<template>
  <div>
    <template v-if="addContainer && addIcon">
      <v-layout row>
        <v-flex xs12>
          <v-select
            :items="languages"
            v-model="currentLanguage"
            :label="$t('Select a language')"
            single-line
            menu-props="auto"
            prepend-icon="mdi-web"
            hide-details
            :color="color"
            :id="id"
            class="language-selector-in-row"
          ></v-select>
        </v-flex>
      </v-layout>
    </template>
    <template v-else-if="addContainer && !addIcon">
      <v-layout row>
        <v-flex xs12>
          <v-select
            :items="languages"
            v-model="currentLanguage"
            :label="$t('Select a language')"
            single-line
            menu-props="auto"
            hide-details
            :color="color"
            :id="id"
            class="language-selector-in-row mt-1"
          ></v-select>
        </v-flex>
      </v-layout>
    </template>
    <template v-else-if="!addContainer && !addIcon">
      <v-select
        :items="languages"
        v-model="currentLanguage"
        :label="$t('Select a language')"
        single-line
        menu-props="auto"
        prepend-icon="language"
        hide-details
        :color="color"
        :id="id"
        class="mr-5 pt-0 nav-language-select"
      ></v-select>
    </template>
    <template v-else>
      <v-select
        :items="languages"
        v-model="currentLanguage"
        :label="$t('Select a language')"
        single-line
        menu-props="auto"
        prepend-icon="mdi-web"
        hide-details
        :color="color"
        :id="id"
        class="mr-5 pt-0 nav-language-select"
      ></v-select>
    </template>
  </div>
</template>

<script>

export default {
  name: "LanguageSelector",
  props: ["whiteColors", "addContainer", "addIcon"],
  data() {
    return {
      languages: this.$config.languages,
    };
  },

  computed: {
    currentLanguage: {
      get: function () {
        return this.$i18n.locale();
      },
      set: function (value) {
        this.$i18n.set(value);
      },
    },
    color: function () {
      return this.whiteColors ? "" : "";
    },
    id: function () {
      return this.whiteColors ? "languageSelect" : "";
    },
  },
};
</script>

<style>
.language-selector-in-row {
  max-width: 100px;
  float: right;
  margin: 10px 0;
}
.nav-language-select .v-select__slot .v-select__selections input {
  display: none;
}
.nav-language-select .v-select__slot .v-select__selections {
  margin-right: 1rem;
}
#languageSelect:not(language-selector-in-row) {
  padding-right: 30px !important;
  float: left;
  max-width: 30px;
}

@media only screen and (max-width: 600px) {
  .language-selector-in-row {
    margin: 0px 0 30px 0;
  }
}
</style>
