<template>
  <div>
    <v-snackbar
      v-model="snackbar"
      :color="snackbarColor"
      bottom
      :timeout="snackbarTimout"
      transition="slide-y-reverse-transition"
      app
      max-width="1600px"
    >
      <v-layout align-center fill-height>
        <template>
          <v-icon left @click="showStacktrace()" v-if="development"
            >mdi-information</v-icon
          >
          <v-icon left v-if="!development">{{ snackbarIcon }}</v-icon>
        </template>
        <p v-if="!isSpecial" class="mb-0 text-truncate max-w">
          {{ getSnackbarText }}
        </p>
        <ul class="ml-4" v-else-if="snackbarText">
          <li v-for="(error, index) in snackbarText" :key="index">
            {{ error }}
          </li>
        </ul>
      </v-layout>

      <template v-slot:action="{ attrs }">
        <v-btn
          v-for="(button, index) in buttons"
          :key="index"
          :text="button.isText"
          v-bind="attrs"
          @click="
            button.functionName == 'clearError'
              ? clearError()
              : button.functionName == 'reportError'
              ? reportError()
              : $emit('syncItems')
          "
        >
          {{ $t(button.text) }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="errorDialogViaError" hide-overlay persistent width="800">
      <v-card color="primary" dark>
        <v-card-text>
          <p class="mb-1 pt-2">
            {{
              $t(
                "Thank you for reporting your problem. We try to check this as soon as possible."
              )
            }}
          </p>

          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="errorDialogStacktrace" hide-overlay width="800">
      <v-card>
        <v-card-text>
          <div class="pt-4" v-if="fullError">
            <prism-editor
              v-if="fullError.err"
              class="my-editor"
              v-model="fullError.err"
              :highlight="highlighter"
              line-numbers
            ></prism-editor>
            <prism-editor
              v-if="fullError.info"
              class="my-editor"
              v-model="fullError.info"
              :highlight="highlighter"
              line-numbers
            ></prism-editor>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import auth from "../../auth";
/* import request from "../../request";
 */
import { mapGetters } from "vuex";
import helpers from "../../helpers";
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css"; // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism-tomorrow.css";
export default {
  //snackbarTimout--> Use -1 to keep open indefinitely
  props: {
    isSpecial: {
      type: Boolean,
      default: false,
    },
    itemId: {
      type: String,
      default: "",
    },
    snackbarText: [Object, String, Error],
    snackbarColor: {
      type: String,
    },
    snackbarTimout: {
      type: String,
    },
    snackbarIcon: {
      type: String,
    },
    buttons: {
      type: Array,
    },
  },
  data() {
    return {
      development: helpers.inDevelopment(),
      errorDialogStacktrace: false,
      snackbar: true,
    };
  },

  components: { PrismEditor },

  computed: {
    ...mapGetters({
      errorDialogViaError: "errorDialogViaError",
      fullError: "fullError",
    }),
    getSnackbarText: {
      get() {
        return this.snackbarText;
      },
      set(newItem) {
        this.snackbarText = newItem;
      },
    },
  },
  methods: {
    reportError() {
      this.$store.dispatch("errorDialogViaError", true);

      const user = auth.getUser();
      let pageId = "";
      if (this.$route.params.id) {
        pageId = this.$route.params.id;
      } else {
        pageId = this.$route.path;
      }
      const postItems = {
        UserEmail: user.Email,
        UserId: user._id,
        StackTrace: this.snackbarText,
        PageId: pageId,
        ContentId: this.itemId,
      };

      this.$store.dispatch("reportErrorToDeveloper", postItems);
      this.snackbar = false;
    },
    clearError() {
      this.$emit("clearError");

      this.snackbar = false;
    },
    openSnackbar() {
      this.snackbar = true;
    },
    showStacktrace() {
      this.errorDialogStacktrace = true;
    },
    highlighter(code) {
      return highlight(code, languages.javascript); //returns html
    },
  },
};
</script>

<style scoped>
.max-w {
  max-width: 65rem;
}
@media only screen and (max-width: 465px) {
  .max-w {
    max-width: 5rem;
  }
}
@media screen and (min-width: 600px) {
  .max-w {
    max-width: 30rem;
  }
}
</style>