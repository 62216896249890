<template>
  <tr>
    <td>
      <v-layout column>
        <small class="mb-1 max-w-text">
          {{ capitalizeFirstLetter(orderListItem.Code) }}</small
        >
        <v-avatar size="32" v-if="orderListItem.ColorId" color="white">
          <v-img
            v-if="orderListItem.ColorId.Image"
            cache
            :src="
              apiUrl +
              '/images/products/colors/' +
              orderListItem.ColorId.Image +
              '?token=' +
              getUserToken
            "
            :alt="orderListItem.ColorId.Image"
          ></v-img>
        </v-avatar>
      </v-layout>
    </td>

    <td
      v-for="(availableSize, indexOf) in isCartItem
        ? availableSizes
        : orderListItem.Stock"
      :key="indexOf"
    >
      <v-col
        cols="12"
        v-if="isAvailble(availableSize.Size, orderListItem.Stock)"
        class="d-flex flex-column align-center"
      >
        <counterItem
          :key="indexOf + rnd"
          :item="orderListItem"
          :amount="
            isCartItem
              ? findReserved(orderListItem.Reserved, availableSize)
              : findReserved(orderListItem.Stock, availableSize)
          "
          :productCode="productCode"
          :ref="
            'counter' + availableSize.Size + orderListItem.Code + productCode
          "
          :size="availableSize.Size"
          :isStockItem="true"
          :productVisableCode="productVisableCode"
          @amountChangeStock="amountChangeStock"
        />
      </v-col>
      <v-col v-else cols="12" class="d-flex flex-column align-center">
        <template v-if="isAvailble(availableSize.Size, orderListItem.Reserved)">
          <counterItem
            :key="indexOf + rnd"
            :item="orderListItem"
            :amount="
              isCartItem
                ? findReserved(orderListItem.Reserved, availableSize)
                : findReserved(orderListItem.Stock, availableSize)
            "
            :productCode="productCode"
            :ref="
              'counter' + availableSize.Size + orderListItem.Code + productCode
            "
            :size="availableSize.Size"
            :isStockItem="false"
            :isReservedItem="true"
            :productVisableCode="productVisableCode"
            @amountChangeReserved="amountChangeReserved"
          />
        </template>

        <template v-else-if="!orderListItem || isCartItem">
          <counterItem
            :key="indexOf + rnd"
            :item="orderListItem"
            :amount="
              isCartItem
                ? findReserved(orderListItem.Reserved, availableSize)
                : findReserved(orderListItem.Stock, availableSize)
            "
            :productCode="productCode"
            :ref="
              'counter' + availableSize.Size + orderListItem.Code + productCode
            "
            :size="availableSize.Size"
            :productVisableCode="productVisableCode"
            @amountUpReservedNew="amountUpReservedNew"
          />
        </template>
        <template v-else>
          <v-layout align-center column>
            <v-btn :disabled="true" icon>
              <v-icon small>mdi-chevron-up</v-icon>
            </v-btn>
            <v-layout align-center column>
              <input
                :readonly="true"
                value="0"
                type="number"
                class="inputwidth text--center ma-0 pa-0 inputborderRed"
              />
            </v-layout>
            <v-btn :disabled="true" icon>
              <v-icon small>mdi-chevron-down</v-icon>
            </v-btn>
          </v-layout>
        </template>
      </v-col>
    </td>
    <td v-if="!isCartItem">
      <v-layout align-center>
        {{ orderAmount }}
      </v-layout>
    </td>
    <td v-if="isCartItem">
      <v-layout align-center>
        {{ getBasketReservedAmount }}
      </v-layout>
    </td>
    <td v-if="isCartItem">
      <v-btn fab icon small @click="deleteItem(orderListItem)">
        <v-icon> mdi-close</v-icon>
      </v-btn>
    </td>
  </tr>
</template>

<script>
import axios from "axios";
import request from "../../request";
import auth from "../../auth";

export default {
  props: [
    "orderListItem",
    "isCartItem",
    "productCode",
    "stockItems",
    "availableSizes",
    "customerId",
    "productVisableCode",
  ],
  data() {
    return {
      show: [],
      allSizes: [],
      amount: [],
      apiUrl: this.$config.apiUrl,
      images: [],
      cart: [],
      snackbar: false,
      snackbarText: null,
      timeout: 4000,
      snackbarColor: null,
      index: null,
      basketReservedAmount: null,
      customerIdParent: this.customerId,
      rnd: Math.random(),
    };
  },
  created() {
    this.setOrderItem();
  },
  watch: {
    orderListItem: function () {
      this.setOrderItem();
    },
    cart: function () {
      this.$emit("cartItems", this.cart);
    },
  },
  computed: {
    getcurrency() {
      let currencySign;
      switch (this.currency) {
        case "EUR":
          currencySign = "€ ";
          break;
        case "GBP":
          currencySign = "£ ";
          break;
        case "USD":
          currencySign = "$ ";
          break;

        default:
          currencySign = "€ ";
          break;
      }
      return currencySign;
    },

    orderAmount() {
      let totalAmount = 0;
      if (this.cart.length == 0) {
        return 0;
      }
      this.cart.forEach((element) => {
        element.SizesAmounts.forEach((el) => {
          totalAmount += el.Amount;
        });
      });
      return totalAmount;
    },
    getBasketReservedAmount: {
      get() {
        if (this.basketReservedAmount == null || !this.basketReservedAmount) {
          this.setBasketReservedAmount();
        }
        return this.basketReservedAmount;
      },
      set(item) {
        this.basketReservedAmount = item;
      },
    },
    getUserToken() {
      return auth.userToken();
    },
  },
  methods: {
    amountChangeStock(event) {
      if (this.cart.length > 0) {
        this.cart.forEach((el, cartIndex) => {
          if (
            el.VariantCode == event.item.Code &&
            el.ProductId == event.productCode
          ) {
            const item = el.SizesAmounts.find(
              (sizeElement) => sizeElement.Size == event.size
            );
            if (item) {
              if (Number(event.amount) == 0) {
                const index = el.SizesAmounts.indexOf(item);
                el.SizesAmounts.splice(index, 1);
                if (el.SizesAmounts.length == 0) {
                  this.cart.splice(cartIndex, 1);
                }
              }
              item.Amount = Number(event.amount);
            } else {
              if (Number(event.amount) > 0) {
                el.SizesAmounts.push({
                  Size: event.size,
                  Amount: Number(event.amount),
                  AvailableDeliverableAmount: Number(
                    event.AvailableDeliverableAmount
                  ),
                });
              }
            }
          } else if (el.ProductId == event.productCode) {
            this.pushCartItem(event.item.Code, event.size, event.amount);
          }
        });
      } else {
        this.pushCartItem(event.item.Code, event.size, event.amount);
      }
    },
    pushCartItem(code, size, amount) {
      if (Number(amount)) {
        this.cart.push({
          ProductId: this.productCode,
          VariantCode: code,
          SizesAmounts: [
            {
              Size: size,
              Amount: Number(amount),
            },
          ],
        });
      }
    },
    amountChangeReserved(event) {
      this.orderListItem.Reserved.forEach((element) => {
        if (element.Size == event.size) {
          element.BasketReservedAmount = event.amount;
        }
      });
      this.updateCart(event.item.Code, event.size, event.amount);
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    findReserved(items, size) {
      if (!this.isCartItem) {
        const item = items.find((el) => el.Size == size.name);
        if (item) {
          if (item["BasketReservedAmount"]) {
            return item.BasketReservedAmount;
          }
          return "0";
        }
        return "0";
      }
      const item = items.find((el) => el.Size == size.Size);
      if (!item) {
        return "0";
      }
      if (!item["BasketReservedAmount"]) {
        return "0";
      }
      return item.BasketReservedAmount;
    },
    async setOrderItem() {
      this.images = [];
      if (this.orderListItem.ColorId) {
        if (!this.orderListItem.ColorId.Id) {
          this.orderListItem.ColorId = await this.getColorById(
            this.orderListItem.ColorId
          );
        } else {
          this.orderListItem.ColorId = await this.getColorById(
            this.orderListItem.ColorId.Id
          );
        }
      }
      this.orderListItem.CommercialImages.forEach((element) => {
        if (element) {
          this.images.push(
            this.apiUrl + "/images/products/commercial-images/" + element
          );
        }
      });
    },
    amountUpReservedNew(event) {
      this.updateCart(event.item.Code, event.size, event.amount, true);
    },
    updateCart(code, size, amount, isNewItem = false) {
      let urlFilter = this.customerIdParent
        ? "&customerId=" + this.customerIdParent
        : "";

      request.post(
        "/api/kdesign/basketupdateamount?variantCode=" +
          code +
          "&productId=" +
          this.productCode +
          "&size=" +
          size +
          "&amount=" +
          amount +
          urlFilter,
        null,
        (res) => {
          if (!res.success) {
            //reset in case of error for the counterItem component
            if (this.$refs["counter" + size + code + this.productCode]) {
              if (this.$refs["counter" + size + code + this.productCode][0]) {
                this.$refs[
                  "counter" + size + code + this.productCode
                ][0].reset();
              }
            }
            this.$emit("error", {
              snackbar: true,
              snackbarText:
                this.$t(res.message) ||
                this.$t("Oops something went wrong, please try again."),
              snackbarColor: "error",
            });
          } else {
            this.$emit("updateTotalPrice");
            isNewItem ? this.$emit("changePrice", "") : "";
            this.setBasketReservedAmount();

            /* this.$emit("changePrice", ""); */
          }
        }
      );
    },
    async getColorById(id) {
      let color;
      if (id) {
        color = await axios
          .get(this.apiUrl + "/api/kdesign/productcolor?colorId=" + id)
          .then((res) => {
            return res.data;
          });
        return await color;
      }
      return "";
    },
    isAvailble(size, stock) {
      if (!stock) {
        return false;
      }
      return (
        (stock.find((el) => el.Size == size) != undefined &&
          stock.find((el) => el.Size == size).AvailableAmount != 0) ||
        (stock.find((el) => el.Size == size) != undefined &&
          stock.find((el) => el.Size == size).AvailableAmount == 0 &&
          stock.find((el) => el.Size == size).BasketReservedAmount > 0)
      );
    },
    deleteItem(item) {
      let urlFilter = this.customerIdParent
        ? "&customerId=" + this.customerIdParent
        : "";
      request.post(
        "/api/kdesign/basketremove?productId=" +
          this.productCode +
          "&variantCode=" +
          item.Code +
          urlFilter,
        null,
        (res) => {
          if (res.success) {
            this.$emit("changePrice");
            this.snackbar = true;
            this.snackbarText = this.$t(
              "Item successfully deleted from shopping cart."
            );
            this.snackbarColor = "succes";
          } else {
            this.snackbar = true;
            this.snackbarText = this.$t(
              "Oops something went wrong, please try again."
            );
            this.snackbarColor = "error";
          }
        }
      );
    },

    resetCart() {
      this.availableSizes.forEach((element) => {
        if (
          this.$refs[
            "counter" +
              element.Size +
              this.orderListItem.Code +
              this.productCode
          ]
        ) {
          if (
            this.$refs[
              "counter" +
                element.Size +
                this.orderListItem.Code +
                this.productCode
            ][0]
          ) {
            this.$refs[
              "counter" +
                element.Size +
                this.orderListItem.Code +
                this.productCode
            ][0].reset();
          }
        }
      });
      this.cart = [];
    },
    changeCurrency(currency) {
      if (currency) {
        this.currency = currency;
      }
    },
    correctItemPrice(item) {
      if (!item) {
        return 0;
      }
      let price = 0;
      item.Netprices.forEach((element) => {
        if (element.Code == this.currency) {
          return (price = element.Price);
        }
      });
      return price;
    },
    setBasketReservedAmount() {
      this.basketReservedAmount = 0;
      if (!this.orderListItem) {
        return 0;
      }
      this.orderListItem.Reserved.forEach((element) => {
        this.basketReservedAmount += element.BasketReservedAmount;
      });
      return this.basketReservedAmount;
    },
    updateCutomerId(param) {
      this.customerIdParent = param;
    },
  },
};
</script>

<style scoped>
.v-text-field {
  width: 4rem;
}
.inputborder {
  border: solid 1px;
  border-color: black;
  border-radius: 0.25rem;
}
.inputborderOrange {
  border: solid 1px;
  border-color: orange;
  border-radius: 0.25rem;
}
.inputborderRed {
  border: solid 1px;
  border-color: red;
  border-radius: 0.25rem;
}
.centered-input >>> input {
  text-align: center;
}
.inputhight {
  height: 2rem !important;
  max-width: 1rem;
  text-align: center;
}
.spacer-width {
  width: 1rem !important;
}
.cursor {
  cursor: pointer;
}
.text-color {
  color: #616161;
}
.max-w-text {
  width: 2rem;
}
.inputwidth {
  max-width: 2rem;
  text-align: center;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>