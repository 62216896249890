<template>
  <v-col cols="12" sm="9" md="12" lg="12" xl="12" class="mt-6">
    <p class="font-weight-bold">{{ product.Code }}</p>
    <v-row no-gutters>
      <v-col cols="1">
        <v-layout v-if="productImages[product.Code] && product.Images[0]">
          <topImage
            :productImages="productImages[product.Code]"
            :productImage="product.Images[0]"
          />
        </v-layout>
      </v-col>
      <v-col cols="11">
        <v-row no-gutters align="center">
          <v-col cols="11" sm="10" md="10" lg="11" xl="11">
            <v-simple-table dense class="pa-md-2 pa-lg-2 pa-xl-2">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">{{ $t("Color") }}</th>
                    <th
                      class="text-center"
                      v-for="(item, index) in getProcuctSizes"
                      :key="index"
                    >
                      <headerPrice
                        :item="item"
                        :index="index"
                        :product="product"
                        :currencyItem="currency"
                        :stockItems="stockItems"
                        :cartItems="toBeUpdatedCart"
                        :ref="'headerItem' + [index]"
                      />
                    </th>

                    <th class="text-left">
                      {{ $t("Total") }}
                    </th>
                    <th v-if="isCartItem" class="text-left">
                      {{ $t("Delete") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <orderListItem
                    v-for="(item, index) in product.Variants"
                    :key="index"
                    :orderListItem="item"
                    :productCode="product.Id"
                    :productVisableCode="product.Code"
                    :ref="'productvariant' + index"
                    :isCartItem="isCartItem"
                    :availableSizes="getProcuctSizes"
                    :customerId="customerIdParent"
                    @cartItems="prepareCartItemsForUpdate"
                    @changePrice="resetCart"
                    @updateTotalPrice="updateTotalPrice"
                    @error="
                      (snackbar = $event.snackbar),
                        (snackbarText = $event.snackbarText),
                        (snackbarColor = $event.snackbarColor)
                    "
                  />
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row no-gutters v-if="!isCartItem">
          <v-spacer></v-spacer>
          <v-col class="text-right mr-4 mb-4 mt-2">
            <v-btn @click="addItemsToShoppingList()" elevation="2" small>
              <v-icon left> mdi-cart </v-icon>
              {{ $t("Add") }}
            </v-btn>
          </v-col>
        </v-row>
        <v-snackbar
          v-model="snackbar"
          :color="snackbarColor"
          :timeout="timeout"
        >
          {{ snackbarText }}

          <template v-slot:action="{ attrs }">
            <v-btn color="white" v-bind="attrs" @click="snackbar = false" icon>
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col>
        <v-divider class="mr-4"></v-divider>
      </v-col>
    </v-row>
    <v-dialog v-model="differenceDialog" persistent max-width="1000">
      <v-card>
        <v-card-title>
          <span
            class="text-h5"
            v-html="$t('Attention, there is a big difference in numbers.')"
          ></span>
        </v-card-title>
        <v-card-text>
          <p
            v-html="
              $t('There is a difference of', { count: differenceForDialog })
            "
          ></p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="differenceDialog = false">
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn color="primary" @click="addItemsToShoppingcart()">
            {{ $t("Add") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="sameProductDialog" persistent max-width="1000">
      <v-card>
        <v-card-title>
          <p class="text-h5">
            {{ $t("This item was already added to your shopping cart.") }}
          </p>
        </v-card-title>
        <v-card-text>
          <p>
            {{ $t("Do you want to add this item again?") }}
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="sameProductDialog = false">
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn
            v-if="!changeButton"
            color="primary"
            @click="addItemsToShoppingcart()"
          >
            {{ $t("Add") }}
          </v-btn>
          <v-btn v-else color="primary" @click="openDifferenceDialog()">
            {{ $t("Next") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import request from "../../request";
export default {
  props: [
    "product",
    "productImages",
    "isCartItem",
    "stockItems",
    "currencyFromParent",
    "customerId",
    "cartItems",
  ],
  data() {
    return {
      apiUrl: this.$config.apiUrl,
      toBeUpdatedCart: [],
      show: [],
      currency: this.currencyFromParent,

      snackbar: false,
      snackbarText: null,
      timeout: 4000,
      snackbarColor: null,
      customerIdParent: this.customerId,
      differenceDialog: false,
      differenceForDialog: 0,
      sameProductDialog: false,
      changeButton: false,
    };
  },

  computed: {
    getCurrencyItem() {
      return this.currency;
    },
    getcurrency() {
      let currencySign;
      switch (this.currency) {
        case "EUR":
          currencySign = "€ ";
          break;
        case "GBP":
          currencySign = "£ ";
          break;
        case "USD":
          currencySign = "$ ";
          break;

        default:
          currencySign = "€ ";
          break;
      }
      return currencySign;
    },
    getProcuctSizes() {
      let standardSizes = [];

      if (this.stockItems && this.isCartItem) {
        this.stockItems.Stock.forEach((el) => {
          standardSizes.push({
            Size: el.Size,
            NetPrices: el.NetPrices,
            Code: this.product.Code,
          });
        });
      } else {
        this.product.Variants[0].Stock.forEach((el) => {
          standardSizes.push({
            Size: el.Size,
            NetPrices: el.NetPrices,
            Code: this.product.Code,
          });
        });
      }
      return standardSizes;
    },

    productCodes() {
      let codes = [];
      this.product.Variants.forEach((el) => {
        codes.push(el.Code);
      });
      return codes;
    },
    productColors() {
      let productColors = [];
      this.product.Variants.forEach((el) => {
        productColors.push(el.ColorId);
      });
      return productColors;
    },
  },
  methods: {
    changeCurrency(currency) {
      if (currency) {
        this.currency = currency;
        for (const [key] of Object.entries(this.$refs)) {
          if (this.$refs[key][0]) {
            this.$refs[key][0].changeCurrency(this.currency);
          }
        }
      }
    },
    maxDifference(arr) {
      let maxDiff = -1;

      let min = arr[0].Amount;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].Amount > min && maxDiff < arr[i].Amount - min) {
          maxDiff = arr[i].Amount - min;
        }
        if (arr[i].Amount < min) {
          min = arr[i].Amount;
        }
      }
      return maxDiff;
    },
    addItemsToShoppingList() {
      if (this.toBeUpdatedCart.length > 0) {
        const differ = this.differenceCheck();
        const shopping = this.allreddyInShoppingcartCheck(differ);
        if (shopping && !differ) {
          this.differenceDialog = true;
        }

        if (differ && shopping) {
          this.addItemsToShoppingcart(shopping, differ);
        }
      } else {
        this.snackbar = true;
        this.snackbarText = this.$t("Add a size.");
        this.snackbarColor = "warning";
      }
    },

    allreddyInShoppingcartCheck(differ) {
      let check = true;
      if (this.cartItems.items.length > 0) {
        this.cartItems.items.forEach((element) => {
          if (
            this.toBeUpdatedCart.find(
              (el) =>
                el.ProductId == element.Id &&
                el.VariantCode == element.VariantCode
            )
          ) {
            if (!differ) {
              this.changeButton = true;
            }
            this.sameProductDialog = true;
            check = false;

            return;
          }
        });
      }
      return check;
    },
    differenceCheck() {
      let check = true;
      //if there is a difference of 8 or more, in "toBeUpdatedCart" send popup
      this.toBeUpdatedCart.forEach((element) => {
        const difference = this.maxDifference(element.SizesAmounts);
        if (difference >= 8) {
          this.differenceForDialog = difference;
          check = false;
          return;
        }
      });
      return check;
    },
    openDifferenceDialog() {
      this.sameProductDialog = false;
      this.differenceDialog = true;
    },
    addItemsToShoppingcart(shopping, differ) {
      const urlFilter = this.customerIdParent
        ? "?customerId=" + this.customerIdParent
        : "";
      if (shopping && differ) {
        this.differenceDialog = false;
      }
      let allAboveZero = true;
      this.toBeUpdatedCart.forEach((element) => {
        const isAboveZero = (currentValue) => currentValue.Amount > 0;
        if (!element.SizesAmounts.every(isAboveZero)) {
          allAboveZero = false;
        }
      });
      if (allAboveZero) {
        this.$store.dispatch("resetProductColor");

        request.post(
          "/api/kdesign/basketadd" + urlFilter,
          [...this.toBeUpdatedCart],
          (res) => {
            this.sameProductDialog = false;
            this.differenceDialog = false;
            this.changeButton = false;
            if (res.success) {
              // show snackbar
              this.snackbar = true;
              this.snackbarText = this.$t(
                "Item successfully added to shopping cart."
              );
              this.snackbarColor = "success";
              //bascid count update
              if (this.product.Variants) {
                this.product.Variants.forEach((element, index) => {
                  if (this.$refs["productvariant" + index]) {
                    this.$refs["productvariant" + index][0].resetCart();
                  }
                });
              }
              this.$emit("updateCart", this.customerIdParent);
              //update prepare item
              this.toBeUpdatedCart = [];
              //set clear event to child component
            } else {
              this.snackbar = true;
              this.snackbarText = this.$t(
                "Oops something went wrong, please try again."
              );
              this.snackbarColor = "error";
            }
          }
        );
      }
    },
    prepareCartItemsForUpdate(items) {
      if (items.length > 0) {
        items.forEach((element) => {
          this.toBeUpdatedCart.push(element);
        });
      }
      this.toBeUpdatedCart.forEach((element, index) => {
        if (element.SizesAmounts.length == 0) {
          this.toBeUpdatedCart.splice(index, 1);
        }
      });
    },
    clearItemOutPrepItem(item) {
      this.toBeUpdatedCart = this.toBeUpdatedCart.filter(
        (el) => el.VariantCode !== item.Code
      );
    },
    resetCart() {
      this.$emit("changePrice");
    },
    updateTotalPrice() {
      this.$emit("updateTotalPrice");
    },
    updateCutomerId(param) {
      this.customerIdParent = param;
      this.product.Variants.forEach((element, index) => {
        this.$refs["productvariant" + index][0].updateCutomerId(param);
      });
    },
  },
};
</script>

<style>
</style>