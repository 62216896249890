//initial state
const state = {
    productsWithColor: []
}

//getters
const getters = {
    productsWithColor: state => state.productsWithColor
}

//actions
const actions = {
    resetProductColor({ commit }) {
        commit('resetProductColor')
    },

    setProductColor({ commit }, product) {
        commit('setProductColor', product)
    }
}

//mutations
const mutations = {
    resetProductColor(state) {
        state.productsWithColor = []
    },

    setProductColor(state, product) {
        if (state.productsWithColor.find(el => (el.productVisableCode == product.productVisableCode) && (el.size == product.item.Size) && (el.code == product.code))) {
            const index = state.productsWithColor.findIndex(el => (el.productVisableCode == product.productVisableCode) && (el.size == product.item.Size) && (el.code == product.code))
            if ((product.amount <= product.item.AvailableDeliverableAmount)&&product.amount!=0) {
                state.productsWithColor[index].color = 'success'
            }
            else if ((product.amount > product.item.AvailableDeliverableAmount)&&product.amount!=0) {
                state.productsWithColor[index].color = 'warning'
            }
            else {
                state.productsWithColor[index].color = 'white'
            }
        }
        else {
            if (product.amount <= product.item.AvailableDeliverableAmount) {
                state.productsWithColor.push({ color: 'success', size: product.item.Size, code: product.code, productVisableCode: product.productVisableCode })
            }
            else if (product.amount > product.item.AvailableDeliverableAmount) {
                state.productsWithColor.push({ color: 'warning', size: product.item.Size, code: product.code, productVisableCode: product.productVisableCode })
            }
            else {
                state.productsWithColor.push({ color: 'white', size: product.item.Size, code: product.code, productVisableCode: product.productVisableCode })
            }
        }
    }



}

export default {
    state,
    getters,
    actions,
    mutations
}
