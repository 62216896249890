<template>
  <div>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="2">
        <v-combobox
          :items="getCustomersNameAndCode"
          item-text="Name"
          item-value="Id"
          v-model="customer"
          :label="$t('Customer')"
          @change="changeDataOnCustomer()"
          @click:clear="changeDataOnClear()"
          clearable
          v-if="!isB2B"
        ></v-combobox>
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="search"
          single-line
          append-icon="mdi-magnify"
          :label="$t('Search')"
          hide-details
          clearable
          @change="changeDataOnSearch()"
        >
          <v-icon slot="append"> mdi-magnify</v-icon></v-text-field
        >
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items.sync="Orders"
      :items-per-page.sync="itemsPerPage"
      :options.sync="options"
      :loading="loadOrders"
      class="elevation-1 ma-3 pa-4"
      hide-default-footer
      :no-data-text="$t('No data available')"
    >
      <template v-slot:[`item.action`]="{ item, index }">
        <v-btn
          @click="downloadPdf(index, item)"
          elevation="2"
          fab
          x-small
          :loading="isLoading[index]"
        >
          <v-icon>mdi-file-download-outline</v-icon>
        </v-btn>
      </template>
      <template v-slot:footer>
        <v-row class="mx-2 py-2" align="center" justify="center">
          <v-spacer></v-spacer>

          <span class="mr-4 grey--text">
            <small
              >{{ $t("Page") }} {{ getPage }} {{ $t("out of") }}
              {{ getAmountOfPages }}</small
            >
          </span>
          <v-btn
            :disabled="hasPrevPage"
            fab
            x-small
            class="mr-1"
            @click="formerPage()"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            :disabled="hasNextPage"
            fab
            x-small
            class="ml-1"
            @click="nextPage()"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-row>
      </template>
    </v-data-table>
    <v-snackbar v-model="alert" :color="alertColor" dense :type="alertType">
      {{ alertText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" v-bind="attrs" @click="alert = false" icon>
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import request from "../../request";
import helpers from "../../helpers";
import auth from "../../auth";

export default {
  props: ["isB2B"],
  data() {
    return {
      options: {},
      search: "",
      Orders: [],
      Customers: [],
      customer: null,
      page: 1,
      amountOfPages: 1,
      count: 10,
      start: 0,
      itemsPerPage: 10,
      timer: null,
      isLoading: [],
      alert: false,
      alertColor: "success",
      alertType: "success",
      alertText: "",
      loadOrders: false,
    };
  },
  computed: {
    headers() {
      let headers;
      if (this.isB2B) {
        return [
          {
            text: this.$t("Order number"),
            align: "start",
            sortable: true,
            value: "Number",
          },
          {
            text: this.$t("Reference"),
            sortable: false,
            value: "Reference",
          },
          {
            text: this.$t("Amount"),
            sortable: false,
            value: "Amount",
          },
          {
            text: this.$t("Date"),
            sortable: true,
            value: "Date",
          },
          {
            text: this.$t("Download PDF"),
            value: "action",
            sortable: false,
          },
        ];
      } else {
        headers = [
          {
            text: this.$t("Order number"),
            align: "start",
            sortable: true,
            value: "Number",
          },
          {
            text: this.$t("Customer"),
            sortable: true,
            value: "CustomerName",
          },
          {
            text: this.$t("Reference"),
            sortable: false,
            value: "Reference",
          },
          {
            text: this.$t("Amount"),
            sortable: false,
            value: "Amount",
          },
          {
            text: this.$t("Date"),
            sortable: true,
            value: "Date",
          },
          {
            text: this.$t("Download PDF"),
            value: "action",
            sortable: false,
          },
        ];
      }
      return headers;
    },
    getCustomersNameAndCode() {
      if (!this.Customers) {
        return [];
      }
      this.Customers.forEach((element) => {
        element.Name = element.Name + " (" + element.Code + ")";
      });
      return this.Customers;
    },
    getPage: {
      get() {
        return this.page;
      },
      set(newItem) {
        this.page = newItem;
      },
    },
    getAmountOfPages: {
      get() {
        if (this.amountOfPages == 0) {
          return 1;
        }
        return this.amountOfPages;
      },
      set(newItem) {
        this.amountOfPages = newItem;
      },
    },
    hasPrevPage() {
      if (this.getPage == 1) {
        return true;
      }
      return false;
    },
    hasNextPage() {
      if (this.getPage < this.amountOfPages) {
        return false;
      }
      return true;
    },
    getUserToken() {
      return auth.userToken();
    },
  },
  watch: {
    search: function (params) {
      params ? params : (params = "");
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.resetPaging();
        this.getOrders({
          start: this.start,
          count: this.count,
          search: this.search ? this.search : "",
          sort: this.options.sortBy.length > 0 ? this.options.sortBy[0] : "",
          sortDescending:
            this.options.sortDesc.length > 0 ? this.options.sortDesc[0] : false,
        });
      }, 800);
    },
    options: {
      handler() {
        this.getOrders({
          start: this.start,
          count: this.count,
          search: this.search ? this.search : "",
          sort: this.options.sortBy.length > 0 ? this.options.sortBy[0] : "",
          sortDescending:
            this.options.sortDesc.length > 0 ? this.options.sortDesc[0] : false,
        });
      },
      deep: true,
    },
  },
  created() {
    if (!this.isB2B) {
      this.getCustomers();
    }

    this.getOrders({
      start: this.start,
      count: this.count,
      search: this.search ? this.search : "",
      sort: "",
      sortDescending: false,
    });
  },
  methods: {
    resetPaging() {
      this.page = 1;
      this.start = 0;
      this.count = 10;
    },
    downloadPdf(index, item) {
      this.$set(this.isLoading, index, true);
      request.download(
        "/api/kdesign/order-confirmation-download?orderId=" +
          item.Id +
          "&token=" +
          this.getUserToken,
        { responseType: "blob" },
        "export_ " + item.Number + ".pdf",
        (res) => {
          this.$set(this.isLoading, index, false);
          if (res.success) {
            return;
          }
          this.alert = true;
          this.alertColor = "error";
          this.alertType = "error";
          this.alertText = this.$t(
            "Woops, something went wrong! Please try again."
          );
          setTimeout(() => {
            this.alert = false;
          }, 3000);
        }
      );
    },
    getCustomers() {
      request.get("/api/kdesign/customers?getAll=true", null, (res) => {
        if (res.success) {
          this.Customers = res;
          return;
        }
        this.alert = true;
        this.alertColor = "error";
        this.alertType = "error";
        this.alertText = this.$t(
          "Woops, something went wrong! Please try again."
        );
        setTimeout(() => {
          this.alert = false;
        }, 3000);
      });
    },
    changeDataOnSearch() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.resetPaging();
        this.getOrders({
          start: this.start,
          count: this.count,
          search: this.search ? this.search : "",
          sort: this.options.sortBy.length > 0 ? this.options.sortBy[0] : "",
          sortDescending:
            this.options.sortDesc.length > 0 ? this.options.sortDesc[0] : false,
        });
      }, 800);
    },
    changeDataOnCustomer() {
      if (this.customer) {
        if (this.customer.Id) {
          this.resetPaging();
          this.getOrders({
            start: this.start,
            count: this.count,
            search: this.search ? this.search : "",
            sort: this.options.sortBy.length > 0 ? this.options.sortBy[0] : "",
            sortDescending:
              this.options.sortDesc.length > 0
                ? this.options.sortDesc[0]
                : false,
          });
        }
      }
    },
    changeDataOnClear() {
      this.customer = null;
      this.resetPaging();
      this.getOrders({
        start: this.start,
        count: this.count,
        search: this.search ? this.search : "",
        sort: this.options.sortBy.length > 0 ? this.options.sortBy[0] : "",
        sortDescending:
          this.options.sortDesc.length > 0 ? this.options.sortDesc[0] : false,
      });
    },
    getOrders(items) {
      this.loadOrders = true;
      let customer = "";
      if (this.customer) {
        if (this.customer.Id) {
          customer = "&customerId=" + this.customer.Id;
        }
      }
      request.get(
        "/api/kdesign/orders?start=" +
          items.start +
          "&count=" +
          items.count +
          "&search=" +
          items.search +
          "&sort=" +
          items.sort +
          "&sortDescending=" +
          items.sortDescending +
          customer,
        null,
        (res) => {
          this.loadOrders = false;
          if (res.success) {
            if (res.length) {
              res.forEach((el, index) => {
                res[index].Date = helpers.changeTimeToReadebleDMY(el.Date);
                res[index].Amount =
                  (el.Currency ? el.Currency : "") + " " + el.Amount;
              });
              this.Orders = res;
            }else{
              this.Orders = [];
            }
          }
        }
      );
      this.getOrdersCounter(items, customer);
    },
    getOrdersCounter(items, customer) {
      request.get(
        "/api/kdesign/orderscount?search=" + items.search + customer,
        null,
        (res) => {
          this.amountOfPages = Math.ceil(res.item / this.itemsPerPage);
        }
      );
    },
    nextPage() {
      this.start = this.start + this.itemsPerPage;
      this.page++;
      this.getOrders({
        start: this.start,
        count: this.count,
        search: this.search ? this.search : "",
        sort: this.options.sortBy.length > 0 ? this.options.sortBy[0] : "",
        sortDescending:
          this.options.sortDesc.length > 0 ? this.options.sortDesc[0] : false,
      });
    },

    formerPage() {
      this.start = this.start - this.itemsPerPage;

      this.page--;
      this.getOrders({
        start: this.start,
        count: this.count,
        search: this.search ? this.search : "",
        sort: this.options.sortBy.length > 0 ? this.options.sortBy[0] : "",
        sortDescending:
          this.options.sortDesc.length > 0 ? this.options.sortDesc[0] : false,
      });
    },
  },
};
</script>

<style scoped>
.alert {
  bottom: 0;
  position: absolute;
  left: 40%;
}
</style>