<template>
  <div class="full-width">
    <h4 class="mt-6">{{ $t("Categories") }}</h4>
    <v-row no-gutters v-if="categorys">
      <v-col>
        <v-checkbox
          class="mt-2"
          :label="item.Name"
          :value="item.Id"
          hide-details
          @change="getFilterdProducts(null, item.Id, null, false)"
          v-for="item in toBoShownCategorys"
          :key="item.Id"
        >
        </v-checkbox>
      </v-col>

      <v-col
        cols="12"
        class="pt-4 ml-1"
        v-if="numberOfShowenCategorys <= categorys.length"
      >
        <a
          class="text-decoration-underline"
          @click="numberOfShowenCategorys = categorys.length"
          v-if="numberOfShowenCategorys != categorys.length"
          >{{ $t("Show more") }}</a
        >
        <a
          class="text-decoration-underline"
          v-else
          @click="numberOfShowenCategorys = 5"
          >{{ $t("Show less") }}</a
        >
      </v-col>
    </v-row>
    <h4 class="mt-6">{{ $t("Colors") }}</h4>
    <v-row v-if="colors" no-gutters>
      <v-col
        cols="2"
        v-for="(item, index) in toBoShownColors"
        :key="item.Image"
      >
        <v-sheet
          color="primary"
          height="28"
          width="28"
          class="rounded-xl"
          v-if="color[index]"
        >
          <v-layout class="mt-2" align-center justify-center fill-height>
            <v-avatar
              size="24"
              @click="changeColor(index, item)"
              class="pointer"
            >
              <v-img
                class="rounded"
                cache
                :src="
                  apiUrl +
                  '/images/products/colors/' +
                  item.Image +
                  '?token=' +
                  getUserToken
                "
              ></v-img>
            </v-avatar>
          </v-layout>
        </v-sheet>
        <v-layout class="mt-2" v-else>
          <v-avatar size="28" @click="changeColor(index, item)" class="pointer">
            <v-img
              class="rounded"
              cache
              :src="
                apiUrl +
                '/images/products/colors/' +
                item.Image +
                '?token=' +
                getUserToken
              "
            ></v-img>
          </v-avatar>
        </v-layout>
      </v-col>
      <v-col
        cols="12"
        class="mt-4"
        v-if="numberOfShowenColors <= colors.length"
      >
        <a
          class="text-decoration-underline"
          @click="numberOfShowenColors = colors.length"
          v-if="numberOfShowenColors != colors.length"
          >{{ $t("Show more") }}</a
        >
        <a
          class="text-decoration-underline"
          v-else
          @click="numberOfShowenColors = 5"
          >{{ $t("Show less") }}</a
        >
      </v-col>
    </v-row>

    <h4 class="mt-6">{{ $t("Collections") }}</h4>
    <v-row no-gutters v-if="seasons">
      <v-col>
        <v-checkbox
          class="mt-2"
          :label="item.Name"
          :value="item.Id"
          hide-details
          @change="getFilterdProducts(item.Id, null, null, false)"
          v-for="item in toBoShownSeasons"
          :key="item.Name"
        >
        </v-checkbox>
      </v-col>

      <v-col
        cols="12"
        class="mt-4"
        v-show="numberOfShowenSeasons <= seasons.length"
      >
        <a
          class="text-decoration-underline"
          @click="numberOfShowenSeasons = seasons.length"
          v-if="numberOfShowenSeasons != seasons.length"
          >{{ $t("Show more") }}</a
        >
        <a
          class="text-decoration-underline"
          v-else
          @click="numberOfShowenSeasons = 5"
          >{{ $t("Show less") }}</a
        >
      </v-col>
    </v-row>

    <!-- <h4 class="mt-6">{{ $t("StayFresh") }}</h4>
    <v-row no-gutters v-if="stayFresh">
      <v-col>
        <v-checkbox
          class="mt-2"
          :label="item.Name"
          :value="item.Id"
          hide-details
          @change="getFilterdProducts(null, null, null, stayFreshBox)"
          v-for="item in stayFresh"
          :key="item.Id"
          v-model="stayFreshBox"
        >
        </v-checkbox>
      </v-col>
    </v-row> -->
  </div>
</template>

<script>
import request from "../../request";
import Vue from "vue";
import auth from "../../auth";

export default {
  props: ["isSalesAgent"],

  data() {
    return {
      apiUrl: this.$config.apiUrl,
      seasons: [],
      categorys: null,
      colors: null,
      stayFresh: [{ Name: "StayFresh", Id: "" }],
      stayFreshBox: false,
      color: [],
      seasonsFilterArray: [],
      categorysFilterArray: [],
      colorsFilterArray: [],
      numberOfShowenSeasons: 5,
      numberOfShowenCategorys: 5,
      numberOfShowenColors: 5,
      categoryCheckbox: null,
      seasonCheckbox: null,
    };
  },
  created() {
    request.get("/api/kdesign/seasons", null, (res) => {
      if (this.isSalesAgent) {
        res.forEach((element) => {
          if (element.ActiveSalesAgent && element.Active) {
            this.seasons.push(element);
          }
        });
      } else {
        res.forEach((element) => {
          if (element.ActiveB2B && element.Active) {
            this.seasons.push(element);
          }
        });
      }
      let query = "";
      this.seasons.forEach((element) => {
        query = query + "&seasonIds=" + element.Id;
      });
      request.get("/api/kdesign/productcategories?" + query, null, (res) => {
        if (res.success) {
          let items = [];
          res.forEach((element) => {
            if (element.Names) {
              items.push({
                Name: this.capitalizeFirstLetter(
                  element.Names.find(
                    (el) => el.LanguageCode == this.currentLanguage
                  ).Value.toLowerCase()
                ),
                Id: element.Id,
              });
            } else if (element.Code && !element.Names) {
              items.push({
                Name: this.capitalizeFirstLetter(element.Code.toLowerCase()),
                Id: element.Id,
              });
            }
          });
          items.sort((a, b) => a.Name.localeCompare(b.Name));
          this.categorys = items;
        }
      });
      request.get("/api/kdesign/productcolors?" + query, null, (res) => {
        if (res.success) {
          this.colors = res;
        }
      });
    });
  },
  computed: {
    currentLanguage: {
      get: function () {
        return Vue.i18n.locale();
      },
    },
    toBoShownCategorys() {
      return this.categorys.slice(0, this.numberOfShowenCategorys);
    },
    toBoShownColors() {
      return this.colors.slice(0, this.numberOfShowenColors);
    },
    toBoShownSeasons() {
      return this.seasons.slice(0, this.numberOfShowenSeasons);
    },
    getUserToken() {
      return auth.userToken();
    },
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    changeFilterItems(item) {
      this.$emit("changeFilterItems", item);
    },
    changeColor(index, item) {
      if (!this.color[index] || this.color[index] == undefined) {
        this.$set(this.color, index, true);
        this.getFilterdProducts(null, null, item.Id, false);
      } else {
        this.getFilterdProducts(null, null, item.Id, false);

        this.$set(this.color, index, false);
      }
    },
    async getFilterdProducts(season, category, color, stayFresh) {
      let seasonText, categoryText, colorText;
      //checking for more
      if (this.seasonsFilterArray.includes("&seasonIds=" + season)) {
        this.deleteItemFromArray(
          "&seasonIds=" + season,
          this.seasonsFilterArray
        );
      } else {
        if (season) {
          this.seasonsFilterArray.push(season);
        }
      }
      if (this.categorysFilterArray.includes("&categoryIds=" + category)) {
        if (category) {
          this.deleteItemFromArray(
            "&categoryIds=" + category,
            this.categorysFilterArray
          );
        }
      } else {
        if (category) {
          this.categorysFilterArray.push(category);
        }
      }

      this.colorsFilterArray.includes("&colorids=" + color)
        ? this.deleteItemFromArray("&colorids=" + color, this.colorsFilterArray)
        : color
        ? this.colorsFilterArray.push(color)
        : "";

      //adding text
      if (this.seasonsFilterArray.length > 0) {
        this.seasonsFilterArray = await this.filterAddText(
          this.seasonsFilterArray,
          "&seasonIds="
        );
      }
      //array to string
      seasonText = this.filtersToString(this.seasonsFilterArray);

      if (this.categorysFilterArray.length > 0) {
        this.categorysFilterArray = await this.filterAddText(
          this.categorysFilterArray,
          "&categoryIds="
        );
      }
      //array to string
      categoryText = this.filtersToString(this.categorysFilterArray);

      if (this.colorsFilterArray.length > 0) {
        this.colorsFilterArray = await this.filterAddText(
          this.colorsFilterArray,
          "&colorids="
        );
      }
      //array to string
      colorText = this.filtersToString(this.colorsFilterArray);

      //setup api string
      const apiString = `${
        seasonText + categoryText + colorText + "&isStayFresh=" + stayFresh
      }`;
      this.$emit("updateSortString", apiString);
    },
    filterAddText(items, text) {
      items.forEach((element, index) => {
        if (!element.includes(text)) {
          items[index] = text + element;
        }
      });
      return items;
    },
    filtersToString(items) {
      if (items) {
        let text = "";
        items.forEach((element) => {
          text = text + element;
        });
        return text;
      }
      return null;
    },
    deleteItemFromArray(id, items) {
      const index = items.indexOf(id);
      if (index > -1) {
        items.splice(index, 1);
      }

      return items;
    },
  },
};
</script>

<style>
.full-width {
  width: 100%;
}
.pointer {
  cursor: pointer;
}
</style>