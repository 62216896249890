<template>
  <v-card class="px-8 py-4">
    <v-row>
      <v-col cols="12" sm="6" class="py-4 px-4 d-flex">
        <v-layout align-center class="text-sm-left" v-if="logo">
          <v-img
            contain
            id="logoLogin"
            class=""
            :src="this.serverUrl + '/images/' + logo + '?token=' + userToken"
          >
          </v-img>
        </v-layout>
        <v-layout align-center class="text-sm-left" v-else>
          <v-img
            contain
            id="logoLogin"
            :src="getLogo"
            v-on:error="failedImage = true"
          ></v-img>
        </v-layout>
      </v-col>
      <v-col cols="12" sm="6" class="pl-4">
        <languageSelector :addContainer="true" :addIcon="true" />
        <v-form @submit.prevent="login" ref="form" lazy-validation>
          <v-text-field
            :label="$t('Email')"
            v-model="email"
            required
            :rules="getRules('notEmptyEmailRule')"
            validate-on-blur
          ></v-text-field>

          <v-text-field
            :label="$t('Password')"
            v-model="pass"
            type="password"
            required
            :rules="[
              (v) => !!v || $t('Password is required'),
              (v) => (v && v.length > 4) || $t('Password is too short'),
            ]"
            validate-on-blur
          ></v-text-field>

          <div class="text-sm-right">
            <v-btn
              type="submit"
              :loading="loading"
              class="ml-0 mr-0"
              color="primary"
              >{{ $t("Login") }}</v-btn
            >
          </div>

          <errorHandeling
            v-if="error"
            :snackbarText="error"
            :buttons="[
              {
                isText: true,
                functionName: 'reportError',
                text: 'Report error',
              },
              {
                isText: true,
                functionName: 'clearError',
                text: 'Close',
              },
            ]"
            snackbarColor="error"
            snackbarTimout="-1"
            snackbarIcon="mdi-alert-circle"
            @clearError="error = ''"
          />
        </v-form>
        <v-row no-gutters>
          <v-col cols="12" class="mt-4">
            <p class="text-sm-right mb-2">
              <router-link to="/forgotpassword">
                {{ $t("Forgot password?") }}
              </router-link>
            </p>
          </v-col>
          <!--<v-col cols="12">
            <p class="text-sm-right">
              <router-link to="/register">
                {{ $t("No account yet?") }}
              </router-link>
            </p>
          </v-col>-->
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import auth from "../../auth";
import helpers from "../../helpers";
import request from "../../request";
import validation from "../../validation";

export default {
  data() {
    return {
      email: "",
      pass: "",
      loading: false,
      logo: this.$config.logoPos,
      serverUrl: helpers.getServerUrl(),
      failedImage: false,
      userToken: auth.userToken(),
    };
  },
  computed: {
    getLogo: {
      get() {
        return this.failedImage
          ? this.serverUrl +
              "/images/" +
              "logo-pos.png" +
              "?token=" +
              this.userToken +
              "&v=" +
              this.getDate()
          : this.serverUrl +
              "/images/" +
              "logo-pos.svg" +
              "?token=" +
              this.userToken +
              "&v=" +
              this.getDate();
      },
    },
    ...mapGetters({
      error: "error",
    }),
  },

  methods: {
    getRules(rule) {
      return validation.getValidation(rule);
    },
    login() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        auth.login(this.email, this.pass, (res) => {
          request.get("/websites", null, (websitesResponse) => {
            this.loading = false;
            if (!res.success) {
              this.$store.dispatch("setError", res);
              this.pass = "";
            } else {
              this.$store.dispatch("resetError");
              this.$store.dispatch("setAllWebsites", websitesResponse.data);
              this.$i18n.set(res.data.Language);

              if (
                websitesResponse.data[0].DashboardPageId.$oid !==
                "000000000000000000000000"
              ) {
                this.$router.push({
                  name: "editPage",
                  params: { id: websitesResponse.data[0].DashboardPageId.$oid },
                });
                this.$router.go(1);
              } else {
                this.$router.push({ name: "dashboard" });
                this.$router.go(1);
              }
            }
          });
        });
      }
    },
    getDate() {
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      const yyyy = today.getFullYear();

      return mm + dd + yyyy;
    },
  },
};
</script>

<style>
#login-container {
  max-width: 750px;
}

#logoLogin {
  max-width: 100%;
  width: 90%;
  margin: auto;
}
</style>
