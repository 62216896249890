<template>
  <div>
    <v-img
      contain
      :src="
        apiUrl + '/images/products/' + productImage + '?token=' + getUserToken
      "
      cache
      alt="clothes"
      class="cursor"
      @click="indexImage = 0"
      height="150"
      width="90"
    >
    </v-img>
    <div id="test">
      <CoolLightBox
        :items="productImages"
        :index="indexImage"
        @close="indexImage = null"
      >
      </CoolLightBox>
    </div>
  </div>
</template>

<script>
import auth from "../../auth";
export default {
  props: ["productImages", "productImage"],
  data() {
    return {
      apiUrl: this.$config.apiUrl,
      indexImage: null,
    };
  },
  computed: {
    getUserToken() {
      return auth.userToken();
    },
  },
};
</script>

<style>
</style>