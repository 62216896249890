<template>
  <v-col cols="12" :lg="getWidth" md="12" sm="12">
    <v-card height="100%">
      <v-card-text>
        <!-- Add title -->
        <template v-if="editMode">
          <v-row no-gutters>
            <v-col cols="1">
              <v-layout column>
                <v-btn
                  text
                  small
                  icon
                  color="grey"
                  v-on:click="$emit('moveUpNonRepPage', objectName)"
                >
                  <v-icon>mdi-chevron-up</v-icon>
                </v-btn>

                <v-btn
                  text
                  small
                  icon
                  color="grey"
                  v-on:click="$emit('moveDownNonRepPage', objectName)"
                >
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </v-layout>
            </v-col>
            <v-col cols="10">
              <configLanguageSelector
                v-if="item.TranslatedTitle"
                :colOption="{
                  colsDropdown: getDropdownCols,
                  colsText: getTextCols,
                }"
                :itemData="item"
                @changeTranslatedTitle="item.TranslatedTitle = $event"
              />
              <v-text-field
                v-else
                :label="objectName"
                v-model="item.Title"
              ></v-text-field>
            </v-col>

            <v-col cols="1" class="text-right">
              <v-btn class="mt-5" color="error" icon v-on:click="deleteItem()">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </template>
        <template v-else>
          <h3 class="mb-3" v-if="getItemTitle">{{ getItemTitle }}</h3>
        </template>

        <!-- If the item is text -->
        <template v-if="item.Type === 'text'">
          <template v-if="item.Translate || item.Translate == undefined">
            <v-select
              :items="itemLanguages"
              v-model="itemDefaultLanguage"
              single-line
              class="pageElementLanguageSelect"
            ></v-select>
          </template>

          <div
            v-for="(translatedValue, translatedValueIndex) in item.Value"
            :key="translatedValueIndex"
          >
            <v-textarea
              v-if="
                itemDefaultLanguage == translatedValueIndex && !item.ReadOnly
              "
              outlined
              dense
              v-model="item.Value[translatedValueIndex]"
              rows="2"
              :required="item.Required"
              :disabled="item.ReadOnly"
              :rules="validations.getValidation(item.FieldValidationFuntion)"
              hide-details="auto"
            >
            </v-textarea>
            <p
              class="mb-0"
              v-if="
                item.ReadOnly && itemDefaultLanguage == translatedValueIndex
              "
            >
              {{ item.Value[translatedValueIndex] }}
            </p>
          </div>

          <template v-if="editMode">
            <hr class="mt-5" />
            <v-row no-gutters class="my-5">
              <v-col cols="12">
                <v-layout column>
                  <v-text-field
                    v-model.number="item.colWidth"
                    hide-details="auto"
                    type="number"
                    max="12"
                    min="1"
                    dense
                    :label="$t('Column width')"
                    class="mt-4 mw-w"
                  ></v-text-field>
                  <v-switch
                    v-model="item.Translate"
                    class="float-left mr-3"
                    hide-details="auto"
                    :label="$t('Translate item')"
                  ></v-switch>
                  <v-switch
                    v-model="item.ReadOnly"
                    class="float-left mr-3"
                    hide-details="auto"
                    :label="$t('Read-only')"
                  ></v-switch>
                  <v-switch
                    v-model="item.FieldValidation"
                    @change="changeFieldValidation(item)"
                    class="float-left mr-3"
                    :label="$t('Field validation')"
                  >
                  </v-switch>
                  <v-row no-gutters>
                    <v-col cols="6">
                      <v-combobox
                        v-if="item.FieldValidation"
                        :items="validations.getAllValidationNames()"
                        class="float-left mr-3"
                        :label="$t('Rule')"
                        v-model="item.FieldValidationFuntion"
                        clearable
                        @click:clear="item.FieldValidationFuntion = ''"
                      >
                      </v-combobox>
                    </v-col>
                  </v-row>
                </v-layout>
              </v-col>
            </v-row>
          </template>
        </template>
        <!-- If the item is number -->
        <template v-if="item.Type === 'number'">
          <v-layout align-center>
            <v-text-field
              v-if="!item.ReadOnly && item.NeedsNumberSign && item.IsPrefix"
              :prefix="item.NumberSign"
              outlined
              dense
              step="0.01"
              v-model.number="item.Value"
              :required="item.Required"
              :disabled="item.ReadOnly"
              hide-details="auto"
              single-line
              :rules="validations.getValidation(item.FieldValidationFuntion)"
              type="number"
            ></v-text-field>
            <v-text-field
              v-else-if="!item.ReadOnly"
              outlined
              dense
              step="0.01"
              v-model.number="item.Value"
              :required="item.Required"
              :disabled="item.ReadOnly"
              hide-details="auto"
              single-line
              :rules="validations.getValidation(item.FieldValidationFuntion)"
              type="number"
              :suffix="item.NeedsNumberSign ? item.NumberSign : ''"
            ></v-text-field>

            <p class="mb-0" v-else>
              <span
                class="mr-1 mb-0"
                v-if="item.NeedsNumberSign && item.ReadOnly && item.IsPrefix"
              >
                {{ item.NumberSign }}
              </span>
              {{ item.Value }}
              <span
                class="ml-1 mb-0"
                v-if="item.NeedsNumberSign && item.ReadOnly && !item.IsPrefix"
              >
                {{ item.NumberSign }}
              </span>
            </p>
            <h1
              class="ml-2 mt-1"
              v-if="item.IsPercentage && !item.NeedsNumberSign"
            >
              %
            </h1>
          </v-layout>
          <template v-if="editMode">
            <hr class="mt-5" />
            <v-row no-gutters class="my-5">
              <v-col cols="12">
                <v-layout column>
                  <v-text-field
                    v-model.number="item.colWidth"
                    hide-details="auto"
                    type="number"
                    max="12"
                    min="1"
                    dense
                    :label="$t('Column width')"
                    class="mt-4 mw-w"
                  ></v-text-field>

                  <v-switch
                    v-model="item.ReadOnly"
                    :label="$t('Read-only')"
                    hide-details="auto"
                    class="float-left mr-3"
                  ></v-switch>

                  <v-divider
                    v-if="item.NeedsNumberSign"
                    class="mt-4"
                  ></v-divider>
                  <v-switch
                    v-model="item.NeedsNumberSign"
                    hide-details="auto"
                    :label="$t('Show currency symbol')"
                    class="float-left mr-3"
                  >
                  </v-switch>
                  <v-switch
                    v-if="item.NeedsNumberSign"
                    v-model="item.IsPrefix"
                    :label="$t('Show currency symbol in front')"
                    class="float-left mr-3"
                  >
                  </v-switch>
                  <v-select
                    v-if="item.NeedsNumberSign"
                    v-model="item.NumberSign"
                    :items="$config.signs"
                    dense
                    :label="$t('Symbol')"
                    class="float-left mr-3 mw-w mt-4"
                  ></v-select>
                  <v-divider
                    v-if="item.NeedsNumberSign"
                    :class="item.NeedsNumberSign ? '' : 'my-4'"
                  ></v-divider>
                  <v-switch
                    v-model="item.FieldValidation"
                    @change="changeFieldValidation(item)"
                    class="float-left mr-3"
                    :label="$t('Field validation')"
                  >
                  </v-switch>
                  <v-combobox
                    v-if="item.FieldValidation"
                    :items="validations.getAllValidationNames()"
                    class="float-left mr-3"
                    :label="$t('Rule')"
                    v-model="item.FieldValidationFuntion"
                    clearable
                    @click:clear="item.FieldValidationFuntion = ''"
                  >
                  </v-combobox>
                </v-layout>
              </v-col>
            </v-row>
          </template>
        </template>
        <!-- If the item is rich text -->
        <template v-else-if="item.Type === 'richtext'">
          <div>
            <template v-if="item.Translate || item.Translate == undefined">
              <v-select
                :items="itemLanguages"
                v-model="itemDefaultLanguage"
                single-line
                class="pageElementLanguageSelectRichText"
              ></v-select>
            </template>

            <template
              v-for="(translatedValue, translatedValueIndex) in item.Value"
            >
              <template
                v-if="
                  itemDefaultLanguage == translatedValueIndex && !item.ReadOnly
                "
              >
                <editor
                  api-key="f18i0ywzo341k9tx3r3vjsnoghnomdrqbhx2qqi5lnul1dbh"
                  v-model="item.Value[translatedValueIndex]"
                  tinymce-script-src="/tinymce/js/tinymce/tinymce.min.js"
                  :key="tinyId + translatedValueIndex"
                  :required="item.Required"
                  :init="{
                    height: 500,
                    menubar: false,
                    language: editorLanguage,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount',
                    ],
                    toolbar:
                      'undo redo | formatselect | bold italic underline strikethrough | table image link | \ alignleft aligncenter alignright alignjustify | \ bullist numlist outdent indent | removeformat \ | forecolor backcolor |',
                  }"
                  :disabled="item.ReadOnly"
                />
              </template>
              <p
                v-html="item.Value[translatedValueIndex]"
                class="mb-0 text-field-item"
                v-if="
                  item.ReadOnly && itemDefaultLanguage == translatedValueIndex
                "
                :key="tinyId + translatedValueIndex"
              ></p>
            </template>
          </div>

          <template v-if="editMode">
            <hr class="mt-5" />
            <v-row no-gutters class="my-5">
              <v-col cols="12">
                <v-layout column>
                  <v-text-field
                    v-model.number="item.colWidth"
                    hide-details="auto"
                    type="number"
                    max="12"
                    min="1"
                    dense
                    :label="$t('Column width')"
                    class="mw-w"
                  ></v-text-field>
                  <v-switch
                    v-model="item.Translate"
                    class="float-left mr-3"
                    hide-details="auto"
                    :label="$t('Translate item')"
                  ></v-switch>
                  <v-switch
                    v-model="item.Required"
                    class="float-left mr-3"
                    hide-details="auto"
                    :label="$t('Field is required')"
                  ></v-switch>
                  <v-switch
                    v-model="item.ReadOnly"
                    hide-details="auto"
                    :label="$t('Read-only')"
                    class="float-left mr-3"
                  ></v-switch>
                </v-layout>
              </v-col>
            </v-row>
          </template>
        </template>
        <!-- If the item is a boolean -->
        <template v-else-if="item.Type === 'boolean'">
          <v-switch
            v-model="item.Value"
            :disabled="item.ReadOnly"
            class="mb-2"
            hide-details="auto"
          ></v-switch>

          <template v-if="editMode">
            <hr />

            <v-row no-gutters class="my-5">
              <v-col cols="12">
                <v-layout column>
                  <v-text-field
                    v-model.number="item.colWidth"
                    hide-details="auto"
                    type="number"
                    max="12"
                    min="1"
                    dense
                    :label="$t('Column width')"
                    class="mw-w"
                  ></v-text-field>
                  <v-switch
                    v-model="item.ReadOnly"
                    hide-details="auto"
                    class="float-left mr-3"
                    :label="$t('Read-only')"
                  ></v-switch>
                </v-layout>
              </v-col>
            </v-row>
          </template>
        </template>
        <!-- If the item is a date picker -->
        <template v-else-if="item.Type === 'datepicker'">
          <div>
            <date-picker
              name="datePickerPageEl"
              :lableName="$t('Pick a date')"
              :dateOptions="{
                dateClearable: !item.Required,
                dateDisabled: item.ReadOnly,
                dataFieldValidation: item.FieldValidation,
                dateRules: item.FieldValidationFuntion,
                dateAutoSelectCurrent: item.AutoSelectCurrent,
              }"
              :dateValue="item.Value"
              @clearDate="item.Value = null"
              @changeDatePicker="item.Value = $event"
            />
          </div>

          <template v-if="editMode">
            <hr class="mt-5" />
            <v-row no-gutters class="my-5">
              <v-col cols="12">
                <v-layout column>
                  <v-text-field
                    v-model.number="item.colWidth"
                    hide-details="auto"
                    type="number"
                    max="12"
                    min="1"
                    dense
                    :label="$t('Column width')"
                    class="mw-w"
                  ></v-text-field>
                  <v-switch
                    v-model="item.Required"
                    class="float-left mr-3"
                    hide-details="auto"
                    :label="$t('Field is required')"
                  ></v-switch>
                  <v-switch
                    v-model="item.ReadOnly"
                    hide-details="auto"
                    class="float-left mr-3"
                    :label="$t('Read-only')"
                  ></v-switch>
                  <v-switch
                    v-model="item.AutoSelectCurrent"
                    class="float-left mr-3"
                    :label="$t('Auto select date')"
                    hide-details="auto"
                  >
                  </v-switch>
                  <v-switch
                    v-model="item.FieldValidation"
                    @change="changeFieldValidation(item)"
                    class="float-left mr-3"
                    :label="$t('Field validation')"
                  >
                  </v-switch>
                  <v-row no-gutters>
                    <v-col cols="6">
                      <v-combobox
                        v-if="item.FieldValidation"
                        :items="validations.getAllValidationNames()"
                        class="float-left mr-3"
                        :label="$t('Rule')"
                        v-model="item.FieldValidationFuntion"
                        clearable
                        @click:clear="item.FieldValidationFuntion = ''"
                      >
                      </v-combobox>
                    </v-col>
                  </v-row>
                </v-layout>
              </v-col>
            </v-row>
          </template>
        </template>
        <!-- If the item is time -->
        <template v-else-if="item.Type === 'time'">
          <time-picker
            :lableName="$t('Pick a time')"
            :timeValue="item.Value"
            :timeOptions="{
              timeClearable: !item.Required,
              timeDisabled: item.ReadOnly,
              timeFieldValidation: item.FieldValidation,
              timeRules: item.FieldValidationFuntion,
              timeAutoSelectCurrent: item.AutoSelectCurrent,
              showSeconds: item.ShowSeconds,
            }"
            @changedTime="changeTime"
          />

          <template v-if="editMode">
            <hr class="mt-5" />
            <v-row no-gutters class="my-5">
              <v-col cols="12">
                <v-layout column>
                  <v-text-field
                    v-model.number="item.colWidth"
                    hide-details="auto"
                    type="number"
                    max="12"
                    min="1"
                    dense
                    :label="$t('Column width')"
                    class="mw-w"
                  ></v-text-field>
                  <v-switch
                    v-model="item.Required"
                    class="float-left mr-3"
                    hide-details="auto"
                    :label="$t('Field is required')"
                  ></v-switch>
                  <v-switch
                    v-model="item.ReadOnly"
                    hide-details="auto"
                    class="float-left mr-3"
                    :label="$t('Read-only')"
                  ></v-switch>
                  <v-switch
                    hide-details="auto"
                    class="float-left mr-3"
                    v-model="item.ShowSeconds"
                    :label="$t('Show seconds')"
                  ></v-switch>
                  <v-switch
                    v-if="item.Type == 'time'"
                    v-model="item.AutoSelectCurrent"
                    :label="$t('Auto select time')"
                    class="float-left mr-3"
                    hide-details="auto"
                  >
                  </v-switch>
                  <v-switch
                    v-model="item.FieldValidation"
                    @change="changeFieldValidation(item)"
                    class="float-left mr-3"
                    :label="$t('Field validation')"
                  >
                  </v-switch>
                  <v-row no-gutters>
                    <v-col cols="6">
                      <v-combobox
                        v-if="item.FieldValidation"
                        :items="validations.getAllValidationNames()"
                        class="float-left mr-3"
                        :label="$t('Rule')"
                        v-model="item.FieldValidationFuntion"
                        clearable
                        @click:clear="item.FieldValidationFuntion = ''"
                      >
                      </v-combobox>
                    </v-col>
                  </v-row>
                </v-layout>
              </v-col>
            </v-row>
          </template>
          <errorHandeling
            v-if="uploadError.length > 0"
            :snackbarText="uploadError"
            :buttons="[
              {
                isText: true,
                functionName: 'reportError',
                text: 'Report error',
              },
              {
                isText: true,
                functionName: 'clearError',
                text: 'Close',
              },
            ]"
            snackbarColor="error"
            snackbarTimout="-1"
            snackbarIcon="mdi-alert-circle"
            @clearError="uploadError = ''"
          />
        </template>
        <!-- If the item is dropdown -->
        <template v-else-if="item.Type === 'dropdown'">
          <v-select
            :items="item.Options"
            v-model="item.Value"
            :label="$t('Select')"
            single-line
            menu-props="bottom"
            item-value="Id"
            item-text="Value"
            :rules="dropdownRule"
            validate-on-blur
            :clearable="!item.Required"
            :disabled="item.ReadOnly"
          ></v-select>
          <template v-if="editMode">
            <hr />
            <h4 class="mt-5">{{ $t("Modify select options") }}</h4>

            <template v-for="(option, optionIndex) in item.Options">
              <v-chip
                :key="optionIndex"
                class="ml-0"
                close
                @click="removeOption(optionIndex)"
              >
                {{ option.Value }}
              </v-chip>
            </template>
            <br />
            <v-row no-gutters class="my-5">
              <v-col cols="12" lg="6">
                <v-text-field
                  :label="$t('Option name')"
                  v-model="newOptionName"
                  required
                  :error="addOptionFieldHasError"
                  :rules="addOptionFieldRule"
                  class="mr-xs-0 mr-sm-3"
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="3">
                <v-btn
                  class="my-3 mr-3 float-left edit-page-action-button dropdown-action-button"
                  color="green"
                  dark
                  v-on:click="addOption"
                >
                  {{ $t("Add option") }}
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="12" sm="12" lg="12">
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-layout column>
                      <v-text-field
                        v-model.number="item.colWidth"
                        hide-details="auto"
                        type="number"
                        max="12"
                        min="1"
                        dense
                        :label="$t('Column width')"
                        class="mw-w"
                      ></v-text-field>
                      <v-switch
                        v-model="item.Required"
                        class="float-left mr-3"
                        hide-details="auto"
                        :label="$t('Field is required')"
                      ></v-switch>
                      <v-switch
                        v-model="item.ReadOnly"
                        hide-details="auto"
                        class="float-left mr-3"
                        :label="$t('Read-only')"
                      ></v-switch>
                    </v-layout>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </template>
        </template>
        <!-- If the item is a list of content -->
        <template v-else-if="item.Type === 'content'">
          <!-- There is content in the list -->
          <template v-for="(content, index) in item.Value">
            <div :key="index" v-if="content != ''">
              <h3>
                <v-icon>mdi-file-document</v-icon>
                {{ content }}
                <v-btn
                  color="error"
                  icon
                  v-on:click="deleteContent(item.Value, index)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </h3>
            </div>
          </template>
          <v-layout align-center>
            <v-autocomplete
              v-if="item.MultipleItems"
              class="mt-4"
              height="28"
              dense
              v-model="item.LinkedContent"
              :items="searchResults"
              :placeholder="
                item.LoadItemsWhenCreated ? '' : $t('Search') + '...'
              "
              small-chips
              :loading="searchingContent"
              :search-input.sync="searchContentWatch"
              :multiple="item.MultipleItems"
              :chips="item.MultipleItems"
              :deletable-chips="item.MultipleItems"
              item-text="DisplayLabel"
              item-value="_id"
              :rules="dropdownRule"
              :clearable="!item.Required"
              hide-no-data
              :disabled="item.ReadOnly"
            >
              <!-- @change="searchContentWatch = ''" -->
              <template v-slot:selection="{ attr, on, item, selected }">
                <v-chip
                  v-bind="attr"
                  :input-value="selected"
                  v-on="on"
                  small
                  close
                  @click:close="remove(item)"
                >
                  <span v-text="item.DisplayLabel"></span>
                  <v-btn
                    class="ml-1"
                    right
                    x-small
                    icon
                    @click="
                      goToLinkedContentPage(
                        item,
                        item.PageId.$oid,
                        item._id.$oid
                      )
                    "
                  >
                    <v-icon> mdi-arrow-right </v-icon>
                  </v-btn>
                </v-chip>
              </template>
            </v-autocomplete>
            <v-autocomplete
              v-else
              v-model="item.LinkedContent"
              :items="searchResults"
              height="28"
              :placeholder="
                item.LoadItemsWhenCreated ? '' : $t('Search') + '...'
              "
              :loading="searchingContent"
              :search-input.sync="searchContentWatch"
              :item-text="showItem"
              item-value="_id"
              :rules="dropdownRule"
              :clearable="!item.Required"
              hide-no-data
              :disabled="item.ReadOnly"
              @keydown.enter.prevent=""
            >
              <!--  @change="searchContentWatch = ''" -->
              <template v-slot:item="{ item }">
                {{
                  item.DisplayLabels
                    ? item.DisplayLabels[currentLanguage]
                    : item.DisplayLabel
                }}
              </template>
            </v-autocomplete>
            <v-btn
              @click="goToLinkedContentPage(item)"
              :disabled="!item.LinkedContent"
              color="primary"
              class="ml-2"
              icon
              v-if="item.LinkedContentDetailButton && !item.MultipleItems"
            >
              <v-icon> mdi-arrow-right</v-icon>
            </v-btn>
          </v-layout>

          <template v-if="editMode">
            <hr />
            <v-row no-gutters class="my-5">
              <v-col cols="12" sm="6">
                <v-select
                  v-bind:items="getSharedPagesNotAssigned()"
                  single-line
                  v-model="item.LinkedPage"
                  item-value="_id"
                  :label="$t('Select a page')"
                  class="altSelect"
                >
                  <template slot="selection" slot-scope="data">
                    <div class="input-group__selections__comma">
                      {{ data.item.Name[currentLanguage] }}
                    </div>
                  </template>
                  <template slot="item" slot-scope="data">
                    {{ data.item.Name[currentLanguage] }}
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12">
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-layout column>
                      <v-text-field
                        v-model.number="item.colWidth"
                        hide-details="auto"
                        type="number"
                        max="12"
                        min="1"
                        dense
                        :label="$t('Column width')"
                        class="mw-w"
                      ></v-text-field>
                      <v-switch
                        v-model="item.LoadItemsWhenCreated"
                        :label="$t('Load items when created')"
                        hide-details="auto"
                        class="float-left mr-3"
                      ></v-switch>
                      <v-switch
                        v-model="item.MultipleItems"
                        @change="itemChangeMultipleItems(item)"
                        class="float-left mr-3"
                        hide-details="auto"
                        :label="$t('Multiple items')"
                      ></v-switch>
                      <v-switch
                        v-model="item.Required"
                        class="float-left mr-3"
                        hide-details="auto"
                        :label="$t('Field is required')"
                      ></v-switch>
                      <v-switch
                        v-model="item.ReadOnly"
                        class="float-left mr-3"
                        hide-details="auto"
                        :label="$t('Read-only')"
                      ></v-switch>
                    </v-layout>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </template>
        </template>
        <!-- If the item is an image -->
        <template v-else-if="item.Type === 'image'">
          <!-- If there is already an image saved -->
          <template v-if="item.Value.Name">
            <div class="imageContainer">
              <img
                class="pageImage"
                :src="
                  serverUrl +
                  '/images/' +
                  websiteId +
                  '/' +
                  item.Value.Name +
                  '?token=' +
                  userToken
                "
              />
              <v-layout
                v-if="!item.ReadOnly"
                row
                wrap
                class="editContainer mt-2 mt-2 pl-3 pr-3"
              >
                <template v-if="item.AddAltTags || item.AddAltTags == null">
                  <template
                    v-if="item.Translate || item.Translate == undefined"
                  >
                    <v-flex lg3 sm3 xs12 pr-4>
                      <v-select
                        :items="itemLanguages"
                        v-model="itemDefaultLanguage"
                        single-line
                        class="altSelect"
                      ></v-select>
                    </v-flex>
                    <v-flex lg7 sm7 xs10 pr-4>
                      <template
                        v-for="(translatedValue, translatedValueIndex) in item
                          .Value.Alt"
                      >
                        <template
                          v-if="itemDefaultLanguage == translatedValueIndex"
                        >
                          <input
                            :key="translatedValueIndex"
                            type="text"
                            class="textInput"
                            placeholder="Alt tag"
                            v-model="item.Value.Alt[translatedValueIndex]"
                          />
                        </template>
                      </template>
                    </v-flex>
                    <v-flex lg2 sm2 xs2>
                      <v-btn
                        color="error"
                        class="customRemoveButton"
                        icon
                        v-on:click="deleteImage(item)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-flex>
                  </template>
                </template>
              </v-layout>
            </div>
          </template>
          <!-- If no image was saved -->
          <template v-else-if="!item.ReadOnly">
            <h3 v-if="file">
              <v-icon>mdi-image</v-icon>
              {{ file.name }}
              <v-btn color="error" icon v-on:click="deleteSelectedImage()">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </h3>
            <input
              :ref="'fileInput' + objectName"
              class="fileInput"
              type="file"
              v-on:change="setSelectedFile($event.target.files)"
            />

            <v-btn
              class="ml-0 mt-3 mr-3 edit-page-action-button"
              @click="$refs['fileInput' + objectName].click()"
              color="primary"
              >{{ $t("Choose an image") }}</v-btn
            >
            <v-btn
              color="blue-grey"
              class="ml-0 mt-3 white--text edit-page-action-button"
              :disabled="!file || uploading || this.uploadError.length > 0"
              :loading="uploading"
              v-on:click="submitFile(item)"
              >{{ $t("Upload")
              }}<v-icon right dark>mdi-cloud-upload</v-icon></v-btn
            >
          </template>

          <template v-if="editMode">
            <hr class="mt-5" />
            <v-row no-gutters class="my-5">
              <v-col cols="12">
                <v-layout column>
                  <v-text-field
                    v-model.number="item.colWidth"
                    hide-details="auto"
                    type="number"
                    max="12"
                    min="1"
                    dense
                    :label="$t('Column width')"
                    class="mw-w"
                  ></v-text-field>

                  <v-switch
                    v-model="item.ReadOnly"
                    hide-details="auto"
                    class="float-left mr-3"
                    :label="$t('Read-only')"
                  ></v-switch>
                  <v-switch
                    v-model="item.FieldValidation"
                    @change="changeFieldValidation(item)"
                    class="float-left mr-3"
                    :label="$t('Field validation')"
                  >
                  </v-switch>
                  <v-combobox
                    v-if="item.FieldValidation"
                    :items="validations.getAllValidationNames()"
                    class="float-left mr-3"
                    :label="$t('Rule')"
                    v-model="item.FieldValidationFuntion"
                    clearable
                    @click:clear="item.FieldValidationFuntion = ''"
                  >
                  </v-combobox>
                </v-layout>
              </v-col>
            </v-row>
          </template>
        </template>
        <!-- If the item is a cropped image -->
        <template v-else-if="item.Type === 'croppedimage'">
          <template v-if="item.Value.Name">
            <div class="imageContainer">
              <img
                class="pageImage"
                :src="
                  serverUrl +
                  '/images/' +
                  websiteId +
                  '/' +
                  item.Value.Name +
                  '?token=' +
                  userToken
                "
              />
              <v-layout
                v-if="!item.ReadOnly"
                row
                wrap
                class="editContainer mt-2 mt-2 pl-3 pr-3"
              >
                <template v-if="item.AddAltTags || item.AddAltTags == null">
                  <template
                    v-if="item.Translate || item.Translate == undefined"
                  >
                    <v-flex lg3 sm3 xs12 pr-4>
                      <v-select
                        :items="itemLanguages"
                        v-model="itemDefaultLanguage"
                        single-line
                        class="altSelect"
                      ></v-select>
                    </v-flex>
                  </template>
                  <template>
                    <v-flex lg7 sm7 xs10 pr-4>
                      <template
                        v-for="(translatedValue, translatedValueIndex) in item
                          .Value.Alt"
                      >
                        <template
                          v-if="itemDefaultLanguage == translatedValueIndex"
                        >
                          <input
                            :key="translatedValueIndex"
                            type="text"
                            class="textInput"
                            placeholder="Alt tag"
                            v-model="item.Value.Alt[translatedValueIndex]"
                          />
                        </template>
                      </template>
                    </v-flex>
                    <v-flex lg2 sm2 xs2>
                      <v-btn
                        color="error"
                        class="customRemoveButton"
                        icon
                        v-on:click="deleteImage(item)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-flex>
                  </template>
                </template>
              </v-layout>
            </div>
          </template>

          <!-- If no image was saved -->
          <template v-else-if="!item.ReadOnly">
            <v-layout row wrap class="mt-2">
              <v-flex lg6 sm12>
                <VueCropper
                  v-show="selectedImageToCrop"
                  class="mb-3"
                  ref="cropper"
                  :src="selectedImageToCrop"
                  :aspectRatio="item.Width / item.Height"
                >
                </VueCropper>
              </v-flex>
            </v-layout>

            <input
              :ref="'fileInput' + objectName"
              class="fileInput"
              type="file"
              @change="onCroppedImageSelect"
            />

            <v-btn
              class="ml-0 mt-3 mr-3 edit-page-action-button"
              @click="$refs['fileInput' + objectName].click()"
              color="primary"
              >{{ $t("Choose an image") }}</v-btn
            >
            <v-btn
              color="blue-grey"
              class="ml-0 mt-3 white--text edit-page-action-button"
              :disabled="!file || uploading || this.uploadError.length > 0"
              :loading="uploading"
              v-on:click="submitCroppedImage(item)"
              >{{ $t("Upload")
              }}<v-icon right dark>mdi-cloud-upload</v-icon></v-btn
            >
            <errorHandeling
              v-if="uploadError.length > 0"
              :snackbarText="uploadError"
              :buttons="[
                {
                  isText: true,
                  functionName: 'reportError',
                  text: 'Report error',
                },
                {
                  isText: true,
                  functionName: 'clearError',
                  text: 'Close',
                },
              ]"
              snackbarColor="error"
              snackbarTimout="-1"
              snackbarIcon="mdi-alert-circle"
              @clearError="uploadError = ''"
            />
          </template>

          <template v-if="editMode">
            <hr class="mt-5" />
            <v-row no-gutters class="my-5">
              <v-col cols="12">
                <v-text-field
                  :label="$t('Width in pixels')"
                  v-model="item.Width"
                  dense
                  class="float-left mr-3"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :label="$t('Height in pixels')"
                  v-model="item.Height"
                  dense
                  class="float-left mr-3 mt-5"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters class="my-5">
              <v-col cols="12">
                <v-layout column>
                  <v-text-field
                    v-model.number="item.colWidth"
                    hide-details="auto"
                    type="number"
                    max="12"
                    min="1"
                    dense
                    :label="$t('Column width')"
                    class="mw-w"
                  ></v-text-field>
                  <v-switch
                    v-model="item.Translate"
                    class="float-left mr-3"
                    hide-details="auto"
                    :label="$t('Translate item')"
                  ></v-switch>
                  <v-switch
                    v-model="item.AddAltTags"
                    class="float-left mr-3"
                    hide-details="auto"
                    :label="$t('Add alt tags')"
                  ></v-switch>
                  <v-switch
                    v-model="item.ReadOnly"
                    hide-details="auto"
                    class="float-left mr-3"
                    :label="$t('Read-only')"
                  ></v-switch>
                </v-layout>
              </v-col>
            </v-row>
          </template>
        </template>
        <!-- If the item is an image gallery -->
        <template v-else-if="item.Type === 'gallery'">
          <!-- There are images in the gallery -->
          <template v-for="(galleryItem, index) in item.Value">
            <div
              :key="index"
              v-if="galleryItem.Name != ''"
              class="imageContainer gallery mb-4"
            >
              <img
                class="galleryImage"
                :src="
                  serverUrl +
                  '/images/' +
                  websiteId +
                  '/' +
                  galleryItem.Name +
                  '?token=' +
                  userToken
                "
              />

              <v-layout
                v-if="!item.ReadOnly"
                row
                wrap
                class="editContainer mt-2 mt-2 pl-3 pr-3"
              >
                <template v-if="item.AddAltTags || item.AddAltTags == null">
                  <template
                    v-if="item.Translate || item.Translate == undefined"
                  >
                    <v-flex lg3 sm3 xs12 pr-4>
                      <v-select
                        :items="itemLanguages"
                        v-model="itemDefaultLanguage"
                        single-line
                        class="altSelect"
                      ></v-select>
                    </v-flex>
                    <v-flex lg7 sm7 xs10 pr-4>
                      <template
                        v-for="(
                          translatedValue, translatedValueIndex
                        ) in galleryItem.Alt"
                      >
                        <template
                          v-if="itemDefaultLanguage == translatedValueIndex"
                        >
                          <input
                            :key="translatedValueIndex"
                            type="text"
                            class="textInput"
                            placeholder="Alt tag"
                            v-model="galleryItem.Alt[translatedValueIndex]"
                          />
                        </template>
                      </template>
                    </v-flex>
                    <v-flex lg2 sm2 xs2>
                      <v-btn
                        color="error"
                        class="customRemoveButton"
                        icon
                        v-on:click="deleteFile(item.Value, index)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-flex>
                  </template>
                </template>
              </v-layout>
            </div>
          </template>
          <div v-if="!uploading">
            <template v-for="(image, index) in this.files">
              <h3 :key="index">
                <v-icon>mdi-image</v-icon>
                {{ image.name }}
                <v-btn
                  color="error"
                  icon
                  v-on:click="deleteSelectedFile(index)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </h3>
            </template>
          </div>
          <preloader v-else></preloader>

          <input
            v-if="!item.ReadOnly"
            :ref="'galleryFileInput' + objectName"
            class="fileInput"
            multiple
            type="file"
            v-on:change="setSelectedFiles($event.target.files)"
          />
          <v-btn
            v-if="!item.ReadOnly"
            class="ml-0 mt-3 mr-3 edit-page-action-button"
            @click="$refs['galleryFileInput' + objectName].click()"
            color="primary"
            >{{ $t("Choose images") }}</v-btn
          >
          <v-btn
            v-if="!item.ReadOnly"
            color="blue-grey"
            class="ml-0 mt-3 white--text edit-page-action-button"
            :disabled="!files || uploading || this.uploadError.length > 0"
            :loading="uploading"
            v-on:click="submitGalleryFiles(item)"
            >{{ $t("Upload")
            }}<v-icon right dark>mdi-cloud-upload</v-icon></v-btn
          >
          <errorHandeling
            v-if="uploadError.length > 0"
            :snackbarText="uploadError"
            :buttons="[
              {
                isText: true,
                functionName: 'reportError',
                text: 'Report error',
              },
              {
                isText: true,
                functionName: 'clearError',
                text: 'Close',
              },
            ]"
            snackbarColor="error"
            snackbarTimout="-1"
            snackbarIcon="mdi-alert-circle"
            @clearError="uploadError = ''"
          />

          <template v-if="editMode">
            <hr class="mt-5" />
            <v-row no-gutters class="my-5">
              <v-col cols="12">
                <v-layout column>
                  <v-text-field
                    v-model.number="item.colWidth"
                    hide-details="auto"
                    type="number"
                    max="12"
                    min="1"
                    dense
                    :label="$t('Column width')"
                    class="mw-w"
                  ></v-text-field>
                  <v-switch
                    v-model="item.Translate"
                    class="float-left mr-3"
                    hide-details="auto"
                    :label="$t('Translate item')"
                  ></v-switch>
                  <v-switch
                    v-model="item.AddAltTags"
                    class="float-left mr-3"
                    hide-details="auto"
                    :label="$t('Add alt tags')"
                  ></v-switch>
                  <v-switch
                    v-model="item.ReadOnly"
                    hide-details="auto"
                    class="float-left mr-3"
                    :label="$t('Read-only')"
                  ></v-switch>
                </v-layout>
              </v-col>
            </v-row>
          </template>
        </template>
        <!-- If the item is a list of files -->
        <template v-else-if="item.Type === 'files'">
          <v-dialog v-model="showPreview" max-width="1200">
            <v-card>
              <v-card-title>
                <v-layout justify-space-between>
                  {{ showPreviewItem }}
                  <v-btn icon color="error" @click="showPreview = false">
                    <v-icon>mdi-close-circle</v-icon>
                  </v-btn>
                </v-layout>
              </v-card-title>
              <v-card-text>
                <iframe
                  v-if="fileAndVideoCheck('.pdf')"
                  :src="
                    serverUrl +
                    '/files/' +
                    websiteId +
                    '/' +
                    showPreviewItem +
                    '?token=' +
                    userToken
                  "
                  width="100%"
                  height="600px"
                  class="mb-4"
                ></iframe>
                <v-layout justify-center v-if="fileAndVideoCheck('.mp4')">
                  <video class="mb-4" controls width="100%">
                    <source
                      :src="
                        serverUrl +
                        '/files/' +
                        websiteId +
                        '/' +
                        showPreviewItem +
                        '?token=' +
                        userToken
                      "
                      type="video/mp4"
                    />
                  </video>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-dialog>
          <v-select
            v-if="item.TranslatedFiles"
            v-model="itemDefaultLanguage"
            :items="itemLanguages"
            :label="$t('Language')"
            class="max-w-select-lang"
          ></v-select>
          <!-- There are files in the list -->
          <template
            v-for="(file, index) in item.TranslatedFiles
              ? item.Value[itemDefaultLanguage]
              : item.Value"
          >
            <div :key="index + file" v-if="file != ''">
              <h3>
                <v-icon>mdi-paperclip</v-icon>
                {{ file }}
                <v-btn
                  :loading="isDownloading"
                  color="primary"
                  icon
                  @click="setItemForPreview(file)"
                  v-if="!showPreview && includesPDFOrMP4(item)"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
                <v-btn
                  :loading="isDownloading"
                  color="primary"
                  icon
                  @click="showPreview = false"
                  v-else-if="showPreview && includesPDFOrMP4(item)"
                >
                  <v-icon>mdi-eye-off</v-icon>
                </v-btn>
                <v-btn
                  :loading="isDownloading"
                  color="primary"
                  icon
                  @click="downloadFile(file)"
                >
                  <v-icon>mdi-file-download</v-icon>
                </v-btn>
                <v-btn
                  v-if="!item.ReadOnly"
                  color="error"
                  icon
                  :loading="isDownloading"
                  v-on:click="
                    deleteFile(
                      item.TranslatedFiles
                        ? item.Value[itemDefaultLanguage]
                        : item.Value,
                      index
                    )
                  "
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </h3>
            </div>
          </template>
          <template v-for="(file, index) in this.files">
            <h3 :key="index">
              <v-icon>mdi-paperclip</v-icon>
              {{ file.name }}
              <v-btn
                v-if="!item.ReadOnly"
                color="error"
                icon
                v-on:click="
                  deleteFile(
                    item.TranslatedFiles
                      ? item.Value[itemDefaultLanguage]
                      : item.Value,
                    index
                  )
                "
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </h3>
          </template>
          <input
            v-if="!item.ReadOnly"
            :ref="'filesInput' + objectName"
            class="fileInput"
            multiple
            type="file"
            :accept="item.SpecificFileTypes"
            @change="setSelectedFiles($event.target.files)"
          />
          <v-btn
            v-if="!item.ReadOnly"
            class="mt-3 ml-0 mr-3 edit-page-action-button mb-4"
            @click="$refs['filesInput' + objectName].click()"
            color="primary"
            >{{ $t("Choose files") }}</v-btn
          >
          <!-- <v-btn
            v-if="!item.ReadOnly"
            color="blue-grey"
            class="mt-3 ml-0 white--text edit-page-action-button mb-4"
            :disabled="!files || uploading || this.uploadError.length > 0"
            :loading="uploading"
            v-on:click="submitFiles(item)"
            >{{ $t("Upload")
            }}<v-icon right dark>mdi-cloud-upload</v-icon></v-btn
          > -->
          <errorHandeling
            v-if="uploadError.length > 0"
            :snackbarText="uploadError"
            :buttons="[
              {
                isText: true,
                functionName: 'reportError',
                text: 'Report error',
              },
              {
                isText: true,
                functionName: 'clearError',
                text: 'Close',
              },
            ]"
            snackbarColor="error"
            snackbarTimout="-1"
            snackbarIcon="mdi-alert-circle"
            @clearError="uploadError = ''"
          />
          <template v-if="editMode">
            <hr class="mt-5" />

            <v-row no-gutters class="my-5">
              <v-col cols="12">
                <v-layout column>
                  <v-text-field
                    v-model.number="item.colWidth"
                    hide-details="auto"
                    type="number"
                    max="12"
                    min="1"
                    dense
                    :label="$t('Column width')"
                    class="mw-w"
                  ></v-text-field>
                  <v-switch
                    v-model="item.TranslatedFiles"
                    hide-details="auto"
                    class="float-left mr-3"
                    :label="$t('Translate files')"
                    @change="makeOneArray()"
                  ></v-switch>
                  <v-switch
                    v-model="item.SpecificFile"
                    hide-details="auto"
                    class="float-left mr-3"
                    :label="$t('Set file types')"
                  ></v-switch>

                  <v-select
                    v-if="item.SpecificFile"
                    :items="$config.fileTypes"
                    v-model="item.SpecificFileTypes"
                    :label="$t('Files')"
                    multiple
                    chips
                    clearable
                    class="mw-w"
                  >
                  </v-select>
                </v-layout>
              </v-col>
            </v-row>
          </template>
        </template>
        <!-- If the item is a list of custom -->
        <template v-else-if="item.Type === 'custom'">
          <component
            :is="customComponent"
            :componentData="item"
            @saveComponent="saveItem"
          ></component>
        </template>
        <!-- If the item is colorpicker -->
        <template v-else-if="item.Type === 'colorpicker'">
          <v-color-picker
            v-model="item.Value"
            mode="hexa"
            :disabled="item.ReadOnly"
            dot-size="25"
            swatches-max-height="200"
          ></v-color-picker>
          <v-row no-gutters v-if="editMode">
            <v-col cols="12">
              <hr />
            </v-col>
            <v-switch
              v-model="item.ReadOnly"
              :label="$t('Read-only')"
            ></v-switch>
          </v-row>
        </template>
        <!-- If the item is code field -->
        <template v-else-if="item.Type === 'codefield'">
          <prism-editor
            class="my-editor"
            v-model="item.Value"
            :highlight="highlighter"
            :readonly="item.ReadOnly"
            line-numbers
          ></prism-editor>
          <v-row no-gutters v-if="editMode">
            <v-col cols="12">
              <hr />
            </v-col>
            <v-col cols="12">
              <v-layout column>
                <v-switch
                  v-model="item.ReadOnly"
                  :label="$t('Read-only')"
                ></v-switch>
                <v-text-field
                  v-model.number="item.colWidth"
                  hide-details="auto"
                  type="number"
                  max="12"
                  min="1"
                  dense
                  :label="$t('Column width')"
                  class="mt-4 mw-w"
                ></v-text-field>
              </v-layout>
            </v-col>
          </v-row>
        </template>
        <!-- If the item is url -->
        <template v-else-if="item.Type === 'url'">
          <v-layout fill-height align-center justify-space-between>
            <v-text-field
              v-if="editUrl && !item.ReadOnly"
              :label="$t('Website URL')"
              class="mr-8"
              v-model="item.Value"
              :rules="validations.getValidation('notEmptyOrWrongURLRule')"
            ></v-text-field>
            <a v-else-if="item.Value" :href="item.Value" target="_blank">
              <v-layout fill-height align-center justify-start>
                <v-img
                  v-if="!editMode"
                  contain
                  max-height="16"
                  max-width="16"
                  :src="getFavicon(item)"
                  class="mr-2"
                >
                </v-img>
                <p class="mb-0 text-decoration-underline">
                  {{ getDomainUrl(item.Value) }}
                </p>
              </v-layout>
            </a>
            <p v-else class="mb-0">
              {{ this.$t("No URL added yet") }}
            </p>
            <v-switch
              v-if="!item.ReadOnly"
              v-model="editUrl"
              :label="$t('Edit')"
            ></v-switch>
          </v-layout>
          <v-row no-gutters v-if="editMode" class="mt-4">
            <v-col cols="12">
              <v-text-field
                v-model.number="item.colWidth"
                hide-details="auto"
                type="number"
                max="12"
                min="1"
                dense
                :label="$t('Column width')"
                class="mt-4 mw-w"
              ></v-text-field>
              <v-switch
                v-model="item.ReadOnly"
                class="float-left mr-3"
                hide-details="auto"
                :label="$t('Read-only')"
              ></v-switch>
            </v-col>
          </v-row>
        </template>
      </v-card-text>
    </v-card>
  </v-col>
</template>
<script>
import request from "../../request";
import helpers from "../../helpers";
import auth from "../../auth";

import { mapGetters } from "vuex";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import Editor from "@tinymce/tinymce-vue";
import moment from "moment";
import validation from "../../validation";
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css"; // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism-tomorrow.css";
export default {
  data() {
    return {
      uploadError: "",
      file: null,
      files: null,
      uploading: false,
      menu: false,
      newOptionName: "",
      addOptionFieldHasError: false,
      addOptionFieldRule: [],
      newProperty: "",
      addPropertyHasError: false,
      addPropertyRule: [],
      searchingContent: false,
      searchResults: [],
      selectedSearchResult: null,
      itemLanguages: this.getItemLanguages(),
      itemDefaultLanguage: this.getItemDefaultLanguage(),
      selectedPage: null,
      serverUrl: helpers.getServerUrl(),
      searchContentWatch: null,
      selectedImageToCrop: null,
      tinyId: 0,
      validations: validation,
      isDownloading: false,
      editUrl: false,
      showPreview: false,
      showPreviewItem: null,
      userToken: auth.userToken(),
    };
  },
  components: { VueCropper, editor: Editor, PrismEditor },
  props: ["editMode", "item", "websiteId", "pageId", "objectName", "colsWidth"],
  created() {
    if (this.item.LoadItemsWhenCreated) {
      this.searchContent("");
    } else if (this.item.LinkedContent) {
      this.getContent(this.item.LinkedContent);
    }
  },
  computed: {
    ...mapGetters({
      selectedWebsite: "selectedWebsite",
    }),
    currentLanguage: {
      get: function () {
        return this.$i18n.locale();
      },
    },
    editorLanguage: {
      get: function () {
        if (!this.$i18n.locale()) {
          return "nl";
        }
        return this.$i18n.locale() == "Nl" ? "nl" : "fr_FR";
      },
    },
    getDropdownCols() {
      if (this.colsWidth < 8 && this.colsWidth > 4) {
        return 2;
      }
      if (this.colsWidth < 5) {
        return 12;
      }
      return 1;
    },
    getTextCols() {
      if (this.colsWidth < 8 && this.colsWidth > 4) {
        return 10;
      }
      if (this.colsWidth < 5) {
        return 12;
      }
      return 11;
    },
    getItemTitle() {
      if (this.item.TranslatedTitle) {
        if (
          this.item.TranslatedTitle[this.currentLanguage] == "" ||
          !this.item.TranslatedTitle[this.currentLanguage]
        ) {
          return this.item.TranslatedTitle["Nl"];
        } else {
          return this.item.TranslatedTitle[this.currentLanguage];
        }
      }
      return this.item.Title;
    },
    dateRule() {
      if (this.item.Required) {
        return [(v) => !!v || this.$i18n.translate("Date is required")];
      }

      return [];
    },
    fieldRule() {
      if (this.item.Required) {
        return [(v) => !!v || this.$i18n.translate("Field is required")];
      }

      return [];
    },
    dropdownRule() {
      if (this.item.Required) {
        return [(v) => !!v || this.$i18n.translate("You must select an item")];
      }

      return [];
    },
    customComponent() {
      return () => import("@/components/custom/" + this.objectName + ".vue");
    },
    getWidth() {
      return this.item.colWidth;
    },
  },
  watch: {
    searchContentWatch: function (searchValue) {
      if (!this.item.LoadItemsWhenCreated) {
        if (searchValue != "") {
          this.searchContent(searchValue);
        }
      }
    },
    editorLanguage: function () {
      this.tinyId += 1;
    },
    "item.Value": {
      handler(val) {
        switch (this.item.Type) {
          case "datepicker":
            this.item.ValueSort = parseInt(moment(val).format("YYYYMMDD"));
            break;
          case "richtext":
            this.item.ValueSort = val[this.currentLanguage].replace(
              /<[^>]*>?/gm,
              ""
            );
            break;
          case "dropdown":
            this.item.ValueSort = this.item.Options.find(
              (o) => o.Id == val
            ).Value;
            break;
        }
      },
      deep: true,
    },
    "item.LinkedContent": {
      handler(val) {
        if (Array.isArray(val)) {
          var valueSort = "";
          for (var valIndex in val) {
            for (var searchResultIndex in this.searchResults) {
              if (
                val[valIndex].$oid ==
                this.searchResults[searchResultIndex]._id.$oid
              ) {
                valueSort +=
                  this.searchResults[searchResultIndex].DisplayLabel + ", ";
              }
            }
          }
          this.item.ValueSort = valueSort.substring(0, valueSort.length - 2);
        } else if (this.searchResults.find((o) => o._id == val)) {
          if (this.searchResults.find((o) => o._id == val).DisplayLabels) {
            this.item.DisplayLabels = this.searchResults.find(
              (o) => o._id == val
            ).DisplayLabels;
          } else {
            this.item.ValueSort = this.searchResults.find(
              (o) => o._id == val
            ).DisplayLabel;
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    showItem(item) {
      let returnValue = item;
      if (item["DisplayLabels"]) {
        returnValue = item.DisplayLabels[this.currentLanguage];
      } else if (item["DisplayLabel"]) {
        returnValue = item.DisplayLabel;
      }
      return returnValue;
    },
    remove(item) {
      const index = this.item.LinkedContent.findIndex(
        (obj) => obj.$oid == item._id.$oid
      );
      if (index > -1) {
        this.item.LinkedContent.splice(index, 1);
      }
    },
    itemChangeMultipleItems(item) {
      if (item.MultipleItems) {
        if (item["LinkedContentDetailButton"])
          item.LinkedContentDetailButton = !item.LinkedContentDetailButton;
      }
    },
    goToLinkedContentPage(
      item,
      editpageLinkId = item.LinkedPage.$oid,
      editpageContentId = item.LinkedContent.$oid
    ) {
      helpers.goToLinkPage(
        "/page/editpage/" + editpageLinkId + "/addedititem/" + editpageContentId
      );
    },
    getFavicon(item) {
      return helpers.getFaviconFromGoogle(item);
    },
    getDomainUrl(item) {
      return helpers.getDomainUrl(item);
    },
    highlighter(code) {
      return highlight(code, languages.javascript); //returns html
    },
    changeFieldValidation(item) {
      !item.FieldValidation
        ? (item["FieldValidationFuntion"] = null)
        : (item["FieldValidationFuntion"] = "notEmptyStringRule");
    },
    saveItem(event) {
      this.item.Value = event;
    },
    deleteImage(item) {
      item.Value.Name = "";
    },
    deleteSelectedImage() {
      this.file = null;
    },
    submitFile(item) {
      this.uploading = true;
      this.uploadError = "";

      let formData = new FormData();
      formData.append("files", this.file);
      request.post("/content/imageupload/" + this.pageId, formData, (res) => {
        this.uploading = false;
        this.$refs["fileInput" + this.objectName].value = null;
        if (res.success) {
          if (item.Value == "") {
            item.Value = {
              Name: "",
              Alt: {
                Nl: "",
                Fr: "",
                En: "",
              },
            };
          }

          item.Value.Name = res.data[0];
          this.file = null;
        } else {
          this.uploadError = res.message;
        }
      });
    },
    downloadFile(file) {
      this.isDownloading = true;
      request.download(
        "/files/" + this.websiteId + "/" + file + "?token=" + this.userToken,
        { responseType: "blob" },
        file,
        () => {
          this.isDownloading = false;
        }
      );
    },
    fileAndVideoCheck(type) {
      if (this.showPreviewItem) {
        return this.showPreviewItem.includes(type);
      }
      return false;
    },
    includesPDFOrMP4(item) {
      let boolItem = false;
      if (item.Value) {
        if (item.TranslatedFiles) {
          item.Value[this.itemDefaultLanguage].forEach((element) => {
            if (element.includes(".mp4") || element.includes(".pdf")) {
              boolItem = true;
            }
          });
        } else if (Array.isArray(item.Value)) {
          item.Value.forEach((element) => {
            if (element.includes(".mp4") || element.includes(".pdf")) {
              boolItem = true;
            }
          });
        } else {
          for (const key in item.Value) {
            item.Value[key].forEach((element) => {
              if (element.includes(".mp4") || element.includes(".pdf")) {
                boolItem = true;
              }
            });
          }
        }
      }
      return boolItem;
    },
    setSelectedFile(fileList) {
      this.uploadError = "";
      this.file = fileList[0];
    },
    deleteFile(files, index) {
      files.splice(index, 1);
    },
    setSelectedFiles(fileList) {
      this.uploading = true;
      this.uploadError = "";
      this.files = Array.prototype.slice.call(fileList);

      let formData = new FormData();
      for (var i = 0; i < this.files.length; i++) {
        formData.append("files", this.files[i]);
      }

      request.post("/content/fileupload/" + this.pageId, formData, (res) => {
        this.uploading = false;
        this.$refs["filesInput" + this.objectName].value = null;

        if (res.success) {
          if (this.item["TranslatedFiles"]) {
            if (this.item.Value[this.itemDefaultLanguage]) {
              this.item.Value[this.itemDefaultLanguage].push(...res.data);
            } else if (!this.item.Value) {
              this.item.Value = {};
              this.item.Value[this.itemDefaultLanguage] = res.data;
            } else {
              this.item.Value[this.itemDefaultLanguage] = {};
              this.item.Value[this.itemDefaultLanguage] = res.data;
            }
          } else {
            if (this.item.Value) {
              this.item.Value = this.item.Value.concat(res.data);
            } else {
              this.item.Value = res.data;
            }
          }
          this.files = null;
        } else {
          this.files = null;
          this.uploadError = res.message;
        }
      });
    },
    submitGalleryFiles(item) {
      this.uploading = true;
      this.uploadError = "";

      let formData = new FormData();
      for (var i = 0; i < this.files.length; i++) {
        formData.append("files", this.files[i]);
      }
      request.post("/content/imageupload/" + this.pageId, formData, (res) => {
        this.uploading = false;
        this.$refs["galleryFileInput" + this.objectName].value = null;

        if (res.success) {
          if (item.Value == "") {
            item.Value = [];
          } else {
            item.Value = item.Value.filter((i) => i.Name != "");
          }

          for (let i = 0; i < res.data.length; i++) {
            item.Value[item.Value.length] = {
              Name: res.data[i],
              Alt: { Nl: "", Fr: "", En: "" },
            };
          }
          this.getItemLanguages();
          this.getItemDefaultLanguage();
          this.files = null;
        } else {
          this.uploadError = res.message;
        }
      });
    },
    submitFiles(item) {
      this.uploading = true;
      this.uploadError = "";

      let formData = new FormData();
      for (var i = 0; i < this.files.length; i++) {
        formData.append("files", this.files[i]);
      }

      request.post("/content/fileupload/" + this.pageId, formData, (res) => {
        this.uploading = false;
        this.$refs["filesInput" + this.objectName].value = null;

        if (res.success) {
          item.Value = res.data;

          this.files = null;
        } else {
          this.uploadError = res.message;
        }
      });
    },
    deleteSelectedFile(index) {
      this.files.splice(index, 1);
    },
    deleteItem() {
      this.$emit("removeItem");
    },
    addOption() {
      this.addOptionFieldHasError = false;
      this.addOptionFieldRule = [];

      if (this.newOptionName.length > 1) {
        this.item.Options.push({
          Id: this.item.Options.length + 1,
          Value: this.newOptionName,
        });
      } else {
        this.addOptionFieldRule = [
          () => this.$i18n.translate("Minimum required characters is") + " 2",
        ];
        this.addOptionFieldHasError = true;
      }
    },
    addProperty() {
      this.addPropertyHasError = false;
      this.addPropertyRule = [];

      if (this.newProperty.length > 1) {
        this.item.Properties.push({
          Id: this.item.Properties.length + 1,
          Value: this.newProperty,
        });
      } else {
        this.addPropertyRule = [
          () => this.$i18n.translate("Minimum required characters is") + " 2",
        ];
        this.addPropertyHasError = true;
      }
    },
    removeOption(index) {
      this.item.Options.splice(index, 1);
    },
    removeProperty(index) {
      this.item.Properties.splice(index, 1);
    },
    getItemLanguages() {
      return this.$config.contentLanguages;
    },
    getItemDefaultLanguage() {
      return this.$config.contentLanguages[0];
    },
    getSharedPagesNotAssigned() {
      return this.selectedWebsite.Pages.filter(
        (p) => p.RepeatingContent === true
      );
    },
    searchContent(searchValue) {
      if (this.searchingContent) return;
      if (!this.item.LinkedPage) return;
      this.searchingContent = true;

      request.get(
        "/pagecontentsearch/" +
          this.item.LinkedPage.$oid +
          "?searchValue=" +
          searchValue +
          "&language=" +
          this.currentLanguage,
        null,
        (res) => {
          if (res.success) {
            if (this.item.MultipleItems) {
              var concatSearchResults = this.searchResults.concat(res.data);
              for (var i = 0; i < concatSearchResults.length; ++i) {
                for (var j = i + 1; j < concatSearchResults.length; ++j) {
                  if (
                    concatSearchResults[i]._id.$oid ===
                    concatSearchResults[j]._id.$oid
                  )
                    concatSearchResults.splice(j--, 1);
                }
              }

              this.searchResults = concatSearchResults;
            } else {
              this.searchResults = res.data;
            }
          } else {
            this.$store.dispatch("setError", res);
          }

          this.searchingContent = false;
        }
      );
    },
    getContent(id) {
      if (Array.isArray(id)) {
        var getContentUrls = [];
        for (var idIndex in id) {
          getContentUrls.push("/content/" + id[idIndex].$oid);
        }
        request.getAll(getContentUrls, null, (res) => {
          this.searchResults = [];
          for (var resIndex in res) {
            if (res[resIndex].success) {
              this.searchResults.push(res[resIndex].data);
            } else {
              this.$store.dispatch("setError", res[resIndex]);
            }
          }
        });
      } else {
        request.get("/content/" + id.$oid, null, (res) => {
          if (res.success) {
            this.searchResults = [];
            this.searchResults.push(res.data);
          } else {
            this.$store.dispatch("setError", res);
          }
        });
      }
    },
    onCroppedImageSelect(e) {
      this.file = e.target.files[0];
      this.mime_type = this.file.type;

      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.selectedImageToCrop = event.target.result;
          this.$refs.cropper.replace(this.selectedImageToCrop);
        };
        reader.readAsDataURL(this.file);
      }
    },
    submitCroppedImage(item) {
      this.uploading = true;
      this.uploadError = "";

      this.$refs.cropper
        .getCroppedCanvas({ width: this.item.Width })
        .toBlob((blob) => {
          let formData = new FormData();
          formData.append("files", blob, this.file.name);

          request.post(
            "/content/imageupload/" + this.pageId,
            formData,
            (res) => {
              this.uploading = false;
              this.$refs["fileInput" + this.objectName].value = null;

              if (res.success) {
                item.Value.Name = res.data[0];
                this.file = null;
              } else {
                this.uploadError = res.message;
              }
            }
          );
        }, this.mime_type);
    },
    changeTime(time) {
      this.item.Value = time;
    },
    makeOneArray() {
      if (this.item.Value) {
        if (this.item.TranslatedFiles) {
          this.item.Value = { Nl: this.item.Value };
        } else {
          this.item.Value =
            this.item.Value["Nl"] ||
            this.item.Value["Fr"] ||
            this.item.Value["En"];
        }
      }
    },
    setItemForPreview(file) {
      this.showPreviewItem = file;
      this.showPreview = true;
    },
  },
};
</script>

<style>
.v-autocomplete:not(.v-input--is-focused).v-select--chips input {
  max-height: inherit;
}
.mw-w {
  min-width: 2rem;
  max-width: 8rem;
}
.editr--toolbar a.vw-btn-separator {
  display: none;
}

.fileInput {
  display: none;
}

.pageImage {
  max-width: 100%;
  max-height: 500px;
}

.galleryImage {
  max-width: 100%;
  max-height: 350px;
}

.imageContainer {
  position: relative;
  display: table;
  background-color: #fff;
  border: 1px solid #e4e4e4;
  cursor: pointer;
}

.imageContainer img {
  opacity: 1;
  margin-bottom: -6px;
}

.imageContainer:hover img,
.imageContainer:focus img {
  opacity: 0.6;
}

.imageContainer .editContainer {
  background-color: #fff;
  display: none;
  width: 100%;
}

.imageContainer .editContainer .textInput {
  margin: 18px 0;
}

.imageContainer .editContainer .customRemoveButton {
  margin: 14px 0;
}

.imageContainer:hover .editContainer,
.imageContainer:focus .editContainer {
  display: flex;
}
.mw-w2 {
  min-width: 2rem;
  max-width: 16rem;
}
.max-w-select-lang {
  max-width: 4rem;
}
/* required class */
.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #2d2d2d;
  color: #ccc;

  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}

@media only screen and (max-width: 568px) {
  .editr--toolbar {
    height: auto;
    display: table;
  }

  .editr--content {
    clear: both;
  }

  .editr--toolbar div[title] {
    float: left;
  }
}
</style>
