<template>
  <v-col cols="10" v-if="!isCustom">
    <v-row no-gutters>
      <v-col cols="6" class="pr-2">
        <v-row no-gutters>
          <v-col cols="12">
            <v-select
              :items="languages"
              v-model="currentLanguage"
              :label="$t('Language')"
              hide-details="auto"
              class="mb-4 mw-lang"
            ></v-select>
          </v-col>
          <v-col>
            <template v-for="(lang, index) in languages">
              <dragAndDrop
                v-if="cardCheck && lang == currentLanguage"
                :key="index"
                @setSnackbar="$emit('setSnackbar', $event)"
                :multiple="false"
                uploadUrl="/onboardingsteps/upload-image"
                :needsUploadBtn="false"
                :autoUploadAfterSelect="true"
                :accepts="[]"
                @returnFilename="
                  onboardingItem['Images'][currentLanguage] = $event[0]
                "
                :options="{
                  height: '500',
                }"
              />
              <v-img
                v-else-if="lang == currentLanguage"
                :key="index"
                :src="
                  serverUrl +
                  '/images/onboarding/' +
                  onboardingItem['Images'][currentLanguage] +
                  '?token=' +
                  userToken
                "
              >
              </v-img>
            </template>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6" class="pl-2">
        <v-row no-gutters>
          <v-col cols="12">
            <v-select
              :items="languages"
              v-model="currentLanguage"
              :label="$t('Language')"
              hide-details="auto"
              class="mb-4 mw-lang"
            ></v-select>
          </v-col>
          <v-col>
            <template v-for="(lang, index) in languages">
              <editor
                v-if="lang == currentLanguage"
                api-key="f18i0ywzo341k9tx3r3vjsnoghnomdrqbhx2qqi5lnul1dbh"
                tinymce-script-src="/tinymce/js/tinymce/tinymce.min.js"
                v-model="onboardingItem.Texts[lang]"
                :key="index"
                :init="{
                  height: 500,
                  menubar: false,
                  language: editorLanguage,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount',
                  ],
                  toolbar:
                    'undo redo | formatselect | bold italic underline strikethrough | table image link | \
                         alignleft aligncenter alignright alignjustify | \
                         bullist numlist outdent indent | removeformat',
                }"
                :placeholder="$t('Write a text')"
              />
            </template>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-col>
  <v-col cols="10" v-else>
    <v-row no-gutters>
      <v-col cols="1">
        <v-select
          :items="languages"
          v-model="currentLanguage"
          :label="$t('Language')"
          hide-details="auto"
          class="mb-4 mw-lang"
        ></v-select>
      </v-col>
      <v-col cols="11">
        <template v-for="(lang, index) in languages">
          <v-text-field
            v-if="lang == currentLanguage"
            :key="index"
            v-model="onboardingItem.Components[lang]"
            :placeholder="$t('Write the name of the component')"
          ></v-text-field>
        </template>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import DragAndDrop from "../shared/DragAndDrop.vue";
import helpers from "../../helpers";
import auth from "../../auth";

export default {
  components: { editor: Editor, DragAndDrop },
  props: ["onboardingItem", "isCustom"],
  data() {
    return {
      serverUrl: helpers.getServerUrl(),
      userToken: auth.userToken(),
      currentLanguage: this.$i18n.locale(),
      languages: this.$config.languages,
    };
  },

  computed: {
    editorLanguage: {
      get: function () {
        if (!this.$i18n.locale()) {
          return "nl";
        }
        return this.$i18n.locale() == "Nl" ? "nl" : "fr_FR";
      },
    },
    cardCheck() {
      if (
        this.onboardingItem["_id"] &&
        this.onboardingItem["Images"][this.currentLanguage]
      ) {
        return false;
      }
      return true;
    },
  },
  methods: {
    getDate() {
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      const yyyy = today.getFullYear();

      return mm + dd + yyyy;
    },
  },
};
</script>

<style scoped>
.mw-lang {
  max-width: 6rem;
}
</style>