<template>
  <div v-if="Product">
    <backButton />
    <h3>{{ Product.Code }}</h3>
    <p class="subtitle-1" v-if="Product.CommercialDescriptions.length > 0">
      {{ getDiscription(Product.CommercialDescriptions) }}
    </p>
    <v-expansion-panels class="mb-2" v-model="panel" multiple>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <h3>{{ $t("General info") }}</h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col>
              <v-row>
                <v-col cols="4" class="font-weight-bold black--text">
                  {{ $t("Collection") }}</v-col
                >
                <v-col cols="6">
                  <template v-if="Product">
                    <p
                      class="mb-1"
                      v-for="(season, index) in Product.Seasons"
                      :key="index"
                    >
                      {{ season.Code }}
                    </p>
                  </template>
                </v-col>
                <v-col cols="4" class="font-weight-bold black--text">
                  {{ $t("Category") }}</v-col
                >
                <v-col cols="6">{{ category }}</v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-row>
                <v-col cols="4" class="font-weight-bold black--text">
                  {{ $t("Modified") }}</v-col
                >
                <v-col cols="6">{{
                  helper.changeTimeToReadeble(Product.Modified)
                }}</v-col>
                <v-col cols="4" class="font-weight-bold black--text">
                  {{ $t("Verbergen op B2C") }}</v-col
                >
                <v-col cols="6">
                  <v-checkbox
                    class="mt-0 pt-0"
                    hide-details
                    value
                    v-model="Product.HideOnB2C"
                    @change="updateProductHideOnB2C()"
                  ></v-checkbox
                ></v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-row>
                <v-col cols="4" class="font-weight-bold black--text">
                  {{ $t("Created on") }}</v-col
                >
                <v-col cols="6">{{
                  helper.changeTimeToReadeble(Product.Created)
                }}</v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="pt-0">
              <v-row no-gutters>
                <v-layout column>
                  <v-col class="font-weight-bold black--text pt-0">
                    {{ $t("Dynamics image") }}
                  </v-col>
                  <v-col cols="2" v-if="ImagesURLs[Product.Code]">
                    <CoolLightBox
                      :items="ImagesURLs[Product.Code]"
                      :index="indexImages"
                      @close="indexImages = null"
                    >
                    </CoolLightBox>
                    <v-img
                      v-for="(image, imageIndex) in Product.Images"
                      contain
                      height="300"
                      :src="
                        api +
                        '/images/products/' +
                        image +
                        '?token=' +
                        getUserToken
                      "
                      :alt="image"
                      @click="indexImages = imageIndex"
                      :key="imageIndex"
                      class="pointer"
                    >
                    </v-img>
                  </v-col>
                </v-layout>
              </v-row>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <h3>{{ $t("Commercial information") }}</h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row no-gutters class="mt-2 mb-4">
            <v-col
              class="justify-center align-center font-weight-bold black--text"
              cols="1"
              >{{ $t("Staysfresh") }}</v-col
            >
            <v-col cols="4">
              <v-checkbox
                class="mt-0 pt-0"
                hide-details
                value
                v-model="Product.IsStayFresh"
                disabled
              >
              </v-checkbox>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
          <v-tabs class="mt-4">
            <v-tab v-for="tab in tabs" :key="tab">
              {{ $t(tab) }}
            </v-tab>
            <v-tab-item class="mt-4">
              <v-row no-gutters>
                <v-col cols="1">
                  <v-select
                    v-model="lang"
                    :items="langs"
                    :label="$t('Language')"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col
                  cols="12"
                  v-for="item in itemDescriptions"
                  :key="item.LanguageCode"
                >
                  <editor
                    v-if="item.LanguageCode == lang"
                    api-key="f18i0ywzo341k9tx3r3vjsnoghnomdrqbhx2qqi5lnul1dbh"
                    tinymce-script-src="/tinymce/js/tinymce/tinymce.min.js"
                    v-model="item.Value"
                    :key="tinyId + item.LanguageCode"
                    :init="{
                      height: 500,
                      menubar: false,
                      language: editorLanguage,
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount',
                      ],
                      toolbar:
                        'undo redo | formatselect | bold italic underline strikethrough | table image link | \
                 alignleft aligncenter alignright alignjustify | \
                 bullist numlist outdent indent | removeformat',
                    }"
                  />
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-spacer></v-spacer>
                <v-col cols="2" class="text-right mr-1 mb-1 mt-4">
                  <v-btn @click="updateProduct()">
                    {{ $t("Save") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <div
                v-for="(composition, index) in Product.Compositions"
                :key="index"
                class="mt-4 ml-4"
              >
                {{ composition.Percentage + "% " + getItemByLang(composition) }}
              </div>
            </v-tab-item>
            <v-tab-item>
              <div
                v-for="(washCare, index) in Product.WashAndCares"
                :key="index"
                class="mt-4 ml-4"
              >
                <v-img
                  contain
                  height="150"
                  width="150"
                  :src="
                    api +
                    '/images/products/wash-and-cares/' +
                    washCare.Image +
                    '?token=' +
                    getUserToken
                  "
                >
                </v-img>
              </div>
            </v-tab-item>
          </v-tabs>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header
          ><h3>{{ $t("Variants") }}</h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-tabs class="mt-4 ml-4" v-model="tab">
            <v-tab
              v-for="tab in Product.Variants"
              :key="tab.Code"
              @change="tabChange"
            >
              {{ tab.Code }}
            </v-tab>
            <v-tab-item v-for="item in Product.Variants" :key="item.Id">
              <v-row no-gutters class="mt-6">
                <v-col cols="5">
                  <h3 class="mt-3 mb-4">{{ $t("Gross price") }}</h3>
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            {{ $t("Size") }}
                          </th>
                          <th class="text-left">
                            {{ $t("Price") }}
                          </th>
                          <th class="text-left">
                            {{ $t("Stock") }}
                          </th>
                          <th class="text-left">
                            <v-checkbox
                              class="mt-0 pt-0"
                              @change="toggleVariantIsB2cActive(item)"
                              v-model="item.IsActiveB2c"
                              hide-details="auto"
                              dense
                            >
                              <template v-slot:label>
                                <p class="mb-0 text-caption font-weight-bold">
                                  {{ $t("Display stock B2C") }}
                                </p>
                              </template>
                            </v-checkbox>
                          </th>
                          <th>
                            <v-checkbox
                              class="mt-0 pt-0"
                              @change="toggleVariantIsComingSoonB2c(item)"
                              v-model="item.IsComingSoonB2c"
                              hide-details="auto"
                              dense
                            >
                              <template v-slot:label>
                                <p class="mb-0 text-caption font-weight-bold">
                                  {{ $t("Comings soon B2C") }}
                                </p>
                              </template>
                            </v-checkbox>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(stockItem, index) in getGross(
                            item.Stock,
                            item
                          )"
                          :key="index"
                        >
                          <td>{{ stockItem.Size }}</td>
                          <td>
                            <v-layout column>
                              <p
                                class="mb-0"
                                v-for="(price, index) of stockItem.GrossPrice"
                                :key="index"
                              >
                                {{ price }}
                              </p>
                            </v-layout>
                          </td>
                          <td>
                            {{ stockItem.StockCount }}
                          </td>
                          <td>
                            <v-checkbox
                              :disabled="stockItem.SelectedCommingSoonB2c"
                              v-model="stockItem.Selected"
                              @change="
                                toggleStockIsB2CActive(
                                  stockItem,
                                  item.Stock[index]
                                )
                              "
                            ></v-checkbox>
                          </td>
                          <td>
                            <v-checkbox
                              :disabled="stockItem.Selected"
                              v-model="stockItem.SelectedCommingSoonB2c"
                              @change="
                                toggleStockIsComingSoonB2c(
                                  stockItem,
                                  item.Stock[index]
                                )
                              "
                            ></v-checkbox>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="4">
                  <h3 class="mt-6 mb-4">{{ $t("Color") }}</h3>
                  <v-avatar color="white" v-if="item.ColorId">
                    <v-img :src="item.ColorId + '?token=' + getUserToken">
                    </v-img>
                  </v-avatar>
                  <v-avatar v-else color="grey lightend-5"> </v-avatar>
                </v-col>
              </v-row>

              <!--<h3 class="mt-4 mb-4">{{ $t("Commercial images") }}</h3>
              <CoolLightBox
                v-if="ImagesCropedURLs[item.Code]"
                :items="ImagesCropedURLs[item.Code]"
                :index="indexCropImages"
                @close="indexCropImages = null"
              >
              </CoolLightBox>
              <v-row no-gutters class="my-8">
                <v-col
                  cols="2"
                  v-for="(image, i) of item.CommercialImages"
                  :key="image"
                  @dragstart="handleDragStart(i)"
                  @dragover="handleDragOver(i, item.Code, $event)"
                  @dragend="handleDragEnd()"
                  @drop="handleDragEnd()"
                  class="grab"
                  draggable
                >
                  <v-hover v-slot="{ hover }">
                    <v-card :elevation="0" class="py-4 mx-4 hover-featcher">
                      <v-layout justify-end>
                        <v-btn
                          v-if="hover"
                          color="white"
                          icon
                          absolute
                          fab
                          @click="deleteCommercialImage(image)"
                        >
                          <v-icon color="primary"> mdi-close </v-icon>
                        </v-btn>
                      </v-layout>
                      <v-layout justify-center align-center>
                        <v-img
                          contain
                          height="300"
                          :src="
                            api +
                            '/images/products/commercial-images/' +
                            image +
                            '?token=' +
                            getUserToken
                          "
                          :alt="image"
                          @click="indexCropImages = i"
                          :key="i"
                        >
                        </v-img>
                      </v-layout>
                    </v-card>
                  </v-hover>
                </v-col>
                <v-col :cols="!image.src ? 2 : 10">
                  <v-layout align-center justify-start fill-height>
                    <v-btn
                      class="ml-2"
                      fab
                      @click="$refs['file' + item.Code][0].click()"
                      v-if="!image.src"
                    >
                      <v-icon> mdi-plus </v-icon>
                    </v-btn>
                    <input
                      :ref="'file' + item.Code"
                      type="file"
                      accept="image/*"
                      style="display: none"
                      label="upload file"
                      @change="loadImage($event)"
                    />
                    <v-container fluid class="pa-0" v-if="image.src">
                      <v-row no-gutters>
                        <v-col cols="5">
                          <vue-cropper
                            class="cropper"
                            v-show="image.src"
                            :ref="'cropper' + item.Code"
                            :src="image.src"
                          />
                        </v-col>
                        <v-col cols="1">
                          <v-row no-gutters>
                            <v-layout column>
                              <v-col class="pa-0">
                                <v-tooltip right>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      class="mx-2 mb-2"
                                      fab
                                      elevation="0"
                                      small
                                      v-bind="attrs"
                                      v-on="on"
                                      @click="rotate(90, item.Code)"
                                    >
                                      <v-icon> mdi-rotate-right </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>{{ $t("Rotate right") }}</span>
                                </v-tooltip>
                              </v-col>
                              <v-col class="pa-0">
                                <v-tooltip right>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      class="mx-2 mb-2"
                                      fab
                                      elevation="0"
                                      small
                                      v-bind="attrs"
                                      v-on="on"
                                      @click="rotate(-90, item.Code)"
                                    >
                                      <v-icon> mdi-rotate-left </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>{{ $t("Rotate-left") }}</span>
                                </v-tooltip>
                              </v-col>
                              <v-col class="pa-0">
                                <v-tooltip right>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      class="mx-2 mb-2"
                                      fab
                                      elevation="0"
                                      small
                                      v-bind="attrs"
                                      v-on="on"
                                      @click="reset"
                                    >
                                      <v-icon> mdi-restore </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>{{ $t("Reset") }}</span>
                                </v-tooltip>
                              </v-col>
                              <v-col class="pa-0">
                                <v-tooltip right>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      class="mx-2"
                                      fab
                                      elevation="0"
                                      small
                                      v-bind="attrs"
                                      v-on="on"
                                      :disabled="!image.src"
                                      @click="crop(item.Code)"
                                    >
                                      <v-icon> mdi-content-save </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>{{ $t("Save") }}</span>
                                </v-tooltip>
                              </v-col>
                            </v-layout>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-layout>
                </v-col>
              </v-row>-->
            </v-tab-item>
          </v-tabs>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-snackbar
      v-model="snackbar"
      color="success"
      class="text-white"
      :timeout="timeout"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-icon small v-bind="attrs" fab class="mr-2" @click="snackbar = false"
          >mdi-close</v-icon
        >
      </template>
    </v-snackbar>
    <errorHandeling
      v-if="error.length > 0"
      :snackbarText="error"
      snackbarColor="error"
      snackbarTimout="-1"
      snackbarIcon="mdi-warning"
      @clearError="error = ''"
    />
  </div>
</template>

<script>
import request from "../../request";
import axios from "axios";
import Vue from "vue";
//import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import Editor from "@tinymce/tinymce-vue";
import helpers from "../../helpers";
import auth from "../../auth";

export default {
  data() {
    return {
      panel: [0, 1, 2],
      Product: null,
      api: this.$config.apiUrl,
      langs: this.$config.languages,
      lang: "Nl",
      discription: [],
      tabs: ["Description", "Composition", "Wash and care"],
      snackbarText: null,
      category: null,
      snackbar: false,
      timeout: 3000,
      fileName: null,
      timer: null,
      cropedImage: null,
      ImagesURLs: [],
      ImagesCropedURLs: [],
      indexImages: null,
      indexCropImages: null,
      itemDescriptions: [
        { LanguageCode: "Nl", Value: "" },
        { LanguageCode: "Fr", Value: "" },
        { LanguageCode: "En", Value: "" },
      ],
      image: {
        src: null,
        type: null,
      },
      tinyId: 0,
      helper: helpers,
      draggedIndex: null,
      error: "",
      tab: null,
    };
  },

  //components: { VueCropper, CoolLightBox, editor: Editor },
  components: { CoolLightBox, editor: Editor },
  computed: {
    currentLanguage: {
      get: function () {
        return Vue.i18n.locale();
      },
    },
    editorLanguage: {
      get: function () {
        if (!Vue.i18n.locale()) {
          return "nl";
        }
        return Vue.i18n.locale() == "Nl" ? "nl" : "fr_FR";
      },
    },
    getUserToken() {
      return auth.userToken();
    },
  },
  created() {
    if (this.$route.params.productId) {
      request.get(
        "/api/kdesign/product?productId=" + this.$route.params.productId,
        null,
        (res) => {
          this.Product = res;
          this.getCategoryById(res.CategoryId);
          if (res.Descriptions) {
            res.Descriptions.forEach((el) => {
              let des = this.itemDescriptions.find(
                (item) => item.LanguageCode == el.LanguageCode
              );
              if (des) {
                des.Value = el.Value;
              }
            });
          }
          this.ImagesURLs[res.Code] = [];
          if (res.Images) {
            res.Images.forEach((el, i) => {
              this.$set(
                this.ImagesURLs[res.Code],
                i,
                this.api +
                  "/images/products/" +
                  el +
                  "?token=" +
                  this.getUserToken
              );
            });
          }
          res.Variants.forEach(async (element) => {
            this.ImagesCropedURLs[element.Code] = [];
            if (element.CommercialImages) {
              element.CommercialImages.forEach((el, i) => {
                this.$set(
                  this.ImagesCropedURLs[element.Code],
                  i,
                  this.api +
                    "/images/products/commercial-images/" +
                    el +
                    "?token=" +
                    this.getUserToken
                );
              });
            }
            element.ColorId = await this.getColorById(element.ColorId);
          });
        }
      );
    } else {
      this.$router.go(-1);
    }
  },
  methods: {
    handleDragStart(index) {
      this.draggedIndex = index;
    },
    handleDragOver(index, itemId, ev) {
      ev.preventDefault();

      if (this.draggedIndex === index) return;

      const items = this.Product.Variants.find((el) => el.Code == itemId);
      const images = [...items.CommercialImages];
      const draggedImage = images[this.draggedIndex];
      images.splice(this.draggedIndex, 1);
      images.splice(index, 0, draggedImage);
      items.CommercialImages = images;
      this.draggedIndex = index;
      this.updateProduct();
    },
    handleDragEnd() {
      this.draggedIndex = null;
    },
    itemHasProp(variant, prop) {
      return Object.hasOwnProperty.call(variant, prop);
    },
    async getRequest(url, id, code) {
      return await axios.put(
        helpers.getServerUrl() + url + id + "&variantCode=" + code
      );
    },
    toggleVariantIsB2cActive(variant) {
      axios.defaults.headers.common["Language"] = this.currentLanguage;
      axios.defaults.headers.common["Authorization"] =
        localStorage.user == null ? "" : JSON.parse(localStorage.user).Token;

      axios
        .put(
          helpers.getServerUrl() +
            "/api/kdesign/toggle-variant-is-b2c-active?productId=" +
            this.Product.Id +
            "&variantCode=" +
            variant.Code
        )
        .then((res) => {
          const item = this.Product.Variants.find(
            (el) => el.Code == variant.Code
          );
          this.$set(item, "IsActiveB2c", res.data.IsActiveB2c);
          this.$set(item, "Stock", res.data.Stock);
        });
    },
    toggleVariantIsComingSoonB2c(variant) {
      axios.defaults.headers.common["Language"] = this.currentLanguage;
      axios.defaults.headers.common["Authorization"] =
        localStorage.user == null ? "" : JSON.parse(localStorage.user).Token;

      axios
        .put(
          helpers.getServerUrl() +
            "/api/kdesign/toggle-variant-is-b2c-coming-soon?productId=" +
            this.Product.Id +
            "&variantCode=" +
            variant.Code
        )
        .then((res) => {
          const item = this.Product.Variants.find(
            (el) => el.Code == variant.Code
          );
          this.$set(item, "IsComingSoonB2c", res.data.IsComingSoonB2c);
          this.$set(item, "Stock", res.data.Stock);
        });
    },
    getGross(itemStock, item) {
      let prices = [];

      itemStock.forEach((element) => {
        let grossPrices = [];
        element.GrossPrices.forEach((el) => {
          grossPrices.push(this.getcurrency(el.Code) + el.Price);
        });
        prices.push({
          Size: element.Size,
          GrossPrice: grossPrices,
          StockCount:
            element.ActualAmount == 999999 ? "" : element.AvailableAmount,
          Selected: element.IsActiveB2c,
          SelectedCommingSoonB2c: element.IsComingSoonB2c,
          Code: item.Code,
          IsActiveB2cProduct: item.IsActiveB2c,
          IsComingSoonB2c: item.IsComingSoonB2c,
        });
      });
      return prices;
    },
    loadImage(event) {
      this.fileName = event.target.files[0].name;
      // Reference to the DOM input element
      const file = event.target.files[0];
      // Ensure that you have a file before attempting to read it
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.image.src = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    destroyed() {
      // Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
      if (this.image.src) {
        URL.revokeObjectURL(this.image.src);
      }
    },
    reset() {
      this.image = {
        src: null,
        type: null,
      };
      this.cropedImage = null;
    },
    async crop(id) {
      this.cropedImage = this.$refs["cropper" + id][0]
        .getCroppedCanvas()
        .toDataURL();
      this.uploadCropedImage(id);
    },
    uploadCropedImage(id) {
      const canvas = this.$refs["cropper" + id][0].getCroppedCanvas();
      if (canvas) {
        let form = new FormData();
        canvas.toBlob((blob) => {
          form.append("files", blob, this.fileName);
          request.post(
            "/api/kdesign/productvariantcommercialimage",
            form,
            (res) => {
              if (res.success) {
                this.snackbar = true;
                this.snackbarText = this.$t("Image successfully uploaded");
                let item = this.Product.Variants.find((el) => el.Code == id);
                item.CommercialImages.push(res[0]);
                this.updateProduct();
                this.ImagesCropedURLs[id].push(
                  this.api +
                    "/images/products/commercial-images/" +
                    res[0] +
                    "?token=" +
                    this.getUserToken
                );
                this.cropedImage = null;
                this.image = {
                  src: null,
                  type: null,
                };
                this.destroyed();
              }
            }
          );
        });
      }
    },
    rotate(angle, id) {
      this.$refs["cropper" + id][0].rotate(angle);
    },

    getDiscription(item) {
      return item.find((el) => el.LanguageCode == this.currentLanguage)
        ? item.find((el) => el.LanguageCode == this.currentLanguage).Value
        : "";
    },

    getCategoryById(id) {
      /* CategoryId */
      request.get(
        "/api/kdesign/productcategory?categoryId=" + id,
        null,
        (res) => {
          if (res.Names) {
            this.category = this.capitalizeFirstLetter(
              res.Names.find((el) => el.LanguageCode == this.currentLanguage)
                .Value
            );
          }
          this.category = this.capitalizeFirstLetter(res.Code.toLowerCase());
        }
      );
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    getItemByLang(items) {
      if (items.Translations.length) {
        return items.Translations.find(
          (el) => el.LanguageCode == this.currentLanguage
        ).Value;
      }
      return items.Code;
    },
    async getColorById(id) {
      if (id) {
        const color = await axios
          .get(this.api + "/api/kdesign/productcolor?colorId=" + id)
          .then((res) => {
            return res.data.Image;
          });
        return this.api + "/images/products/colors/" + color;
      }
    },
    getcurrency(item) {
      let currencySign;
      switch (item) {
        case "EUR":
          currencySign = "€ ";
          break;
        case "GBP":
          currencySign = "£ ";
          break;
        case "USD":
          currencySign = "$ ";
          break;

        default:
          currencySign = "€ ";
          break;
      }
      return currencySign;
    },

    deleteCommercialImage(id) {
      this.Product.Variants.forEach((element, index) => {
        this.Product.Variants[index].CommercialImages =
          element.CommercialImages.filter((el) => el != id);
        this.ImagesCropedURLs[element.Code] = [];
        element.CommercialImages.forEach((el, i) => {
          this.$set(
            this.ImagesCropedURLs[element.Code],
            i,
            this.api +
              "/images/products/commercial-images/" +
              el +
              "?token=" +
              this.getUserToken
          );
        });
      });
      this.updateProduct();
    },

    updateProduct() {
      let variants = [];
      this.Product.Variants.forEach((element) => {
        variants.push({
          Code: element.Code,
          CommercialImages: [...element.CommercialImages],
        });
      });

      request.put(
        "/api/kdesign/product",

        {
          Id: this.Product.Id,
          Descriptions: [...this.itemDescriptions],
          Variants: variants,
        },
        () => {}
      );
    },
    tabChange() {
      this.cropedImage = null;
      this.image = {
        src: null,
        type: null,
      };
      this.destroyed();
    },
    toggleStockIsB2CActive(item, itemObj) {
      this.Product.Variants.forEach((element) => {
        if (element.Code == item.Code) {
          element.Stock.forEach((el) => {
            if (itemObj.VariantCode == el.VariantCode) {
              this.$set(el, "IsActiveB2c", item.Selected);
            }
          });
        }
      });

      request.put(
        "/api/kdesign/toggle-stock-is-b2c-active?productId=" +
          this.Product.Id +
          "&variantCode=" +
          item.Code +
          "&size=" +
          item.Size,
        null,
        (res) => {
          if (res.success) {
            this.changeVariantDataProduct(item);
          }
        }
      );
    },
    toggleStockIsComingSoonB2c(item, itemObj) {
      this.Product.Variants.forEach((element) => {
        if (element.Code == item.Code) {
          element.Stock.forEach((el) => {
            if (itemObj.VariantCode == el.VariantCode) {
              this.$set(el, "IsComingSoonB2c", item.SelectedCommingSoonB2c);
            }
          });
        }
      });
      request.put(
        "/api/kdesign/toggle-stock-is-b2c-coming-soon?productId=" +
          this.Product.Id +
          "&variantCode=" +
          item.Code +
          "&size=" +
          item.Size,
        null,
        (res) => {
          if (res.success) {
            this.changeVariantDataProduct(res);
          }
        }
      );
    },
    changeVariantDataProduct(data) {
      this.Product.Variants.forEach((element, index) => {
        element.Stock.forEach((el, i) => {
          if (el.VariantCode == data.VariantCode) {
            this.$set(
              this.Product.Variants[index].Stock[i],
              "IsActiveB2c",
              data.Stock[i]["IsActiveB2c"]
            );
            this.$set(
              this.Product.Variants[index].Stock[i],
              "IsComingSoonB2c",
              data.Stock[i]["IsComingSoonB2c"]
            );
          }
        });
      });
    },
    updateProductHideOnB2C() {
      request.put(
        "/api/kdesign/update-hide-on-b2c?productId=" +
          this.Product.Id +
          "&hideOnB2c=" +
          this.Product.HideOnB2C,
        null,
        (res) => {
          if (!res.success) {
            this.error = res.message;
          }
        }
      );
    },
  },
};
</script>

<style scoped >
.cropper {
  height: 550px;
  width: 550px;
}
.show-btns {
  color: rgba(255, 255, 255, 1) !important;
}
.blur {
  filter: blur(2px);
}
.hover-featcher:hover {
  transition: 0.25s;
}
.pointer {
  cursor: pointer;
}
.grab {
  cursor: grab;
}
.dragging {
  cursor: grabbing !important;
}
</style>

