<template>
  <v-layout justify-center align-center>
    <v-badge :color="badgeColor(item.Size)" dot inline></v-badge>
    <p class="mb-0">
      {{ item.Size }}
    </p>

    <v-tooltip v-model="show[index]" bottom color="#1e1e1e">
      <template v-slot:activator="{ on, attrs }">
        <v-sheet
          width="16"
          height="16"
          color="grey lighten-3 ml-1"
          class="rounded-circle"
        >
          <p class="mb-0 cursor text-color" v-bind="attrs" v-on="on">
            {{ getcurrency }}
          </p>
        </v-sheet>
      </template>
      <span v-if="currency">
        <v-simple-table dark>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">{{ $t("Code") }}</th>
                <th class="text-left">{{ $t("Purchase price") }}</th>
                <th class="text-left">{{ $t("Selling price") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(productVariant, i) in product.Variants" :key="i">
                <td>{{ productVariant.Code }}</td>
                <td>
                  {{
                    getcurrency +
                    correctItemPrice(
                      stockItems
                        ? productVariant.Reserved.find(
                            (el) => el.Size == item.Size
                          )
                        : productVariant.Stock.find(
                            (el) => el.Size == item.Size
                          )
                    )
                  }}
                </td>
                <td>
                  {{
                    getcurrency +
                    correctItemPrice(
                      stockItems
                        ? productVariant.Reserved.find(
                            (el) => el.Size == item.Size
                          )
                        : productVariant.Stock.find(
                            (el) => el.Size == item.Size
                          ),
                      "GrossPrices"
                    )
                  }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </span>
      <!--  <span > {{ getcurrency + 0 }} </span> -->
    </v-tooltip>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: [
    "item",
    "index",
    "product",
    "currencyItem",
    "stockItems",
    "cartItems",
  ],
  data() {
    return {
      show: [],
      currency: this.currencyItem,
    };
  },

  computed: {
    ...mapGetters({
      productsWithColor: "productsWithColor",
    }),

    getcurrency() {
      let currencySign;
      switch (this.currency) {
        case "EUR":
          currencySign = "€ ";
          break;
        case "GBP":
          currencySign = "£ ";
          break;
        case "USD":
          currencySign = "$ ";
          break;

        default:
          currencySign = "€ ";
          break;
      }
      return currencySign;
    },
  },
  methods: {
    badgeColor(size) {
      const items = this.productsWithColor.filter(
        (el) => el.productVisableCode == this.product.Code && el.size == size
      );

      if (items.length == 0) {
        return "white";
      } else if (items.filter((el) => el.color == "warning").length > 0) {
        return "warning";
      } else if (items.filter((el) => el.color == "success").length > 0) {
        return "success";
      } else {
        return "white";
      }
    },
    correctItemPrice(item, prices = "NetPrices") {
      if (!item || undefined) {
        return 0;
      }
      let price = 0;
      item[prices].forEach((element) => {
        if (element.Code == this.currency) {
          return (price = element.Price);
        }
      });
      return price;
    },

    changeCurrency(currency) {
      if (currency) {
        this.currency = currency;
      }
    },
  },
};
</script>

<style>
</style>