<template>
  <div>
    <v-layout align-center column v-if="isStockItem && !isReservedItem">
      <v-btn
        @click="changeAmountUp()"
        tabindex="-1"
        :disabled="
          item.Stock.find((el) => el.Size == size).AvailableAmount - amountOf ==
          0
        "
        icon
      >
        <v-icon small>mdi-chevron-up</v-icon>
      </v-btn>

      <input
        v-model="amountOfItems"
        type="number"
        @input="changeAmount($event.target.value)"
        class="inputwidth text--center ma-0 pa-0 jsinput"
        :class="[
          item.Stock.find((el) => el.Size == size).AvailableAmount - amountOf ==
          1
            ? 'inputborderOrange'
            : '',
          item.Stock.find((el) => el.Size == size).AvailableAmount - amountOf >
          1
            ? 'inputborder'
            : '',
          item.Stock.find((el) => el.Size == size).AvailableAmount - amountOf <=
          0
            ? 'inputborderRed'
            : '',
        ]"
      />
      <v-btn
        @click="changeAmountDown()"
        :disabled="amountOf == 0"
        tabindex="-1"
        icon
      >
        <v-icon small>mdi-chevron-down</v-icon>
      </v-btn>
    </v-layout>
    <v-layout align-center column v-else>
      <v-btn
        @click="
          isReservedItem ? changeAmountUpReserved() : changeUpReservedNew()
        "
        tabindex="-1"
        :disabled="checkForDisableUp()"
        icon
      >
        <v-icon small>mdi-chevron-up</v-icon>
      </v-btn>
      <v-layout align-center column>
        <input
          :readonly="!isReservedItem"
          v-model="amountOfItems"
          @input="changeAmountReserved($event.target.value)"
          type="number"
          class="inputwidth text--center ma-0 pa-0 jsinput inputborder"
          :class="[
            isReservedItem
              ? item.Reserved.find((el) => el.Size == size).AvailableAmount -
                  (Number(this.amountOf) -
                    item.Reserved.find((el) => el.Size == size)
                      .ReservedAmount) ==
                1
                ? 'inputborderOrange'
                : ''
              : '',

            isReservedItem
              ? item.Reserved.find((el) => el.Size == size).AvailableAmount -
                  (Number(this.amountOf) -
                    item.Reserved.find((el) => el.Size == size)
                      .ReservedAmount) <=
                0
                ? 'inputborderRed'
                : ''
              : '',
          ]"
        />
      </v-layout>
      <v-btn
        @click="changeAmountDownReserved()"
        :disabled="amountOf == 0"
        tabindex="-1"
        icon
      >
        <v-icon small>mdi-chevron-down</v-icon>
      </v-btn>
    </v-layout>
    <errorHandeling
      v-if="error.length > 0"
      ref="errorHandeling"
      :snackbarText="error"
      :buttons="[
        {
          isText: true,
          functionName: 'clearError',
          text: 'Close',
        },
      ]"
      snackbarColor="error"
      snackbarTimout="3000"
      snackbarIcon="mdi-warning"
      @clearError="error = ''"
    />
  </div>
</template>

<script>
export default {
  props: [
    "item",
    "amount",
    "size",
    "productCode",
    "isStockItem",
    "isReservedItem",
    "isNullItem",
    "productVisableCode",
  ],
  data() {
    return {
      amountOf: this.amount,
      timer: null,
      error: "",
    };
  },
  computed: {
    amountOfItems: {
      get() {
        return this.amountOf;
      },
      set(item) {
        this.amountOf = item;
      },
    },
  },

  created() {
    !this.isStockItem && !this.isReservedItem ? (this.amountOf = 0) : "";
    if (this.isReservedItem) {
      this.item.Reserved.forEach((element) => {
        this.$store.dispatch("setProductColor", {
          item: element,
          amount: this.amountOf,
          code: this.item.Code,
          productCode: this.productCode,
          productVisableCode: this.productVisableCode,
        });
      });
    }
  },

  methods: {
    checkForDisableUp() {
      let item = this.item.Reserved.find((el) => el.Size == this.size);
      if (item) {
        return (
          item.AvailableAmount - (this.amountOf - item.ReservedAmount) == 0
        );
      }
      return false;
    },
    getReservedAvaileble() {
      let reservedItem = this.item.Reserved.find((el) => el.Size == this.size);
      let returnItem;
      if (!reservedItem) {
        return "";
      }
      if (reservedItem.AvailableAmount == 1) {
        returnItem = "inputborderOrange";
      }
      if (reservedItem.AvailableAmount > 1) {
        returnItem = "inputborder";
      }
      return returnItem;
    },
    changeAmountUp() {
      let stockItem = this.item.Stock.find((el) => el.Size == this.size);
      let netpriceValidation = true;
      stockItem.NetPrices.forEach((netprice) => {
        if (netprice.Price == 0) {
          netpriceValidation = false;
        }
      });
      if (stockItem.AvailableAmount > this.amountOf && netpriceValidation) {
        this.amountOf = Number(this.amountOf) + 1;
        this.$store.dispatch("setProductColor", {
          item: stockItem,
          amount: this.amountOf,
          code: this.item.Code,
          productCode: this.productCode,
          productVisableCode: this.productVisableCode,
        });
        this.$emit("amountChangeStock", {
          amount: this.amountOf,
          size: this.size,
          item: this.item,
          productCode: this.productCode,
        });
      } else if (!netpriceValidation) {
        this.amountOf = 0;
        if (this.$refs.errorHandeling) {
          this.$refs.errorHandeling.openSnackbar();
        }
        this.error =
          this.$t("Net price is") +
          " 0." +
          this.$t("Adding to shopping cart is not possible.");
      }
    },
    changeAmountDown() {
      let stockItem = this.item.Stock.find((el) => el.Size == this.size);
      if (Number(this.amountOf) > 0) {
        this.amountOf = Number(this.amountOf) - 1;
        this.$store.dispatch("setProductColor", {
          item: stockItem,
          amount: this.amountOf,
          code: this.item.Code,
          productCode: this.productCode,
          productVisableCode: this.productVisableCode,
        });
        this.$emit("amountChangeStock", {
          amount: this.amountOf,
          size: this.size,
          item: this.item,
          productCode: this.productCode,
        });
      } else {
        this.$store.dispatch("setProductColor", {
          item: stockItem,
          amount: this.amountOf,
          code: this.item.Code,
          productCode: this.productCode,
          productVisableCode: this.productVisableCode,
        });
        this.$emit("deleteAmountStock", {
          item: this.item,
          productCode: this.productCode,
        });
      }
    },
    changeAmount(item) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        let stockItem = this.item.Stock.find((el) => el.Size == this.size);
        let netpriceValidation = true;
        stockItem.NetPrices.forEach((netprice) => {
          if (netprice.Price == 0) {
            netpriceValidation = false;
          }
        });
        if (netpriceValidation) {
          if (stockItem.AvailableAmount > Number(item) && Number(item) >= 0) {
            this.amountOf = Number(item);
            this.$store.dispatch("setProductColor", {
              item: stockItem,
              amount: this.amountOf,
              code: this.item.Code,
              productCode: this.productCode,
              productVisableCode: this.productVisableCode,
            });
            this.$emit("amountChangeStock", {
              amount: Number(item),
              size: this.size,
              item: this.item,
              productCode: this.productCode,
            });
          } else {
            this.amountOf = stockItem.AvailableAmount;
            this.$store.dispatch("setProductColor", {
              item: stockItem,
              amount: this.amountOf,
              code: this.item.Code,
              productCode: this.productCode,
              productVisableCode: this.productVisableCode,
            });
            this.$emit("amountChangeStock", {
              amount: stockItem.AvailableAmount,
              size: this.size,
              item: this.item,
              productCode: this.productCode,
            });
          }
        } else {
          if (this.$refs.errorHandeling) {
            this.$refs.errorHandeling.openSnackbar();
          }
          this.error =
            this.$t("Net price is") +
            " 0. " +
            this.$t("Adding to shopping cart is not possible.");
          this.amountOf = 0;
        }
      }, 150);
    },
    changeAmountUpReserved() {
      let reservedItem = this.item.Reserved.find((el) => el.Size == this.size);
      let netpriceValidation = true;
      reservedItem.NetPrices.forEach((netprice) => {
        if (netprice.Price == 0) {
          netpriceValidation = false;
        }
      });
      if (
        reservedItem.AvailableAmount > 0 &&
        reservedItem.AvailableAmount >
          Number(this.amountOf) - reservedItem.ReservedAmount &&
        netpriceValidation
      ) {
        reservedItem.BasketReservedAmount++;
        this.amountOf = reservedItem.BasketReservedAmount;
        this.$store.dispatch("setProductColor", {
          item: reservedItem,
          amount: this.amountOf,
          code: this.item.Code,
          productCode: this.productCode,
          productVisableCode: this.productVisableCode,
        });
        this.$emit("amountChangeReserved", {
          amount: Number(this.amountOf),
          size: this.size,
          item: this.item,
          productCode: this.productCode,
        });
      } else {
        if (this.$refs.errorHandeling) {
          this.$refs.errorHandeling.openSnackbar();
        }
        if (this.$refs.errorHandeling) {
          this.$refs.errorHandeling.openSnackbar();
        }
        this.error =
          this.$t("Net price is") +
          " 0. " +
          this.$t("Adding to shopping cart is not possible.");
        this.amountOf = 0;
      }
    },
    changeAmountDownReserved() {
      let reservedItem = this.item.Reserved.find((el) => el.Size == this.size);
      if (
        Number(this.amountOf) > 0 &&
        reservedItem.BasketReservedAmount > 0 &&
        reservedItem.AvailableAmount >
          reservedItem.AvailableAmount - Number(this.amountOf)
      ) {
        reservedItem.BasketReservedAmount--;
        this.amountOf = Number(this.amountOf) - 1;
        this.$store.dispatch("setProductColor", {
          item: reservedItem,
          amount: this.amountOf,
          code: this.item.Code,
          productCode: this.productCode,
          productVisableCode: this.productVisableCode,
        });
        this.$emit("amountChangeReserved", {
          amount: this.amountOf,
          size: this.size,
          item: this.item,
          productCode: this.productCode,
        });
      }
    },
    changeAmountReserved(item) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        let netpriceValidation = true;

        let reservedItem = this.item.Reserved.find(
          (el) => el.Size == this.size
        );
        reservedItem.NetPrices.forEach((netprice) => {
          if (netprice.Price == 0) {
            netpriceValidation = false;
          }
        });
        if (reservedItem.AvailableAmount > Number(item) && Number(item) >= 0) {
          this.amountOf = Number(item);
          this.$store.dispatch("setProductColor", {
            item: reservedItem,
            amount: this.amountOf,
            code: this.item.Code,
            productCode: this.productCode,
            productVisableCode: this.productVisableCode,
          });
          this.$emit("amountChangeReserved", {
            amount: Number(item),
            size: this.size,
            item: this.item,
            productCode: this.productCode,
          });
        } else if (!netpriceValidation) {
          this.amountOf = 0;
          if (this.$refs.errorHandeling) {
            this.$refs.errorHandeling.openSnackbar();
          }
          this.error =
            this.$t("Net price is") +
            " 0." +
            this.$t("Adding to shopping cart is not possible.");
        } else {
          this.amountOf =
            reservedItem.AvailableAmount + reservedItem.ReservedAmount;
          this.$store.dispatch("setProductColor", {
            item: reservedItem,
            amount: this.amountOf,
            code: this.item.Code,
            productCode: this.productCode,
            productVisableCode: this.productVisableCode,
          });
          this.$emit("amountChangeReserved", {
            amount: this.amountOf,
            size: this.size,
            item: this.item,
            productCode: this.productCode,
          });
        }
      }, 800);
    },

    changeUpReservedNew() {
      this.amountOf++;
      this.$emit("amountUpReservedNew", {
        amount: this.amountOf,
        size: this.size,
        item: this.item,
        productCode: this.productCode,
      });
    },
    reset() {
      this.amountOf = 0;
    },
  },
};
</script>

<style scoped>
.v-text-field {
  width: 4rem;
}
.inputborder {
  border: solid 1px;
  border-color: black;
  border-radius: 0.25rem;
}
.inputborderOrange {
  border: solid 1px;
  border-color: orange !important;
  border-radius: 0.25rem;
}
.inputborderRed {
  border: solid 1px;
  border-color: red !important;
  border-radius: 0.25rem;
}
.centered-input >>> input {
  text-align: center;
}
.inputhight {
  height: 2rem !important;
  max-width: 1rem;
  text-align: center;
}
.spacer-width {
  width: 1rem !important;
}
.cursor {
  cursor: pointer;
}
.text-color {
  color: #616161;
}
.max-w-text {
  width: 2rem;
}
.inputwidth {
  max-width: 2rem;
  text-align: center;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>