<template>
  <div @dragover="dragOver($event)" @drop="drop($event)">
    <div v-if="!loading">
      <v-row no-gutters>
        <v-col>
          <back-button />
        </v-col>
        <v-col cols="12">
          <h2>{{ $t("Shopping cart") }}</h2>

          <v-row no-gutters class="mt-6">
            <v-col cols="2">
              <v-combobox
                clearable
                v-if="showDropdown"
                v-model="customer"
                :items="getCustomersNameAndCode"
                item-text="Name"
                item-value="Id"
                dense
                hide-details
                :label="$t('Customer')"
                class="mb-6"
                @change="changeCustomer()"
                @click:clear="customer = null"
              ></v-combobox>
            </v-col>
            <v-col
              :class="
                showDropdown
                  ? 'ml-4 d-flex align-center'
                  : 'd-flex align-center'
              "
            >
              <v-badge class="mb-2 mr-2" color="success" inline>{{
                $t("Available immediately")
              }}</v-badge>
              <v-badge class="mb-2" color="warning" inline>{{
                $t("Not immediately available")
              }}</v-badge>
            </v-col>
          </v-row>
          <v-row no-gutters class="ml-4" v-if="!noProductsInBasked">
            <v-col
              cols="12"
              v-if="
                shoppingcart.length > 0 &&
                stockItems.length == shoppingcart.length
              "
            >
              <v-row
                no-gutters
                v-for="(
                  productItem, index
                ) in getShoppingCartProductsSortedByNr"
                :key="productItem.Id"
              >
                <orderList
                  :product="productItem"
                  :productImages="productImages"
                  :ref="'productcart' + index"
                  :isCartItem="true"
                  :stockItems="stockItems.find((el) => el.id == productItem.Id)"
                  :currencyFromParent="currency"
                  :customerId="getBaskedCustomerId"
                  @changePrice="getBasketOverview"
                  @updateTotalPrice="updateTotalPrice"
                />
                <!--          rightStockItemHeader(index, productItem)       @changePrice="getBasketOverview"
   -->
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <v-divider class="mr-4"></v-divider>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col v-if="isLoadingItems">
              <v-progress-circular
                color="primary"
                :size="50"
                indeterminate
              ></v-progress-circular>
            </v-col>
            <v-col v-else class="pl-0">
              <h3>{{ $t("Shopping Cart is empty!") }}</h3>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-expansion-panels
        @dragstart="startDrag($event)"
        draggable="true"
        v-model="panel"
        multiple
        class="panel-custom"
        :style="{ left: x + 'px', top: y + 'px' }"
      >
        <v-expansion-panel outlined rounded elevation="2 ">
          <v-expansion-panel-header>
            <v-layout align-center fill-height>
              <v-icon color="primary" class="mr-2"> mdi-drag </v-icon>
              <h2>{{ $t("Overview") }}</h2>
            </v-layout>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col class="text-left" cols="7">{{
                $t("Price excl. BTW")
              }}</v-col>
              <v-col class="text-right" v-if="!isLoadingItems" cols="5">
                {{ priceExclVAT }}</v-col
              >
              <v-col class="text-right" v-else
                ><v-progress-circular
                  color="primary"
                  :size="20"
                  indeterminate
                ></v-progress-circular>
              </v-col>
            </v-row>
            <v-layout justify-center>
              <v-divider class="max-width"></v-divider>
            </v-layout>
            <v-row v-if="isVatLaible">
              <v-col class="text-left">{{ $t("BTW 21%") }}</v-col>
              <v-col class="text-right" v-if="!isLoadingItems">{{
                priceVAT
              }}</v-col>
              <v-col class="text-right" v-else>
                <v-progress-circular
                  color="primary"
                  :size="20"
                  indeterminate
                ></v-progress-circular
              ></v-col>
            </v-row>
            <v-divider v-if="isVatLaible"></v-divider>
            <v-row>
              <v-col class="text-left">{{ $t("Total") }}</v-col>
              <v-col class="text-right" v-if="!isLoadingItems">
                {{ getTotal }}</v-col
              >
              <v-col class="text-right" v-else
                ><v-progress-circular
                  color="primary"
                  :size="20"
                  indeterminate
                ></v-progress-circular>
              </v-col>
            </v-row>
            <v-row>
              <v-btn
                block
                @click="goToShipping()"
                :loading="isLoadingItems"
                :disabled="noProductsInBasked"
              >
                {{ $t("To shipping") }}</v-btn
              >
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <div v-else>
      <preloader />
    </div>
  </div>
</template>

<script>
import request from "../../request";
import auth from "../../auth";

export default {
  data() {
    return {
      panel: [0],
      shoppingcart: [],
      totlaNetPrices: null,
      VAT: 21,
      vatPrice: 0,
      totalPrice: null,
      productPrices: [],
      indexImage: null,
      apiUrl: this.$config.apiUrl,
      productImages: [],
      currency: null,
      isVatLaible: null,
      stockItems: [],
      customers: [],
      customer: null,
      noProductsInBasked: false,
      isLoadingItems: false,
      basketId: null,
      customerId: null,
      showDropdown: false,

      x: window.innerWidth - 600,
      y: window.innerHeight - 375,
      loading: true,
    };
  },
  created() {
    request.get("/api/kdesign/authenticateduser", null, (res) => {
      this.loading = false;
      if (res.success) {
        if (
          !auth.userIsAdministrator() &&
          res.Roles.indexOf("SalesAgent") == -1
        ) {
          if (res.Customer.OrderInput == false) {
            this.$router.go(-1);
          }
        }
        if (
          res.Roles.indexOf("SalesAgent") >= 0 ||
          res.Roles.indexOf("Management") >= 0
        ) {
          this.showDropdown = true;
        } else if (res.Roles.indexOf("B2B") >= 0 && res.Roles.length == 1) {
          this.showDropdown = false;
        }
        this.getCustomers();
      }
    });
  },
  computed: {
    getBaskedCustomerId() {
      if (this.customerId) {
        return this.customerId;
      }
      return null;
    },
    getCustomersNameAndCode() {
      if (!this.customers) {
        return [];
      }
      this.customers.forEach((element) => {
        element.Name = element.Name + " (" + element.Code + ")";
      });
      return this.customers;
    },
    getCurrencySign() {
      let currencySign;
      switch (this.currency) {
        case "EUR":
          currencySign = "€ ";
          break;
        case "GBP":
          currencySign = "£ ";
          break;
        case "USD":
          currencySign = "$ ";
          break;

        default:
          currencySign = "€ ";
          break;
      }
      return currencySign;
    },
    getShoppingCartProductsSortedByNr() {
      let sortedShoppingCartItems;
      if (this.shoppingcart.length == 0) {
        return [];
      }
      sortedShoppingCartItems = this.shoppingcart;
      sortedShoppingCartItems.sort((a, b) => a.Code.localeCompare(b.Code));

      return sortedShoppingCartItems;
    },
    getTotal() {
      if (!this.currency && !this.totalPrice) {
        return this.getCurrencySign + 0;
      }
      return this.getCurrencySign + this.totalPrice;
    },
    priceVAT() {
      if (!this.currency && !this.vatPrice) {
        return this.getCurrencySign + 0;
      }
      return this.getCurrencySign + this.vatPrice;
    },
    priceExclVAT() {
      if (!this.currency && !this.totlaNetPrices) {
        return this.getCurrencySign + 0;
      }
      if (!this.currency) {
        return this.getCurrencySign + 0;
      }
      return this.getCurrencySign + this.cartItemPrice(this.totlaNetPrices);
    },
    getUserToken() {
      return auth.userToken();
    },
  },
  methods: {
    dragStart(ev) {
      ev.dataTransfer.dropEffect = "move";
    },
    dragOver(ev) {
      ev.preventDefault();
    },
    drop(ev) {
      ev.preventDefault();
      this.x = ev.layerX;
      this.y = ev.layerY;
    },
    updateTotalPrice() {
      this.isLoadingItems = true;

      let urlFilter =
        this.showDropdown && this.customer
          ? "?customerId=" + this.customer.Id
          : "";
      request.get("/api/kdesign/baksetoverview" + urlFilter, null, (res) => {
        if (res.Products.length == 0) {
          this.noProductsInBasked = true;
          this.isLoadingItems = false;
          return;
        }
        if (res.success) {
          this.totlaNetPrices = res.TotalNetPrices;

          if (this.isVatLaible) {
            this.getVatAndTotalPrice(this.totlaNetPrices);
          } else {
            this.getTotalPrice(this.totlaNetPrices);
          }
          for (const [key] of Object.entries(this.$refs)) {
            if (this.$refs[key][0]) {
              this.$refs[key][0].changeCurrency(this.currency);
            }
          }

          this.isLoadingItems = false;
        } else {
          this.isLoadingItems = false;
        }
      });
    },
    changeCustomer() {
      if (
        !this.customerId &&
        this.customer.Id &&
        this.showDropdown &&
        this.basketId
      ) {
        let urlFilter = this.basketId ? "&basketId=" + this.basketId : "";
        request.post(
          "/api/kdesign/basket-update-customer?customerId=" +
            this.customer.Id +
            urlFilter,
          null,
          () => {
            this.getBasketOverview();
          }
        );
      } else {
        this.getBasketOverview();
      }
    },
    getBasketOverview() {
      this.isLoadingItems = true;
      let urlFilter =
        this.showDropdown && this.customer
          ? "?customerId=" + this.customer.Id
          : "";
      request.get("/api/kdesign/baksetoverview" + urlFilter, null, (res) => {
        if (res.success) {
          this.$router.replace({
            params: {
              customerId: this.customer == null ? null : this.customer.Id,
            },
          });

          if (res.Products.length == 0) {
            this.noProductsInBasked = true;
            this.isLoadingItems = false;
            this.totalPrice = 0;
            this.vatPrice = 0;
            this.totlaNetPrices = 0;
            return;
          }

          this.basketId = res.BasketId;
          this.customerId = res.CustomerId;
          this.shoppingcart = res.Products;
          this.totlaNetPrices = res.TotalNetPrices;
          if (!res.CustomerId) {
            request.get("/api/kdesign/account", null, (response) => {
              if (response.success) {
                this.currency = response.Currency;
                this.isVatLaible = response.VatLiable;
                if (response.VatLiable) {
                  this.getVatAndTotalPrice(this.totlaNetPrices);
                } else {
                  this.getTotalPrice(this.totlaNetPrices);
                }
                for (const [key] of Object.entries(this.$refs)) {
                  if (this.$refs[key][0]) {
                    this.$refs[key][0].changeCurrency(this.currency);
                  }
                }
              } else {
                this.currency = "EUR";
                this.getVatAndTotalPrice(this.totlaNetPrices);
                for (const [key] of Object.entries(this.$refs)) {
                  if (this.$refs[key][0]) {
                    this.$refs[key][0].changeCurrency(this.currency);
                  }
                }
              }
              this.getProducts(res);
            });
            this.noProductsInBasked = false;
          } else {
            request.get(
              "/api/kdesign/customer?customerId=" + res.CustomerId,
              null,
              (response) => {
                if (response.success) {
                  this.currency = response.Currency;
                  this.isVatLaible = response.VatLiable;
                  if (response.VatLiable) {
                    this.getVatAndTotalPrice(this.totlaNetPrices);
                  } else {
                    this.getTotalPrice(this.totlaNetPrices);
                  }
                  for (const [key] of Object.entries(this.$refs)) {
                    if (this.$refs[key][0]) {
                      this.$refs[key][0].changeCurrency(this.currency);
                    }
                  }
                }
                this.getProducts(res);
              }
            );
            this.noProductsInBasked = false;
          }
        } else {
          this.isLoadingItems = false;
          this.noProductsInBasked = true;
        }
      });
    },
    getProducts(res) {
      var productsLoaded = 0;
      res.Products.forEach((element) => {
        this.productPrices[element.Code] = [];
        element.Images.forEach((image) => {
          this.productImages[element.Code] = [];
          this.productImages[element.Code].push(
            this.apiUrl +
              "/images/products/" +
              image +
              "?token=" +
              this.getUserToken
          );
        });
        this.stockItems = [];
        request.get(
          "/api/kdesign/product?productId=" + element.Id,
          null,
          (productRes) => {
            productsLoaded += 1;

            if (productsLoaded == res.Products.length) {
              this.isLoadingItems = false;
            }
            this.stockItems.push({
              id: element.Id,
              Stock: productRes.Variants.find((el) => el.Code == el.Code).Stock,
            });
          }
        );
        element.Variants.forEach((el) => {
          this.productPrices[element.Code][el.Code] = [];
          el.CommercialImages.forEach((commercialImage) => {
            this.productImages[element.Code].push(
              this.apiUrl +
                "/images/products/commercial-images/" +
                commercialImage +
                "?token=" +
                this.getUserToken
            );
          });
          el.Reserved.forEach((variant) => {
            this.productPrices[element.Code][el.Code].push({
              Netprices: variant.NetPrices,
              Size: variant.Size,
            });
          });
        });
      });
    },
    getCustomers() {
      if (this.showDropdown) {
        request.get("/api/kdesign/customers", null, (res) => {
          if (res.success) {
            //OrderInput
            let allCustomer = res;
            allCustomer.forEach((element) => {
              if (this.$route.params.customerId) {
                if (element.Id == this.$route.params.customerId) {
                  this.customer = element;
                }
              }
              if (element.OrderInput) {
                this.customers.push(element);
              }
            });
            this.customers.sort((a, b) => a.Name.localeCompare(b.Name));
          }
          this.getBasketOverview();
        });
        return;
      } else {
        this.getBasketOverview();
        return;
      }
    },
    goToShipping() {
      let customerIdRoute = this.$route.params.customerId;
      if (this.getBaskedCustomerId) {
        customerIdRoute = this.getBaskedCustomerId;
      }
      this.$router.push({
        name: "shipping",
        params: {
          id: this.$route.params.id,
          customerId: customerIdRoute,
        },
      });
      this.$router.go(1);
    },
    cartItemPrice(item) {
      if (!item) {
        return 0;
      }
      if (item.length == 1) {
        return item[0].Price;
      }
      if (item && this.currency) {
        return item.find((el) => el.Code == this.currency)
          ? item.find((el) => el.Code == this.currency).Price
          : 0;
      }
    },
    getVatAndTotalPrice(params) {
      this.vatPrice =
        Math.round((this.cartItemPrice(params) / 100) * this.VAT * 100) / 100;
      this.totalPrice =
        Math.round((this.vatPrice + this.cartItemPrice(params)) * 100) / 100;
    },
    getTotalPrice(prices) {
      if (!this.currency) {
        return;
      }
      this.totalPrice = Math.round(this.cartItemPrice(prices) * 100) / 100;
    },
  },
};
</script>

<style>
.max-width {
  max-width: 15rem;
}
.sticky-right {
  width: 18rem !important;
  position: fixed;
  right: 2rem !important;
  bottom: 4rem !important;
}
.panel-custom {
  position: absolute;
  width: 18rem !important;
}
.cursor {
  cursor: pointer;
}
</style>