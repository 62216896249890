import Vue from 'vue'
import Vuex from 'vuex'
import websites from './websites'
import errors from './errors'
import products from './products'


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    websites,
    errors,
    products
  }
})
