import Vue from 'vue'
import VueRouter from 'vue-router'
import auth from './auth'
import request from './request'
import store from "./store"
import vuexI18n from 'vuex-i18n';
import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css';
import VueSignaturePad from 'vue-signature-pad';

//vuetify
import Vuetify from 'vuetify/lib';
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

//shared components
import addButton from './components/shared/AddButton'
import addRemovePageElement from './components/shared/AddRemovePageElement'
import backButton from './components/shared/BackButton'
import cancelConfirmButtons from './components/shared/CancelConfirmButtons'
import ConfigLanguageSelector from './components/shared/ConfigLanguageSelector'
import datepicker from './components/shared/DatePicker'
import filterComponent from './components/shared/LeftFilter'
import languageSelector from './components/shared/LanguageSelector'
import pageElement from './components/shared/PageElement'
import pageNameLanguageSelector from './components/shared/PageNameLanguageSelector'
import preloader from './components/shared/Preloader'
import productFilter from './components/shared/Filter'
import productsOverview from './components/shared/ProductsOverview'
import OrderTable from './components/shared/OrderTable'
import AccountDetail from './components/shared/AccountDetail'
import timepicker from './components/shared/TimePicker'
import OrderList from './components/shared/OrderList'
import OrderListItem from './components/shared/OrderListItem'
import PlaceOrder from './components/shared/PlaceOrder'
import TopImage from './components/shared/TopImage'
import CounterItem from './components/shared/CounterItem'
import HeaderPrice from './components/shared/HeaderPrice'
import DragAndDrop from './components/shared/DragAndDrop'


//page components
import EditPage from './components/page/EditPage'
import EditPageItem from './components/page/EditPageItem'
import EditPageDetails from './components/page/EditPageDetails'
import DeletePageItem from './components/page/DeletePageItem'
import NotFoundPage from './components/page/NotFoundPage'
import EmailButtons from './components/shared/EmailButtons'

//login components
import ActivateAccount from './components/login/ActivateAccount'
import ForgotPassword from './components/login/ForgotPassword'
import Login from './components/login/Login'
import ResetPassword from './components/login/ResetPassword'

//website components
import CreateEditWebsite from './components/website/CreateEditWebsite'
import DeleteWebsite from './components/website/DeleteWebsite'
import ManageWebsites from './components/website/ManageWebsites'
import Settings from './components/website/Settings'
import Translations from './components/website/Translations'

//user components
import CreateEditUser from './components/user/CreateEditUser'
import DeleteUser from './components/user/DeleteUser'
import ManageUsers from './components/user/ManageUsers'

//error components
import ErrorHandeling from './components/error/ErrorHandeling'

//onboarding components
import OnboardingCards from './components/onboarding/OnboardingCards'

//integrations
import MailchimpIntegration from './components/integrations/MailchimpIntegration'

//other components
import App from './components/App'
import Dashboard from './components/Dashboard'
import RouteNotAuthorized from './components/RouteNotAuthorized'
import NewClient from './components/custom/NewClient'
import ClientDetails from './components/custom/ClientDetail'
import ShoppingCart from './components/custom/Cart'
//import Register from './components/register/Register'
import ProductDetail from './components/custom/ProductDetail'
import Shipping from './components/custom/Shipping'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'




//vue components
Vue.component('addButton', addButton)
Vue.component('addRemovePageElement', addRemovePageElement)
Vue.component('backButton', backButton)
Vue.component('cancelConfirmButtons', cancelConfirmButtons)
Vue.component('languageSelector', languageSelector)
Vue.component('pageElement', pageElement)
Vue.component('pageNameLanguageSelector', pageNameLanguageSelector)
Vue.component('preloader', preloader)
Vue.component('productFilter', productFilter)
Vue.component('productsOverview', productsOverview)
Vue.component('login', Login)
Vue.component('newclient', NewClient)
Vue.component('shoppingcart', ShoppingCart)
Vue.component('orderList', OrderList)
Vue.component('orderListItem', OrderListItem)
Vue.component('orderTable', OrderTable)
Vue.component('accountDetail', AccountDetail)
Vue.component('place-order', PlaceOrder)
Vue.component('topImage', TopImage)
Vue.component('counterItem', CounterItem)
Vue.component('headerPrice', HeaderPrice)
Vue.component('email-buttons', EmailButtons)
Vue.component('date-picker', datepicker)
Vue.component('time-picker', timepicker)
Vue.component('filterComponent', filterComponent)
Vue.component('configLanguageSelector', ConfigLanguageSelector)
Vue.component('errorHandeling', ErrorHandeling)
Vue.component('PrismEditor', PrismEditor);
Vue.component('dragAndDrop', DragAndDrop);
Vue.component('onboarding-cards', OnboardingCards);
Vue.component('mailchimp-integration', MailchimpIntegration);






Vue.use(VueRouter)
Vue.use(vuexI18n.plugin, store)
Vue.use(CoolLightBox)
Vue.use(Vuetify);
Vue.use(VueSignaturePad);


const router = new VueRouter({
  mode: 'history',
  base: __dirname,
  routes: [
    { path: '/' },
    { path: '/routenotauthorized', component: RouteNotAuthorized, name: 'routeNotAuthorized', meta: { adminRoute: false } },
    { path: '/dashboard', component: Dashboard, name: 'dashboard', meta: { adminRoute: false } },
    { path: '/websitesoverview', component: ManageWebsites, name: 'websites', meta: { adminRoute: true } },
    { path: '/translations', component: Translations, name: 'translations', meta: { adminRoute: true } },
    { path: '/login', component: Login, name: "login", meta: { adminRoute: false } },
    { path: '/websitesoverview/deletewebsite/:id', component: DeleteWebsite, name: 'deleteWebsite', meta: { adminRoute: true } },
    { path: '/websitesoverview/createeditwebsite', component: CreateEditWebsite, name: 'createNewWebsite', meta: { adminRoute: true } },
    { path: '/websitesoverview/createeditwebsite/:id', component: CreateEditWebsite, name: 'createEditWebsite', meta: { adminRoute: true } },
    { path: '/usersoverview', component: ManageUsers, name: 'manageUsers', meta: { adminRoute: true } },
    { path: '/usersoverview/deleteuser/:id', component: DeleteUser, name: 'deleteUser', meta: { adminRoute: true } },
    { path: '/usersoverview/createedituser', component: CreateEditUser, name: 'createNewUser', meta: { adminRoute: true } },
    { path: '/usersoverview/createedituser/:id', component: CreateEditUser, name: 'createEditUser', meta: { adminRoute: true } },
    { path: '/newclient', component: NewClient, name: 'newClient', meta: { adminRoute: false } },
    { path: '/page/editpagedetails/:name/:websiteId/:id', component: EditPageDetails, name: 'editPageDetails', meta: { adminRoute: true } },
    { path: '/page/editpage/:id', component: EditPage, name: 'editPage', meta: { adminRoute: false } },
    { path: '/page/editpage/:id/addedititem/', component: EditPageItem, name: 'editPageAddItem', meta: { adminRoute: false } },
    { path: '/page/editpage/:id/newcopy/:itemId', component: EditPageItem, name: 'editPageAddCopyItem', meta: { adminRoute: false } },
    { path: '/page/editpage/:id/addedititem/:itemId', component: EditPageItem, name: 'editPageAddEditItem', meta: { adminRoute: false } },
    { path: '/page/editpage/:id/deletepageitem/:itemId', component: DeletePageItem, name: 'deletePageItem', meta: { adminRoute: false } },
    { path: '/page/editpage/:id/productdetail/:productId', component: ProductDetail, name: 'productDetail', meta: { adminRoute: false } },
    { path: '/page/editpage/:id/clientdetails/:clientId', component: ClientDetails, name: 'clientDetails', meta: { adminRoute: false } },
    { path: '/page/editpage/:id/cart/:customerId?', component: ShoppingCart, name: 'cart', meta: { adminRoute: false } },
    { path: '/page/editpage/:id/shipping/:customerId?', component: Shipping, name: 'shipping', meta: { adminRoute: false } },
    { path: '/forgotpassword', component: ForgotPassword, name: 'forgotPassword', meta: { adminRoute: false } },
    { path: '/resetpassword/:hash', component: ResetPassword, name: 'resetPassword', meta: { adminRoute: false } },
    { path: '/activateaccount/:hash', component: ActivateAccount, name: 'activateAccount', meta: { adminRoute: false } },
    { path: '/settings', component: Settings, name: 'settings', meta: { adminRoute: true } },
    { path: '/pagenotfound', component: NotFoundPage, name: 'pagenotfound', meta: { adminRoute: false } },
    { path: '*', component: NotFoundPage, name: 'notFoundPage', meta: { adminRoute: false } },
    //{ path: '/register', component: Register, name: "register", meta: { adminRoute: false }},
  ]
})


const requests = [
  fetch(new Request("/config.json")),
  fetch(new Request("/translationsNl.json")),
  fetch(new Request("/translationsFr.json")),
  fetch(new Request("/translationsEn.json"))
]

Promise.all(requests).then(allResponses => {
  if (allResponses[0]) {
    allResponses[0].json()
      .then((config) => {
        Vue.prototype.$config = config
        const vuetify = new Vuetify({
          theme: {
            themes: {
              light: {
                primary: config.primaryColor,
                secondary: config.secondaryColor,
                background: config.backgroundColor
              },
            },
            options: {
              customProperties: true,
            },

          },
          icons: {
            iconfont: 'mdi',
          }
        });
        request.get('/validatesession', {}, (res) => {
          if (res.success) {
            auth.setUser(res.data)
          } else {
            auth.deleteUser()
          }


          router.beforeEach((to, from, next) => {
            //redirect user to login if user is not logged in
            if (!auth.loggedIn()
              && to.path !== '/login'
              && to.path !== '/forgotpassword'
              && to.path.indexOf("/resetpassword/") == -1
              && to.path.indexOf("/activateaccount/") == -1) {
              next('/login')
            }
            //redirect to dashboard if user is loggedIn
            else if (auth.loggedIn() && to.path == '/login') {
              next('/dashboard')
            }
            //notauthorized
            else if (to.meta.adminRoute && !auth.userIsAdministrator()) {
              next('/routenotauthorized')
            }
            //redirect user after login to "homepage" or dashboard
            else if (to.fullPath === "/") {
              request.get("/websites", null, (res) => {
                if (res.success) {
                  (res.data[0].DashboardPageId.$oid !== "000000000000000000000000") ?
                    next(`/page/editpage/${res.data[0].DashboardPageId.$oid}`) :
                    next('/dashboard')
                } else {
                  next('/dashboard')
                }
              })
            }
            //needs next to go to the next page if previus cases dont work
            else {
              next()
            }
          });
          Vue.config.errorHandler = function (err, vm, info) {
            const error = {
              err: err,
              vm: vm,
              info: info,
            }
            store.dispatch("setErrorMain", error);

            // handle error
            // `info` is a Vue-specific error info, e.g. which lifecycle hook
            // the error was found in. Only available in 2.2.0+

          }
          Vue.config.warnHandler = function (msg, vm, trace) {
            const error = {
              err: msg,
              vm: vm,
              info: trace,
            }
            store.dispatch("setErrorMain", error);

            // `trace` is the component hierarchy trace
          }
          new Vue({
            el: '#app',
            router,
            vuetify,
            store,

            render: h => h(App),
          })
        })
        /* }
        else {
          alert("Geen config file!");
        } */
      })
  }
  if (allResponses[1]) {
    allResponses[1].json()
      .then((langNl) => {

        Vue.i18n.add('Nl', langNl);
      })
  }
  if (allResponses[2]) {
    allResponses[2].json()
      .then((langFr) => {

        Vue.i18n.add('Fr', langFr);
      })
  }
  if (allResponses[3]) {
    allResponses[3].json()
      .then((langEn) => {

        Vue.i18n.add('En', langEn);
      })
  }
  if (allResponses[1] ||
    allResponses[2] ||
    allResponses[3]) {

    try {
      const currentLanguage = (navigator.language || navigator.userLanguage).split("-")[0]

      if (auth.userLanguage() != "") {
        Vue.i18n.set(auth.userLanguage())
      } else if (currentLanguage == "Fr") {
        Vue.i18n.set('Fr')
      }
      else if (currentLanguage == "En") {
        Vue.i18n.set('En')
      }
      else {
        Vue.i18n.set('Nl')
      }
    }
    catch (err) {
      Vue.i18n.set('Nl')
    }
  }
})





