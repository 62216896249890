<template>
  <div>
    <v-row no-gutters
      ><v-col>
        <v-icon @click="$router.go(-1)"> mdi-arrow-left </v-icon>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col class="mt-4 ml-2">
        <h2>{{ $t(title) }}</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3" md="3">
        <v-combobox
          :items="customerTemplates"
          v-model="customerTemplate"
          item-text="Description"
          item-value="Id"
          hide-details="auto"
          :placeholder="$t('Select a template')"
          clearable
          @change="setItemsInTemplate()"
        >
        </v-combobox>
      </v-col>
      <v-col cols="12" md="12">
        <v-card>
          <v-card-title>{{ $t("General") }}</v-card-title>
          <v-card-text>
            <v-form ref="formGeneral" v-model="generalValid" lazy-validation>
              <v-row class="mt-0">
                <v-col cols="12" lg="5" md="12">
                  <v-row no-gutters>
                    <v-col cols="6">
                      <v-text-field
                        :rules="rules.notEmptyStringRule"
                        v-model="clientData.Name"
                        :label="$t('Name')"
                      >
                      </v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="5">
                      <v-select
                        v-model="clientData.Language"
                        :disabled="getLangDisableCheck"
                        :rules="rules.notEmptyUpperLowerAlfabeticRule"
                        :items="langs"
                        :label="$t('Language')"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="clientData.CommercialName"
                        :label="$t('Commercial Name')"
                      >
                      </v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="5">
                      <v-layout>
                        <v-text-field
                          v-model="clientData.Vat"
                          :label="$t('BTW-number')"
                          :rules="getRule"
                        >
                        </v-text-field>

                        <v-tooltip right v-if="clientData.Country == 'BE'">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="ml-2"
                              color="primary"
                              dark
                              v-bind="attrs"
                              v-on="on"
                            >
                              mdi-help-circle
                            </v-icon>
                          </template>
                          <span>0123456789</span>
                        </v-tooltip>
                        <v-tooltip
                          right
                          v-else-if="customerTemplate || clientData.Country"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="ml-2"
                              color="primary"
                              dark
                              v-bind="attrs"
                              v-on="on"
                            >
                              mdi-help-circle
                            </v-icon>
                          </template>
                          <span>{{
                            $t("VAT number needs to start with") +
                            " " +
                            getCounteryCode
                          }}</span>
                        </v-tooltip>
                      </v-layout>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="clientData.Phone"
                        :label="$t('Telephone')"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="12" lg="6" md="12" class="pa-lg-0 pr-lg-4">
                  <v-textarea
                    :label="$t('Opening hours')"
                    :placeholder="$t('Opening hours example')"
                    rows="6"
                    id="opening-hours-input"
                    v-model="clientData.OpeningHours"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        md="4"
        v-for="(contactPersons, index) in clientData.Contacts"
        :key="index"
      >
        <v-card>
          <v-app-bar flat color="rgba(0, 0, 0, 0)">
            <v-toolbar-title class="text-h6 pl-0">
              {{ $t("Contact") }}
            </v-toolbar-title>
            <v-spacer></v-spacer>

            <v-btn v-if="index == 0" icon @click="addContactPerson()">
              <v-icon color="green">mdi-plus</v-icon>
            </v-btn>
            <v-btn v-else icon @click="removeContactPerson(index)">
              <v-icon color="error">mdi-delete</v-icon>
            </v-btn>
          </v-app-bar>

          <v-card-text>
            <v-form
              ref="formContactPerson"
              v-model="contactPersonValid"
              lazy-validation
            >
              <v-row class="mt-0">
                <v-col
                  cols="12"
                  xs="12"
                  sm="6"
                  md="6"
                  lg="6"
                  class="pa-lg-0 pr-lg-4"
                >
                  <v-text-field
                    :rules="rules.notEmptyEmailRule"
                    v-model="clientData.Contacts[index].Email"
                    :label="$t('Email')"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  xs="12"
                  sm="3"
                  md="3"
                  lg="3"
                  class="pa-lg-0 pl-lg-4 pr-lg-4"
                >
                  <v-select
                    required
                    v-model="clientData.Contacts[index].Type"
                    :rules="rules.notEmptyUpperLowerAlfabeticRule"
                    :items="contactTypes"
                    :label="$t('Type')"
                  >
                  </v-select>
                </v-col>
                <v-col
                  cols="12"
                  xs="12"
                  sm="3"
                  md="3"
                  lg="3"
                  class="pa-lg-0 pl-lg-4 pr-lg-4"
                >
                  <v-select
                    required
                    v-model="clientData.Contacts[index].Usage"
                    :rules="rules.notEmptyUpperLowerAlfabeticRule"
                    :items="emailTypes"
                    item-text="Translation"
                    item-value="Name"
                    :label="$t('Usage')"
                  >
                  </v-select>
                </v-col>
                <v-col
                  cols="12"
                  xs="12"
                  sm="12"
                  md="12"
                  lg="12"
                  class="pa-lg-0 pr-lg-4"
                >
                  <v-text-field
                    :rules="rules.notEmptyUpperLowerAlfabeticNumericRule"
                    v-model="clientData.Contacts[index].Description"
                    :label="$t('Description')"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>{{ $t("Billing address") }}</v-card-title>
          <v-card-text>
            <v-form ref="formInvoice" v-model="InvioceValid" lazy-validation>
              <v-row>
                <v-col cols="12" class="pa-0">
                  <v-row>
                    <v-col
                      cols="12"
                      xs="12"
                      sm="12"
                      md="4"
                      lg="4"
                      class="pa-lg-0 pr-lg-4"
                    >
                      <v-text-field
                        :rules="
                          getRules('notEmptyUpperLowerAlfabeticNumericRule')
                        "
                        v-model="clientData['Address']"
                        :label="$t('Street and number')"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      xs="12"
                      sm="12"
                      md="4"
                      lg="4"
                      class="pa-lg-0 pr-lg-4 pl-lg-4"
                    >
                      <v-text-field
                        v-model="clientData['Address2']"
                        :label="$t('Address2')"
                      >
                      </v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      xs="12"
                      sm="12"
                      md="3"
                      lg="3"
                      class="pa-lg-0 pl-lg-4 pr-lg-4"
                    >
                      <v-text-field
                        :rules="getRules('notEmptyUpperLowerAlfabeticRule')"
                        v-model="clientData.City"
                        :label="$t('City')"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      xs="12"
                      sm="12"
                      md="2"
                      lg="2"
                      class="pa-lg-0 pr-lg-4"
                    >
                      <v-text-field
                        :rules="getRules('notEmptyStringRule')"
                        v-model="clientData.Zip"
                        :label="$t('Postal Code')"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      xs="12"
                      sm="12"
                      md="3"
                      lg="3"
                      class="pa-lg-0 pl-lg-4"
                    >
                      <v-select
                        :rules="getRules('notEmptyStringRule')"
                        :disabled="getClientCountryDisableCheck"
                        v-model="clientData.Country"
                        :items="lands"
                        :item-text="userLang"
                        item-value="Code"
                        :label="$t('Country')"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-title>{{ $t("Addresses") }}</v-card-title>

          <v-card-text>
            <v-form ref="form" v-model="deliveryAdresValid" lazy-validation>
              <v-row no-gutters>
                <v-col cols="12" xs="12" sm="12" md="11" lg="11">
                  <v-row>
                    <v-col
                      cols="12"
                      xs="12"
                      sm="12"
                      md="4"
                      lg="4"
                      class="pa-lg-0 pr-lg-4"
                    >
                      <v-text-field
                        :rules="rules.notEmptyUpperLowerAlfabeticNumericRule"
                        v-model="newClientAddress.newName"
                        :label="$t('Name')"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      xs="12"
                      sm="12"
                      md="4"
                      lg="4"
                      class="pa-lg-0 pl-lg-4 pr-lg-4"
                    >
                      <v-text-field
                        :rules="rules.notEmptyUpperLowerAlfabeticNumericRule"
                        v-model="newClientAddress.newAdres"
                        :label="$t('Street and number')"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      xs="12"
                      sm="12"
                      md="4"
                      lg="4"
                      class="pa-lg-0 pl-lg-4 pr-lg-4"
                    >
                      <v-text-field
                        v-model="newClientAddress.newAddress2"
                        :label="$t('Address2')"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      xs="12"
                      sm="12"
                      md="3"
                      lg="3"
                      class="pa-lg-0 pr-lg-4"
                    >
                      <v-text-field
                        :rules="rules.notEmptyUpperLowerAlfabeticRule"
                        v-model="newClientAddress.newPlace"
                        :label="$t('City')"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      xs="12"
                      sm="12"
                      md="2"
                      lg="2"
                      class="pa-lg-0 pr-lg-4 pl-lg-4"
                    >
                      <v-text-field
                        :rules="rules.notEmptyStringRule"
                        v-model="newClientAddress.newPostalcode"
                        :label="$t('Postal Code')"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      xs="12"
                      sm="12"
                      md="3"
                      lg="3"
                      class="pa-lg-0 pl-lg-4 pr-lg-4"
                    >
                      <v-select
                        :disabled="getNewCLientDisableCheck"
                        :rules="rules.notEmptyStringRule"
                        :items="lands"
                        v-model="newClientAddress.newCountry"
                        :item-text="userLang"
                        item-value="Code"
                        :label="$t('Country')"
                      >
                      </v-select>
                    </v-col>
                    <v-col
                      cols="12"
                      xs="12"
                      sm="12"
                      md="4"
                      lg="4"
                      class="pa-lg-0 pr-lg-4 pl-lg-4"
                    >
                      <!--  <v-select
                        :rules="rules.notEmptyStringRule"
                        :items="Stores"
                        v-model="newPoint"
                        item-text="name"
                        item-value="id"
                        :label="$t('Type')"
                        
                      >
                      </v-select> -->
                      <!-- <v-radio-group v-model="newClientAddress.newPoint" row>
                        <v-radio label="Leveringsadres" :value="true"></v-radio>
                        <v-radio label="Verkooppunt" :value="false"></v-radio>
                      </v-radio-group> -->
                      <v-layout>
                        <v-checkbox
                          v-model="newClientAddress.newPoint"
                          label="Leveringsadres"
                          hide-details="auto"
                        ></v-checkbox>
                        <v-checkbox
                          v-model="newClientAddress.newSalesPoint"
                          label="Verkooppunt"
                          class="ml-4"
                          hide-details="auto"
                        ></v-checkbox>
                      </v-layout>
                    </v-col>
                    <v-col
                      cols="12"
                      xs="12"
                      sm="12"
                      md="5"
                      lg="5"
                      class="pa-lg-0 pr-lg-4"
                    >
                      <v-textarea
                        v-model="newClientAddress.newOpeningHours"
                        :label="$t('OpeningHours')"
                        rows="4"
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="1" lg="1">
                  <v-layout align-center fill-height justify-end>
                    <v-btn @click="addItem()" icon>
                      <v-icon color="success"> mdi-plus </v-icon>
                    </v-btn>
                  </v-layout>
                </v-col>
              </v-row>
            </v-form>
            <div v-if="getClientDataAddressesLength > 0">
              <v-row
                v-for="(adresfac, index) in clientData.Addresses"
                :key="index"
                class="mt-0"
              >
                <v-col cols="12" class="px-lg-0">
                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-divider></v-divider>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  class="px-lg-0"
                  xs="12"
                  sm="12"
                  md="11"
                  lg="11"
                >
                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      xs="12"
                      sm="12"
                      md="4"
                      lg="4"
                      class="pa-lg-0 pr-lg-4"
                    >
                      <v-text-field
                        readonly
                        v-model="adresfac['Name']"
                        :label="$t('Name')"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      xs="12"
                      sm="12"
                      md="4"
                      lg="4"
                      class="pa-lg-0 pr-lg-4 pl-lg-4"
                    >
                      <v-text-field
                        readonly
                        v-model="adresfac['Address']"
                        :label="$t('Street and number')"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      xs="12"
                      sm="12"
                      md="4"
                      lg="4"
                      class="pa-lg-0 pr-lg-4 pl-lg-4"
                    >
                      <v-text-field
                        readonly
                        v-model="adresfac['Address2']"
                        :label="$t('Address2')"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      xs="12"
                      sm="12"
                      md="3"
                      lg="3"
                      class="pa-lg-0 pr-lg-4"
                    >
                      <v-text-field
                        readonly
                        v-model="adresfac['City']"
                        :label="$t('City')"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      xs="12"
                      sm="12"
                      md="2"
                      lg="2"
                      class="pa-lg-0 pr-lg-4 pl-lg-4"
                    >
                      <v-text-field
                        readonly
                        v-model="adresfac['Zip']"
                        :label="$t('Postal Code')"
                      >
                      </v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      xs="12"
                      sm="12"
                      md="3"
                      lg="3"
                      class="pa-lg-0 pl-lg-4 pr-lg-4"
                    >
                      <v-text-field
                        readonly
                        v-model="adresfac['Country']"
                        :label="$t('Country')"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      xs="12"
                      sm="12"
                      md="4"
                      lg="4"
                      class="pa-lg-0 pl-lg-4 pr-lg-4"
                    >
                      <!--  <v-select
                        :rules="rules.notEmptyStringRule"
                        :items="Stores"
                        v-model="newPoint"
                        item-text="name"
                        item-value="id"
                        :label="$t('Type')"
                        
                      >
                      </v-select> -->
                      <v-radio-group
                        v-model="adresfac['IsDefaultShipmentAddress']"
                        readonly
                        row
                      >
                        <v-radio label="Leveringsadres" :value="true"></v-radio>
                        <v-radio label="Verkooppunt" :value="false"></v-radio>
                      </v-radio-group>
                      <v-checkbox
                        v-model="selected"
                        label="Leveringsadres"
                        value="John"
                      ></v-checkbox>
                      <v-checkbox
                        v-model="selected"
                        label="Verkooppunt"
                        value="Jacob"
                      ></v-checkbox>
                    </v-col>
                    <v-col
                      cols="12"
                      xs="12"
                      sm="12"
                      md="5"
                      lg="5"
                      class="pa-lg-0 pr-lg-4"
                    >
                      <v-textarea
                        readonly
                        v-model="adresfac['OpeningHours']"
                        :label="$t('OpeningHours')"
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="1" lg="1" class="px-lg-0">
                  <v-layout align-center fill-height justify-end>
                    <v-btn @click="deleteItem(index)" icon>
                      <v-icon color="error"> mdi-delete </v-icon>
                    </v-btn>
                  </v-layout>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" color="error" timeout="4000">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" v-bind="attrs" @click="snackbar = false" icon>
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-row>
      <v-col>
        <v-layout justify-end>
          <v-btn @click="goBack()" class="mr-4">
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn
            @click="addNewClient()"
            color="primary"
            :loading="isLoadingClient"
          >
            {{ $t("Save client") }}
          </v-btn>
        </v-layout>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Lands from "../../locale/Countrys.json";
import auth from "../../auth";
import validation from "../../validation";
import helpers from "../../helpers";
import request from "../../request";

export default {
  data() {
    return {
      title: null,
      snackbar: null,
      snackbarText: null,
      deliveryAdresValid: true,
      generalValid: true,
      contactPersonValid: true,
      InvioceValid: true,
      langs: ["Nl", "En", "Fr"],
      contactTypes: ["To", "CC", "BCC"],

      checkbox: false,
      place: null,
      postalcode: null,
      adres: null,
      country: null,
      lands: null,
      name: null,
      companyName: null,
      BTWNr: null,
      contactPerson: null,
      tel: null,
      mobileNr: null,
      email: null,
      lang: null,
      contactType: null,
      newClientAddress: {
        newPlace: null,
        newPostalcode: null,
        newAdres: null,
        newAddress2: null,
        newCountry: null,
        newName: null,
        newOpeningHours: null,
        newPoint: false,
        newSalesPoint: false,
      },
      userLang: auth.userLanguage(),
      rules: validation,
      amountOfContactPersons: [],
      Stores: [
        { id: 1, name: "Verkooppunt" },
        { id: 2, name: "Leveringsadres" },
      ],
      clientData: {
        Name: null,
        CommercialName: null,
        Zip: null,
        City: null,
        Country: null,
        Language: null,
        Phone: null,
        MobilePhone: null,
        Address: null,
        Address2: null,
        OpeningHours: null,
        Vat: null,
        Addresses: [],
        Contacts: [
          {
            Type: null,
            Email: null,
            Description: null,
            Usage: null,
          },
        ],
      },
      isLoadingClient: false,
      customerTemplates: [],
      customerTemplate: null,
      selected: [],
    };
  },
  created() {
    this.lands = Lands.allCountrys;
    this.title = this.$t("New customer");
    /* this.country = this.lands.find(
        (e) => e[this.clientData.Language] == this.clientData.Country
      ); */
    this.getAllCustomerTemplates();
  },
  computed: {
    getCounteryCode() {
      return this.clientData.Country
        ? this.clientData.Country
        : this.$t("countrycode");
    },
    getLangDisableCheck() {
      if (this.clientData.Language && this.customerTemplate) {
        return true;
      }
      return false;
    },
    getClientCountryDisableCheck() {
      if (this.clientData.Country && this.customerTemplate) {
        return true;
      }
      return false;
    },
    getNewCLientDisableCheck() {
      if (this.newClientAddress.newCountry && this.customerTemplate) {
        return true;
      }
      return false;
    },
    getClientDataAddressesLength() {
      return this.clientData.Addresses.length;
    },
    checkVatNumberBE() {
      const last2Str = String(this.clientData.Vat).slice(-2);
      const last2Num = Number(last2Str);
      const strWithoutLast2Numbers = this.clientData.Vat.substring(
        0,
        this.clientData.Vat.length - 2
      );
      const resultDivision = Number(strWithoutLast2Numbers) / 97;
      const resultMultiply = Number(Math.floor(resultDivision)) * 97;
      const resultSubtraction = strWithoutLast2Numbers - resultMultiply;
      const resultCheck = 97 - resultSubtraction;
      return resultCheck == last2Num;
    },
    emailTypes() {
      return [
        { Name: "All", Translation: this.$t("All") },
        { Name: "Invoice", Translation: this.$t("Invoice") },
        { Name: "Reminder", Translation: this.$t("Reminder") },
        { Name: "Sales Order", Translation: this.$t("Sales Order") },
      ];
    },
    getRule() {
      if (this.clientData.Country == "BE" && this.clientData.Vat) {
        return this.rules.VATcheck;
      }
      return [];
    },
  },
  methods: {
    getRules(rule) {
      return validation.getValidation(rule);
    },
    getAllCustomerTemplates() {
      request.get("/api/kdesign/customer-templates", null, (res) => {
        this.customerTemplates = res;
      });
    },
    deleteItem(index) {
      this.$delete(this.clientData.Addresses, index);
    },
    addItem() {
      if (this.$refs.form.validate()) {
        this.$set(this.clientData.Addresses, this.clientData.Addresses.length, {
          Name: this.newClientAddress.newName,
          City: this.newClientAddress.newPlace,
          Zip: this.newClientAddress.newPostalcode,
          Address: this.newClientAddress.newAdres,
          Address2: this.newClientAddress.newAddress2,
          Country: this.newClientAddress.newCountry,
          IsDefaultShipmentAddress: this.newClientAddress.newPoint,
          IsPointOfSales: this.newClientAddress.newSalesPoint,
          OpeningHours: this.newClientAddress.newOpeningHours,
        });
        this.newClientAddress.newName = null;
        this.newClientAddress.newPlace = null;
        this.newClientAddress.newPostalcode = null;
        this.newClientAddress.newAdres = null;
        this.newClientAddress.newAddress2 = null;
        this.newClientAddress.newCountry = null;
        this.newClientAddress.newPoint = false;
        this.newClientAddress.newSalesPoint = false;
        this.newClientAddress.newOpeningHours = null;
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    addNewClient() {
      //vallidate all
      this.$refs.formGeneral.validate();
      this.$refs.formInvoice.validate();
      if (this.clientData.Contacts[0].Email) {
        this.$refs.formContactPerson[0].validate();
      }
      if (this.clientData.Vat && this.clientData.Country == "BE") {
        if (!this.checkVatNumberBE) {
          this.snackbar = true;
          this.snackbarText = this.$t("No valid 'Belgian' VAT number");
          return;
        }
      }
      if (
        this.$refs.formGeneral.validate() &&
        this.$refs.formInvoice.validate()
      ) {
        this.isLoadingClient = true;
        let addresses = [
          {
            Name: this.newClientAddress.newName,
            City: this.newClientAddress.newPlace,
            Zip: this.newClientAddress.newPostalcode,
            Address: this.newClientAddress.newAdres,
            Address2: this.newClientAddress.newAddress2,
            Country: this.newClientAddress.newCountry,
            IsDefaultShipmentAddress: this.newClientAddress.newPoint,
            IsPointOfSales: this.newClientAddress.newSalesPoint,
            OpeningHours: this.newClientAddress.newOpeningHours,
          },
        ];
        if (
          !this.newClientAddress.newAdres &&
          !this.newClientAddress.newCountry &&
          !this.newClientAddress.newName &&
          !this.newClientAddress.newOpeningHours &&
          !this.newClientAddress.newPlace &&
          !this.newClientAddress.newPostalcode
        ) {
          addresses = [];
        }
        if (this.clientData.Addresses.length > 0) {
          addresses = addresses.concat(this.clientData.Addresses);
        }
        let contacts = [];
        if (this.clientData.Contacts[0].Email) {
          contacts = contacts.concat(this.clientData.Contacts);
        }
        request.post(
          "/api/kdesign/customer",
          {
            CustomerTemplate: this.customerTemplate
              ? this.customerTemplate.Code
              : "",
            BillToCode: this.customerTemplate
              ? this.customerTemplate.BillToCode
              : "",
            Name: this.clientData.Name,
            CommercialName: this.clientData.CommercialName,
            Zip: this.clientData.Zip,
            City: this.clientData.City,
            Country: this.clientData.Country,
            Language: this.clientData.Language,
            Phone: this.clientData.Phone,
            MobilePhone: this.clientData.MobilePhone,
            Address: this.clientData.Address,
            Address2: this.clientData.Address2,
            OpeningHours: this.clientData.OpeningHours,
            Vat: this.clientData.Vat,
            Addresses: addresses,
            Contacts: contacts,
            IsDefaultShipmentAddress: true,
            IsPointOfSales: true,
            Created: helpers.getDateWithTimezoneOffset(),
          },
          (res) => {
            this.isLoadingClient = false;
            if (res.success) {
              this.goBack();
            } else {
              if (!res.message || res.message == undefined) {
                this.snackbarText = this.$t(
                  "Unable to create a new customer. Please contact the system administrator."
                );
                this.snackbar = true;
                return;
              }
              this.snackbar = true;
              this.snackbarText = res.message;
            }
          }
        );
      }

      return;
    },
    addContactPerson() {
      this.clientData.Contacts.push({});
    },
    removeContactPerson(index) {
      this.$delete(this.clientData.Contacts, index);
    },
    setPointOfSales(item) {
      if (item === 1) {
        this.clientData.Addresses[0]["IsDefaultShipmentAddress"] = false;
        this.clientData.Addresses[0]["IsPointOfSales"] = true;
      } else {
        this.clientData.Addresses[0]["IsDefaultShipmentAddress"] = true;
        this.clientData.Addresses[0]["IsPointOfSales"] = false;
      }
    },
    setItemsInTemplate() {
      if (this.customerTemplate) {
        this.newClientAddress.newCountry = this.customerTemplate.Country;
        this.clientData.Country = this.customerTemplate.Country;
        this.clientData.Language = this.customerTemplate.Language;
        if (this.customerTemplate.Country != "BE") {
          this.clientData.Vat = this.customerTemplate.Country;
        } else {
          this.clientData.Vat = "";
        }
      }
    },
  },
};
</script>

<style>
#opening-hours-input {
  min-height: 172px;
}
</style>