<template>
  <div>
    <div v-if="!loading">
      <v-row no-gutters>
        <v-col cols="12">
          <v-icon id="back-button" class="mb-4" v-on:click="goBack"
            >mdi-arrow-left</v-icon
          >
        </v-col>
        <v-col>
          <h2>{{ $t("Overview") }}</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="products">
          <h3 class="mb-4">{{ $t("Products") }}</h3>
          <v-data-table
            :headers="headers"
            :items="getProductsGroupedByCode"
            hide-default-header
            hide-default-footer
            class="elevation-1 overflow-y-auto max-hight"
            :no-data-text="$t('No data available')"
            :loading="loadingProducts"
            disable-pagination
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-avatar class="my-4" color="white">
                <v-img
                  v-if="item.Image"
                  contain
                  :lazy-src="item.Image + '?token=' + getUserToken"
                  :src="item.Image + '?token=' + getUserToken"
                ></v-img>
              </v-avatar>
            </template>
            <template v-slot:[`item.Variants`]="{ item }">
              <v-layout justify-center column>
                <div v-for="(variant, index) in item.Variants" :key="index">
                  <v-avatar class="my-2 mr-2" color="white" size="30">
                    <v-img
                      v-if="variant.color"
                      :lazy-src="variant.color + '?token=' + getUserToken"
                      :src="variant.color + '?token=' + getUserToken"
                    ></v-img>
                  </v-avatar>
                  {{ variant.code }}
                </div>
              </v-layout>
              <!--  -->
            </template>
            <template v-slot:[`item.Amount`]="{ item }">
              <v-layout justify-center column>
                <div
                  v-for="(variant, index) in item.Variants"
                  :key="index"
                  class="my-3 mr-2"
                >
                  {{ variant.amount }}
                </div>
              </v-layout>
              <!--  -->
            </template>
            <template v-slot:[`item.Stock`]="{ item }">
              <v-layout justify-center column>
                <div
                  v-for="(variant, index) in item.Variants"
                  :key="index"
                  class="my-3 mr-2"
                >
                  <v-badge :color="variant.colorBadge"> </v-badge>
                </div>
              </v-layout>
              <!--  -->
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="5">
          <h3 class="mb-4">{{ $t("General info") }}</h3>
          <v-badge class="mb-4 mr-2 mt-1" color="success" inline>{{
            $t("Available immediately")
          }}</v-badge>
          <v-badge class="mb-4 mt-1" color="warning" inline>{{
            $t("Not immediately available")
          }}</v-badge>
          <v-row no-gutters v-if="showClientDropdown">
            <v-col cols="12">
              <h4 class="mb-1">{{ $t("Customer") }}</h4>
            </v-col>
            <v-col cols="7">
              <v-combobox
                clearable
                v-model="customer"
                :items="getCustomersNameAndCode"
                item-text="Name"
                item-value="Id"
                @input="changeCustomer()"
                @click:clear="customer = null"
              ></v-combobox>

              <!--  <div v-else class="mb-4" v-show="account">
                {{ customerName }}
              </div> -->
            </v-col>
          </v-row>
          <v-row no-gutters v-if="getDeliveryAddresses.length > 0">
            <v-col cols="12">
              <h4 class="mb-1 mt-4">{{ $t("Delivery address") }}</h4>
            </v-col>
            <v-col cols="7">
              <v-select
                :items="getDeliveryAddresses"
                item-value="code"
                item-text="address"
                v-model="deliveryAddres"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12">
              <h4 class="mb-1 mt-4 mb-4">{{ $t("Note") }}</h4>
            </v-col>
            <v-col cols="8">
              <v-textarea
                solo
                :label="$t('Note')"
                name=""
                id=""
                class="mb-4"
                hide-details
                v-model="comment"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12">
              <h4 class="mb-1 mt-4 mb-4">{{ $t("Reference") }}</h4>
            </v-col>
            <v-col cols="8">
              <v-textarea
                solo
                :label="$t('Reference')"
                rows="2"
                class="mb-4"
                hide-details
                v-model="reference"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12">
              <h4 class="mb-1 mt-4 mb-4">{{ $t("Signature") }}</h4>
            </v-col>
            <v-col cols="8">
              <div class="container-signature-pad">
                <VueSignaturePad height="200px" ref="signaturePad" />
              </div>
              <v-layout justify-end>
                <v-btn @click="undo" class="mt-2" outlined>
                  {{ $t("undo") }}
                </v-btn>
              </v-layout>
            </v-col>
          </v-row>
          <v-row v-if="!loadingProducts" no-gutters>
            <v-col cols="12">
              <h4 class="mb-1 mt-4">{{ $t("Total") }}</h4>
            </v-col>
            <v-col cols="8" class="mb-4">
              <v-row no-gutters class="pa-2">
                <v-col cols="6" class="mb-2">{{
                  $t("Number of products")
                }}</v-col>
                <v-col cols="6" class="mb-2 text-end">{{
                  amountOfProducts + " " + $t("Product(s)")
                }}</v-col>

                <v-row no-gutters v-if="totalPrice >= 6000">
                  <v-col cols="6" class="mb-2">{{ $t("Total excl.") }}</v-col>
                  <v-col cols="6" class="mb-2 text-end">{{
                    getcurrency + totalPrice
                  }}</v-col>
                  <v-col cols="6" class="mb-2">{{
                    "5% " + $t("discount on total excl.")
                  }}</v-col>
                  <v-col cols="6" class="mb-2 text-end">{{
                    getcurrency + priceWithDiscount
                  }}</v-col>
                </v-row>
                <v-col cols="12" class="mb-2" v-show="!isUserVatLiable">
                  <v-divider></v-divider>
                </v-col>
                <v-col
                  cols="6"
                  :class="[!isUserVatLiable ? 'font-weight-bold' : '', 'mb-2']"
                  >{{ getNamingPriceExcl }}</v-col
                >
                <v-col cols="6" class="mb-2 text-end">
                  {{ getcurrency + totalPriceWithOrWithoutDiscount }}
                </v-col>
                <v-col cols="12" class="mb-2" v-show="isUserVatLiable">
                  <v-divider></v-divider>
                </v-col>
                <v-col
                  cols="6"
                  class="mb-2 font-weight-bold"
                  v-show="isUserVatLiable"
                  >{{ getNamingPriceIncl }}</v-col
                >
                <v-col
                  cols="6"
                  class="mb-2 text-end"
                  v-show="isUserVatLiable"
                  >{{ getcurrency + totalPiceIncl }}</v-col
                >
              </v-row>
            </v-col>
          </v-row>

          <v-layout align-center>
            <v-checkbox v-model="checkbox"></v-checkbox>
            {{ $t("I agree to the general ") }}
            <a
              href="https://www.k-designfashion.com/privacy-policy"
              target="_blank"
              class="text-decoration-underline ml-2"
              >{{ $t("terms and conditions") }}</a
            >
          </v-layout>
          <v-row no-gutters>
            <v-col cols="8">
              <v-alert v-if="filterdItems" dense type="warning">{{
                $t(
                  "Attention: your order will be split into several orders because the articles belong to several seasons"
                )
              }}</v-alert>
            </v-col>
            <v-col cols="8">
              <v-alert
                v-if="notAvailebleArticles.length > 0"
                dense
                type="warning"
              >
                {{
                  $t(
                    "Please note: certain items that you wish to order have limited availability."
                  )
                }}

                <v-row
                  :class="index == 0 ? 'mt-4' : 'mt-2'"
                  no-gutters
                  v-for="(article, index) in notAvailebleArticles"
                  :key="index"
                >
                  <v-col>{{ article.productCode }}</v-col>
                  <v-col class="text-center">{{ article.code }}</v-col>
                  <v-col class="text-end">{{ article.size }}</v-col>

                  <v-col
                    cols="12"
                    v-if="index !== notAvailebleArticles.length - 1"
                  >
                    <v-divider></v-divider>
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>
            <v-col cols="8">
              <v-alert v-if="hasMultiSeasons" dense type="warning">
                <p>
                  {{
                    $t(
                      "Please note: certain items you wish to order are available in multiple seasons. Select the season for which you wish to order per item."
                    )
                  }}
                </p>
                <div>
                  <v-simple-table light>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">{{ $t("Article") }}</th>
                          <th class="text-left">{{ $t("Season") }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(seasonProduct, index) in productsWithSeasons"
                          :key="index"
                        >
                          <td>{{ seasonProduct.Code }}</td>
                          <td>
                            <v-select
                              :items="seasonProduct.Seasons"
                              dense
                              item-text="TranslatedCode"
                              item-value="Id"
                              hide-details="auto"
                              v-model="
                                selectedSeasonProducts[seasonProduct.Code]
                              "
                              return-object
                            >
                              <template v-slot:item="{ item }">
                                {{ translatedCode(item) }}
                              </template>
                            </v-select>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
              </v-alert>
            </v-col>
          </v-row>

          <v-btn
            @click="comfirmOrder()"
            :disabled="isDisabeld"
            :loading="isLoading"
          >
            {{ $t("Confirm") }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="500">
          <v-card>
            <v-card-title class="text-h5">
              {{ $t("Thanks for your order.") }}
            </v-card-title>
            <v-card-text>
              {{
                $t(
                  "As soon as we have received the order, we will send you an order confirmation. We'll send you back to 'Place order'"
                )
              }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="goTo">
                {{ $t("Back to place order") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-snackbar v-model="snackbar" :color="snackbarColor">
        {{ snackbarText }}

        <template v-slot:action="{ attrs }">
          <v-btn color="white" v-bind="attrs" @click="snackbar = false" icon>
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </div>
    <div v-else>
      <preloader />
    </div>
  </div>
</template>

<script>
import request from "../../request";
import Vue from "vue";
import axios from "axios";
import auth from "../../auth";

export default {
  data() {
    return {
      checkbox: null,
      products: [],
      isSalesAgent: null,
      customersNames: null,
      customers: null,
      customer: null,
      deliveryAddres: null,
      comment: null,
      reference: null,
      totalNetPrice: null,
      discount: 5,
      dialog: false,
      timeLeft: null,
      account: null,
      basketId: null,
      snackbar: false,
      snackbarText: null,
      snackbarColor: null,
      currency: null,
      custommerId: null,
      vat: 21,
      clickedConfirm: false,
      showClientDropdown: false,
      isLoading: false,
      seasonCodes: [],
      loadingProducts: false,
      customerId: null,
      notAvailebleArticles: [],
      loading: true,
      hasMultiSeasons: false,
      productsWithSeasons: [],
      selectedSeasonProducts: [],
    };
  },
  computed: {
    getcurrency() {
      let currencySign;
      switch (this.currency) {
        case "EUR":
          currencySign = "€ ";
          break;
        case "GBP":
          currencySign = "£ ";
          break;
        case "USD":
          currencySign = "$ ";
          break;

        default:
          currencySign = "€ ";
          break;
      }
      return currencySign;
    },
    headers() {
      return [
        {
          text: "Image",
          align: "start",
          sortable: false,
          value: "actions",
        },
        {
          text: this.$t("Code"),
          sortable: false,
          value: "Code",
        },

        {
          text: this.$t("VariantCode"),
          value: "Variants",
          sortable: false,
        },
        {
          text: this.$t("Amount"),
          value: "Amount",
          sortable: false,
        },
        {
          text: this.$t("Stock"),
          value: "Stock",
          sortable: false,
        },
      ];
    },
    isUserVatLiable() {
      if (!this.customer) {
        return false;
      }
      return this.customer.VatLiable;
    },
    amountOfProducts: {
      get: function () {
        return this.products.length;
      },
    },
    tomorrow() {
      let date = new Date();

      // add a day
      return new Date(
        date.setDate(date.getDate() + 1) -
          new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
    },
    isDisabeld() {
      let needsDisable = true;
      let signaturePad = false;
      let multiSeasonWariningPass = false;
      if (this.$refs.signaturePad) {
        signaturePad = this.$refs.signaturePad.saveSignature();
      }
      if (this.hasMultiSeasons) {
        const keys = Object.keys(this.selectedSeasonProducts);
        multiSeasonWariningPass =
          keys.length == this.productsWithSeasons.length;
      } else {
        multiSeasonWariningPass = true;
      }
      if (
        this.products.length > 0 &&
        this.customer &&
        this.checkbox &&
        this.customer.Id &&
        !signaturePad.isEmpty &&
        multiSeasonWariningPass
      ) {
        if (this.getDeliveryAddresses.length <= 1) {
          needsDisable = false;
        } else if (
          this.getDeliveryAddresses.length >= 1 &&
          this.deliveryAddres
        ) {
          needsDisable = false;
        }
      }

      return needsDisable;
    },
    currentLanguage: {
      get: function () {
        return Vue.i18n.locale();
      },
    },
    priceWithDiscount() {
      return (
        Math.round(
          (this.cartItemPrice(this.totalNetPrice) / 100) * this.discount * 100
        ) / 100
      );
    },
    discountPrice() {
      if (this.cartItemPrice(this.totalNetPrice) > 6000) {
        return (
          Math.round(
            (this.cartItemPrice(this.totalNetPrice) / 100) * this.discount * 100
          ) / 100
        );
      }
      return 0;
    },
    getNamingPriceExcl() {
      if (this.totalPrice >= 6000) {
        return this.$t("Total with discount");
      }
      return this.$t("Total");
    },
    getNamingPriceIncl() {
      if (this.totalPrice >= 6000) {
        return this.$t("Total with discount incl.");
      }
      return this.$t("Total incl.");
    },
    totalPrice() {
      return this.cartItemPrice(this.totalNetPrice);
    },
    totalPriceWithOrWithoutDiscount() {
      if (this.totalPrice >= 6000) {
        return this.cartItemPrice(this.totalNetPrice) - this.priceWithDiscount;
      }
      return this.cartItemPrice(this.totalNetPrice);
    },
    totalPiceIncl() {
      const vatPrice = (this.totalPriceWithOrWithoutDiscount / 100) * this.vat;
      const total = this.totalPriceWithOrWithoutDiscount + vatPrice;

      return Math.round(total * 100) / 100;
    },
    customerName() {
      return this.account ? this.account.Name : "";
    },
    filterdItems() {
      if ([...new Set(this.seasonCodes)].length >= 2) {
        return true;
      }

      return false;
    },
    getProductsGroupedByCode() {
      if (this.loadingProducts) {
        return [];
      }
      if (this.products.length == 0) {
        return [];
      }
      let products = [];
      this.products.forEach((product) => {
        if (product.SeasonCode) {
          this.seasonCodes.push(product.SeasonCode);
        }

        let variants = [];
        this.resetNotAvailebleArticles();
        product.Variants.forEach(async (variant) => {
          let amount = 0;
          let outOfDirectStock = true;
          variant.Reserved.forEach((reserved) => {
            amount += reserved.BasketReservedAmount;

            if (
              reserved.BasketReservedAmount + reserved.AvailableAmount !==
                reserved.ActualAmount &&
              reserved.AvailableAmount <= reserved.BasketReservedAmount &&
              reserved.ActualAmount !== 999999
            ) {
              this.notAvailebleArticles.push({
                productCode: product.Code,
                code: variant.Code,
                amount: reserved.ActualAmount,
                size: reserved.Size,
              });
            }
            if (
              reserved.BasketReservedAmount >
              reserved.AvailableDeliverableAmount
            ) {
              outOfDirectStock = false;
            }
          });
          variants.push({
            code: variant.Code,
            color: await this.getColorById(variant.ColorId),
            amount: "x " + amount,
            colorBadge: outOfDirectStock ? "success" : "warning",
          });
        });

        products.push({
          Code: product.Code,
          Image: product.Images[0]
            ? this.$config.apiUrl + "/images/products/" + product.Images[0]
            : null,
          Variants: variants,
        });
      });

      return products;
    },
    getCustomersNameAndCode() {
      if (!this.customers) {
        return [];
      }
      let showCustomers = [];
      this.customers.forEach((element) => {
        if (element.OrderInput) {
          element.Name = element.Name + " (" + element.Code + ")";
          showCustomers.push(element);
        }
      });
      return showCustomers;
    },
    getDeliveryAddresses() {
      let deliveryAddress = [];
      if (!this.customer) {
        return deliveryAddress;
      }
      this.customer.Addresses.filter(
        (e) => e.Code != "" && e.IsShipmentAddress
      ).forEach((el) => {
        deliveryAddress.push({
          code: el.Code,
          address: this.makeAddress(el.Name, el.Address, el.City, el.Zip),
        });
      });
      if (deliveryAddress.length > 0) {
        this.changeDeliveryAddres(deliveryAddress[0].code);
      }
      return deliveryAddress;
    },
    getUserToken() {
      return auth.userToken();
    },
  },
  created() {
    request.get("/api/kdesign/authenticateduser", null, (res) => {
      this.loading = false;
      if (res.success) {
        if (
          !auth.userIsAdministrator() &&
          res.Roles.indexOf("SalesAgent") == -1
        ) {
          if (res.Customer.OrderInput == false) {
            this.$router.go(-1);
          }
        }
        if (
          res.Roles.indexOf("SalesAgent") >= 0 ||
          res.Roles.indexOf("Management") >= 0
        ) {
          this.showClientDropdown = true;
          request.get("/api/kdesign/customers", null, (res) => {
            this.customers = res;
            this.customers.sort((a, b) => a.Name.localeCompare(b.Name));
            this.getProducts();
          });
          return;
        } else if (res.Roles.indexOf("B2B") >= 0 && res.Roles.length == 1) {
          this.showClientDropdown = false;

          if (
            res == null ||
            res == undefined ||
            res.Id == null ||
            res.Id == ""
          ) {
            this.snackbar = true;
            this.snackbarText = this.$t(
              "The order cannot be placed because no customer is linked to your account"
            );
            this.snackbarColor = "warning";
          } else {
            if (res.Customer["OrderInput"] == false) {
              this.$router.go(-1);
            }
            this.customer = res.Customer;
            this.getProducts();
          }
        }
      }
    });
  },
  watch: {
    currentLanguage: {
      handler: function () {
        this.changeTranslatedCodes();
      },
      deep: true,
    },
  },
  methods: {
    changeTranslatedCodes() {
      for (const key in this.selectedSeasonProducts) {
        this.selectedSeasonProducts[key]["TranslatedCode"] =
          this.translatedCode(this.selectedSeasonProducts[key]);
      }
    },
    translatedCode(item) {
      const season = item.Code.substring(0, item.Code.length - 1);

      if (item.Code.endsWith("W")) {
        if (item["TranslatedCode"]) {
          this.$set(
            item,
            "TranslatedCode",
            this.$t("Winter", { year: season })
          );
        } else {
          item["TranslatedCode"] = this.$t("Winter", { year: season });
        }
        return this.$t("Winter", { year: season });
      }
      if (item.Code.endsWith("S")) {
        if (item["TranslatedCode"]) {
          this.$set(
            item,
            "TranslatedCode",
            this.$t("Summer", { year: season })
          );
        } else {
          item["TranslatedCode"] = this.$t("Summer", { year: season });
        }
        return this.$t("Summer", { year: season });
      }
      return item.Code;
    },
    undo() {
      this.$refs.signaturePad.undoSignature();
    },

    resetNotAvailebleArticles() {
      this.notAvailebleArticles = [];
    },
    changeDeliveryAddres(code) {
      this.deliveryAddres = code;
    },
    changeCustomer() {
      this.hasMultiSeasons = false;

      if (!this.customer) {
        return;
      }
      if (this.showClientDropdown && this.customers) {
        this.deliveryAddres = [];

        this.customers.forEach((element) => {
          if (element.Id == this.customer.Id) {
            this.currency = element.Currency;
          }
        });
      } else {
        this.currency = this.customer.Currency;
      }
      if (!this.customerId && this.customer.Id && this.basketId) {
        let urlFilter = this.basketId ? "&basketId=" + this.basketId : "";
        request.post(
          "/api/kdesign/basket-update-customer?customerId=" +
            this.customer.Id +
            urlFilter,
          null,
          () => {
            this.getProducts();
          }
        );
      } else {
        this.getProducts();
      }
    },
    cartItemPrice(item) {
      if (!item) {
        return 0;
      }
      if (item.length == 1) {
        return item[0].Price;
      }
      if (item) {
        return item.find((el) => el.Code == this.currency)
          ? item.find((el) => el.Code == this.currency).Price
          : 0;
      }
      return 0;
    },
    getProducts() {
      this.loadingProducts = true;

      let urlFilter = "";
      if (this.customer && this.customer.Id) {
        urlFilter = "?customerId=" + this.customer.Id;
      } else if (this.$route.params.customerId) {
        urlFilter = "?customerId=" + this.$route.params.customerId;
      }

      request.get("/api/kdesign/baksetoverview" + urlFilter, null, (res) => {
        if (res.success) {
          if (this.customers) {
            this.customers.forEach((element) => {
              if (res.CustomerId == element.Id) {
                this.customer = element;
                this.currency = element.Currency;
              }
            });
          } else if (this.customer) {
            if (this.customer.Currency) {
              if (this.customer.Currency.length > 0) {
                this.currency = this.customer.Currency;
              } else {
                this.currency = "EUR";
              }
            } else {
              this.currency = "EUR";
            }
          } else {
            this.currency = "EUR";
          }

          this.basketId = res.BasketId;
          this.customerId = res.CustomerId;
          this.seasonCodes = [];
          this.productsWithSeasons = [];
          this.products = res.Products;
          res.Products.forEach((product) => {
            if (product.SeasonCode) {
              this.seasonCodes.push(product.SeasonCode);
            }
            if (product.Seasons.length > 1) {
              this.hasMultiSeasons = true;
              this.productsWithSeasons.push(product);
            }
          });
          this.totalNetPrice = res.TotalNetPrices;
        } else {
          this.loadingProducts = false;
          return null;
        }
        this.loadingProducts = false;
      });
    },
    makeAddress(name, addres, city, post) {
      return name + ", " + addres + ", " + city + ", " + post;
    },
    allowedDates(val) {
      const date = parseInt(new Date(val).getDay());
      if (date == 6 || date == 0) {
        return false;
      }
      return true;
    },
    comfirmOrder() {
      this.isLoading = true;
      const { data } = this.$refs.signaturePad.saveSignature();

      const productSeasonCodes = [];
      for (const key in this.selectedSeasonProducts) {
        productSeasonCodes.push({
          ProductCode: key,
          SeasonCode: this.selectedSeasonProducts[key].Code,
        });
      }

      request.post(
        "/api/kdesign/order-confirm",
        {
          CustomerId: this.customer.Id,
          DeliveryAddressCode: this.deliveryAddres,
          Remark: this.comment ? this.comment : "",
          Reference: this.reference ? this.reference : "",
          AgreedConditions: this.checkbox,
          BasketId: this.basketId,
          Signature: data,
          ProductSeasonCodes: productSeasonCodes,
        },
        (res) => {
          this.isLoading = false;
          if (res.success) {
            this.setRedirectTimer();
            return;
          } else if (res.message) {
            this.snackbar = true;
            this.snackbarText = res.message;
            this.snackbarColor = "error";
          } else {
            this.snackbar = true;
            this.snackbarText = this.$t(
              "Woops, something went wrong! Please try again."
            );
            this.snackbarColor = "error";
          }
        }
      );
    },
    setRedirectTimer() {
      const timerTime = 7000;
      this.dialog = true;
      let timer = setTimeout(() => {
        clearTimeout(timer);
        this.$router.push({
          name: "editPage",
          params: { id: this.$route.params.id },
        });

        this.$router.go(1);
      }, timerTime);
    },

    goTo() {
      this.$router.push({
        name: "editPage",
        params: { id: this.$route.params.id },
      });
      this.$router.go(1);
    },
    async getColorById(id) {
      let color;
      if (id) {
        color = await axios
          .get(this.$config.apiUrl + "/api/kdesign/productcolor?colorId=" + id)
          .then((res) => {
            return (
              this.$config.apiUrl + "/images/products/colors/" + res.data.Image
            );
          });
        return await color;
      }
      return "";
    },
    goBack() {
      let customerIdRoute = this.$route.params.customerId;
      if (this.customerId) {
        customerIdRoute = this.customerId;
      }
      this.$router.push({
        name: "cart",
        params: {
          id: this.$route.params.id,
          isAgent: this.$route.params.isAgent,
          customerId: customerIdRoute,
        },
      });
      this.$router.go(-2);
    },
  },
};
</script>

<style scoped>
.max-hight {
  max-height: 90vh;
}
.container-signature-pad {
  border: solid 1px black;
  border-radius: 5px;
}
</style>