<template>
  <div>
    <v-row v-if="account">
      <v-col cols="12">
        <v-card elevation="0">
          <v-card-title>{{ $t("Company data") }}</v-card-title>
          <v-card-text>
            <v-row no-gutters>
              <v-col>
                <v-row>
                  <v-col cols="12">
                    <v-row no-gutters>
                      <v-col cols="6" class="font-weight-bold">{{
                        $t("Customer number")
                      }}</v-col>
                      <v-col cols="6">{{ account.Code }}</v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-row no-gutters>
                      <v-col cols="6" class="font-weight-bold">{{
                        $t("Name")
                      }}</v-col>
                      <v-col cols="6">{{ account.Name }}</v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-row no-gutters>
                      <v-col cols="6" class="font-weight-bold">{{
                        $t("BTW-number")
                      }}</v-col>
                      <v-col cols="6">{{ account.Vat }}</v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-row no-gutters>
                      <v-col cols="6" class="font-weight-bold">{{
                        $t("Telephone")
                      }}</v-col>
                      <v-col cols="6">{{ account.Phone }}</v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-row no-gutters>
                      <v-col cols="6" class="font-weight-bold">{{
                        $t("Email")
                      }}</v-col>
                      <v-col cols="6">{{ account.Email }}</v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-row no-gutters>
                      <v-col cols="6" class="font-weight-bold">{{
                        $t("Mobile Phone")
                      }}</v-col>
                      <v-col cols="6">{{ account.MobilePhone }}</v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-row no-gutters>
                      <v-col cols="6" class="font-weight-bold">{{
                        $t("Payment terms code")
                      }}</v-col>
                      <v-col cols="6">{{ account.PaymentTermsCode }}</v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col>
                <h4 class="mb-4">{{ $t("Opening hours") }}</h4>
                <p>{{ account.OpeningHours }}</p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card elevation="0">
          <v-card-title>{{ $t("Addresses") }}</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="6" class="pa-0">
                <v-row no-gutters class="ml-4">
                  <v-col cols="12">
                    <v-row no-gutters class="mb-4">
                      <v-col
                        cols="12"
                        class="font-weight-bold text-decoration-underline"
                      >
                        {{ $t("Billing address") }}
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    class="font-weight-bold mt-4"
                    v-if="account.Latitude > 0 || account.Longitude > 0"
                  >
                    Google Maps
                    <template cols="6">
                      <a
                        class="ml-2"
                        :href="
                          'https://maps.google.com/?q=' +
                          account.Latitude +
                          ',' +
                          account.Longitude
                        "
                        target="_blank"
                      >
                        <v-btn fab x-small>
                          <v-icon>mdi-google-maps</v-icon>
                        </v-btn>
                      </a>
                    </template>
                  </v-col>
                  <v-col cols="12">
                    <v-row no-gutters>
                      <v-col cols="6" class="font-weight-bold">{{
                        $t("Country")
                      }}</v-col>
                      <v-col cols="6">{{ account.Country }}</v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-row no-gutters>
                      <v-col cols="6" class="font-weight-bold">{{
                        $t("City")
                      }}</v-col>
                      <v-col cols="6">{{ account.City }}</v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-row no-gutters>
                      <v-col cols="6" class="font-weight-bold">{{
                        $t("Postal Code")
                      }}</v-col>
                      <v-col cols="6">{{ account.Zip }}</v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-row no-gutters>
                      <v-col cols="6" class="font-weight-bold">{{
                        $t("Street and number")
                      }}</v-col>
                      <v-col cols="6">{{ account.Address }}</v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6" class="pa-0">
                <v-row
                  no-gutters
                  class="ml-3"
                  v-for="(address, index) in account.Addresses"
                  :key="index"
                >
                  <v-col
                    cols="12"
                    class="font-weight-bold text-decoration-underline mb-4"
                    v-if="!address.IsPointOfSales"
                    >{{ $t("Delivery address") }}
                  </v-col>
                  <v-col
                    cols="12"
                    class="font-weight-bold text-decoration-underline mb-4"
                    v-else
                    >{{ $t("Sales address") }}
                  </v-col>
                  <v-col
                    class="font-weight-bold mt-4"
                    v-if="address.Latitude > 0 || address.Longitude > 0"
                  >
                    Google Maps
                    <template cols="6">
                      <a
                        class="ml-2"
                        :href="
                          'https://maps.google.com/?q=' +
                          address.Latitude +
                          ',' +
                          address.Longitude
                        "
                        target="_blank"
                      >
                        <v-btn fab x-small>
                          <v-icon>mdi-google-maps</v-icon>
                        </v-btn>
                      </a>
                    </template>
                  </v-col>
                  <v-col cols="12">
                    <v-row no-gutters>
                      <v-col cols="6" class="font-weight-bold">{{
                        $t("Country")
                      }}</v-col>

                      <v-col cols="6">{{ address.Country }}</v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-row no-gutters>
                      <v-col cols="6" class="font-weight-bold">{{
                        $t("City")
                      }}</v-col>
                      <v-col cols="6">{{ address.City }}</v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-row no-gutters>
                      <v-col cols="6" class="font-weight-bold">{{
                        $t("Postal Code")
                      }}</v-col>
                      <v-col cols="6">{{ address.Zip }}</v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-row no-gutters>
                      <v-col cols="6" class="font-weight-bold">{{
                        $t("Street and number")
                      }}</v-col>
                      <v-col cols="6">{{ address.Address }}</v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-row no-gutters>
                      <v-col cols="6" class="font-weight-bold">{{
                        $t("Address2")
                      }}</v-col>
                      <v-col cols="6">{{ address.Address2 }}</v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" class="mb-8">
                    <v-row no-gutters>
                      <v-col cols="6" class="font-weight-bold">{{
                        $t("OpeningHours")
                      }}</v-col>
                      <v-col cols="6">{{ address.OpeningHours }}</v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card elevation="0">
          <v-card-title>{{ $t("Contact persons") }}</v-card-title>
          <v-card-text>
            <v-row
              no-gutters
              class="ml-4"
              v-for="(contact, index) in account.Contacts"
              :key="index"
            >
              <v-col cols="12">
                <v-row no-gutters>
                  <v-col cols="6" class="font-weight-bold">{{
                    $t("Function")
                  }}</v-col>
                  <v-col cols="6">{{ contact.Type }}</v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row no-gutters>
                  <v-col cols="6" class="font-weight-bold">{{
                    $t("Email")
                  }}</v-col>
                  <v-col cols="6">{{ contact.Email }}</v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row no-gutters>
                  <v-col cols="6" class="font-weight-bold">{{
                    $t("Usage")
                  }}</v-col>
                  <v-col cols="6">{{ contact.Usage }}</v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="mb-4">
                <v-row no-gutters>
                  <v-col cols="6" class="font-weight-bold">{{
                    $t("Description")
                  }}</v-col>
                  <v-col cols="6">{{ contact.Description }}</v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      {{ $t("No customer associated with this account") }}
    </v-row>
  </div>
</template>

<script>
export default {
  props: ["account", "isAdmin"],
};
</script>


<style>
</style>