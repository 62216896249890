<template>
  <div>
    <div v-if="!loading">
      <v-row v-if="disabeleButtons">
        <v-alert color="error" type="error">
          {{ $t("You currently do not have access to place orders.") }}
        </v-alert>
      </v-row>

      <v-row v-else>
        <v-col cols="2" md="2" class="d-none d-md-flex d-lg-flex d-xl-flex">
          <v-row no-gutters>
            <v-col>
              <v-combobox
                clearable
                v-if="isSalesAgent"
                v-model="customer"
                :items="getCustomersNameAndCode"
                item-text="Name"
                item-value="Id"
                dense
                hide-details
                :label="$t('Customer')"
                class="mb-6"
                @change="changeCustomer(true)"
                @click:clear="clearCustomerIds()"
              ></v-combobox>
              <v-select
                v-model="sortBy"
                :items="[
                  { name: $t('Newest products'), Id: 'created' },
                  { name: $t('Price'), Id: 'grossprice' },
                ]"
                item-text="name"
                item-value="Id"
                dense
                hide-details
                :label="$t('Sort by')"
                clearable
              ></v-select>
              <KeepAlive>
                <productFilter
                  @updateSortString="updateSort"
                  @changeProducts="changeFilter"
                  :isSalesAgent="isSalesAgent"
                />
              </KeepAlive>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="10" sm="6" md="10">
          <v-row class="d-flex justify-end">
            <v-col class="d-flex align-center justify-end">
              <v-badge class="mr-2 mt-1" color="success" inline>{{
                $t("Available immediately")
              }}</v-badge>
              <v-badge class="mt-1" color="warning" inline>{{
                $t("Not immediately available")
              }}</v-badge>
            </v-col>
            <v-col
              cols="2"
              sm="2"
              md="4"
              class="pt-2 d-none d-md-flex d-lg-flex d-xl-flex"
            >
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('Search')"
                single-line
                hide-details
                dense
                clearable
                @keydown.enter="searchItem($event.target.value)"
              ></v-text-field>
            </v-col>
            <v-col cols="1" sm="2" md="1" right class="pt-2 pr-1">
              <v-layout justify-end>
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-width="350"
                  offset-x
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-badge
                      :content="basketAmount"
                      :value="basketAmount"
                      color="primary"
                      overlap
                      class="mr-4"
                    >
                      <v-icon large v-bind="attrs" v-on="on"> mdi-cart </v-icon>
                    </v-badge>
                  </template>
                  <v-card class="pa-2">
                    <v-card-title>
                      {{ $t("Shopping cart") }}
                    </v-card-title>
                    <v-divider></v-divider>
                    <div v-if="cart.items.length != 0">
                      <v-list
                        fluid
                        style="max-height: 600px"
                        class="overflow-y-auto pa-0"
                      >
                        <v-list-item
                          v-for="(item, index) in cart.items"
                          :key="index"
                          class="my-4"
                        >
                          <v-list-item-avatar v-if="item.Image" class="pr-4">
                            <v-img
                              :src="
                                apiUrl +
                                '/images/products/' +
                                item.Image +
                                '?token=' +
                                getUserToken
                              "
                              alt="clothes"
                            ></v-img>
                          </v-list-item-avatar>

                          <v-list-item-avatar v-else>
                            <v-avatar color="grey lighten-4"> </v-avatar>
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-simple-table dense>
                              <template v-slot:default>
                                <thead>
                                  <tr>
                                    <th class="text-left">{{ $t("Size") }}</th>
                                    <th class="text-left">
                                      {{ $t("Code") }}
                                    </th>
                                    <th class="text-left">
                                      {{ $t("Amount") }}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="(variant, index) in item.Variants"
                                    :key="index"
                                  >
                                    <td>{{ variant.Size }}</td>
                                    <td>{{ item.VariantCode }}</td>
                                    <td v-if="currency">
                                      {{
                                        getCurrencySign +
                                        shoppingcartItemPrice(variant.Price) +
                                        " x " +
                                        variant.Amount
                                      }}
                                    </td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-btn icon @click="deleteItem(item)">
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </div>
                    <div v-else>
                      <v-row
                        no-gutters
                        v-if="cart.items.length == 0 && !shoppingcartLoading"
                      >
                        <v-col>
                          <v-layout justify-center align-center fill-height>
                            {{ $t("Shopping Cart is empty!") }}
                          </v-layout>
                        </v-col>
                      </v-row>
                      <v-layout
                        v-if="shoppingcartLoading"
                        align-center
                        justify-center
                        fill-height
                      >
                        <v-progress-circular
                          :size="50"
                          color="black"
                          indeterminate
                        ></v-progress-circular>
                      </v-layout>
                    </div>
                    <v-divider></v-divider>
                    <v-card-actions class="mt-2">
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        @click="goToShoppingCart()"
                        :disabled="basketAmount == 0"
                      >
                        {{ $t("Go to shopping cart") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </v-layout>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" v-if="products.length > 0">
              <v-row
                no-gutters
                v-for="(product, index) in getFilterdProducts"
                :key="product.Id"
              >
                <orderList
                  :product="product"
                  :productImages="productImages"
                  :ref="'product' + index"
                  :isCartItem="false"
                  :isSalesAgent="isSalesAgent"
                  :currencyFromParent="currency"
                  :customerId="getBaskedCustomerId"
                  :cartItems="cart"
                  @updateCart="updatefullShoppingcart"
                />
              </v-row>

              <!-- pagening -->
              <v-row class="mx-2 py-2" align="center" justify="center">
                <v-spacer></v-spacer>

                <span class="mr-4 grey--text">
                  <small
                    >{{ $t("Page") }} {{ getPage }} {{ $t("out of") }}
                    {{ getAmountOfPages }}</small
                  >
                </span>
                <v-btn
                  :disabled="hasPrevPage"
                  fab
                  x-small
                  class="mr-1"
                  @click="formerPage()"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn
                  :disabled="hasNextPage"
                  fab
                  x-small
                  class="ml-1"
                  @click="nextPage()"
                >
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-row>
              <v-snackbar
                v-model="snackbar"
                :color="snackbarColor"
                :timeout="timeout"
              >
                {{ snackbarText }}

                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="white"
                    v-bind="attrs"
                    @click="snackbar = false"
                    icon
                  >
                    <v-icon> mdi-close </v-icon>
                  </v-btn>
                </template>
              </v-snackbar>
            </v-col>
            <v-col cols="12" v-else>
              <v-sheet color="grey lighten-5" class="sheet-height mt-4">
                <v-layout align-center justify-center fill-height>
                  <v-progress-circular
                    v-if="loadingProducts"
                    :size="70"
                    :width="7"
                    color="black"
                    indeterminate
                  ></v-progress-circular>
                  <h3 v-else>{{ $t("No clothes found.") }}</h3>
                </v-layout>
              </v-sheet>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <preloader />
    </div>
  </div>
</template>

<script>
import request from "../../request";
import axios from "axios";
import auth from "../../auth";

export default {
  props: ["isSalesAgent"],

  data() {
    return {
      search: "",
      shoppingcartLoading: false,
      toggleShoppingcart: false,
      basketAmount: 0,
      dialog: false,
      sortBy: null,
      menu: null,
      cart: { items: [] },
      images: [],
      apiUrl: this.$config.apiUrl,
      snackbar: false,
      snackbarText: null,
      timeout: 4000,
      snackbarColor: null,
      filterUrlString: "",
      products: [],
      productPrices: [],
      productColors: [],
      productImages: [],
      customer: null,
      customers: null,
      isAgent: this.isSalesAgent,
      startCounter: 0,
      endCounter: 10,
      currency: null,
      accountCurrency: null,
      loadingProducts: false,
      page: 1,
      amountOfPages: 1,
      itemsPerPage: 10,
      disabeleButtons: false,
      loading: true,
    };
  },

  created() {
    request.get("/api/kdesign/account", null, (res) => {
      this.loading = false;
      if (!auth.userIsAdministrator()) {
        if (res.OrderInput == false) {
          this.disabeleButtons = true;
        }
      }
      if (this.isSalesAgent) {
        this.getCustomers();
      } else {
        this.getLastCart();
      }
      this.getProducts({
        start: 0,
        count: this.itemsPerPage,
        search: "",
        query: "",
      });
      this.checkIfAgent();
      this.shoppingcartLoading = true;
      this.$store.dispatch("resetProductColor");
    });
  },
  computed: {
    getCustomersNameAndCode() {
      if (!this.customers) {
        return [];
      }
      let showCustomers = [];
      this.customers.forEach((element) => {
        if (element.OrderInput) {
          element.Name = element.Name + " (" + element.Code + ")";
          showCustomers.push(element);
        }
      });
      return showCustomers;
    },
    getCurrencySign() {
      let currencySign;
      switch (this.currency) {
        case "EUR":
          currencySign = "€ ";
          break;
        case "GBP":
          currencySign = "£ ";
          break;
        case "USD":
          currencySign = "$ ";
          break;

        default:
          currencySign = "€ ";
          break;
      }
      return currencySign;
    },
    getFilterdProducts() {
      this.products.forEach((element) => {
        this.productPrices[element.Code] = [];
        element.Images.forEach((image) => {
          this.productImages[element.Code] = [];
          this.productImages[element.Code].push(
            this.apiUrl +
              "/images/products/" +
              image +
              "?token=" +
              this.getUserToken
          );
        });

        element.Variants.forEach((el) => {
          this.productColors.push(el.ColorId);
          this.productPrices[element.Code][el.Code] = [];
          el.CommercialImages.forEach((commercialImage) => {
            this.productImages[element.Code].push(
              this.apiUrl +
                "/images/products/commercial-images/" +
                commercialImage +
                "?token=" +
                this.getUserToken
            );
          });
          el.Stock.forEach((variant) => {
            this.productPrices[element.Code][el.Code].push({
              Netprices: variant.NetPrices,
              Size: variant.Size,
            });
          });
        });
      });
      if (this.customer) {
        for (const [key] of Object.entries(this.$refs)) {
          if (this.$refs[key][0]) {
            this.$refs[key][0].updateCutomerId(this.customer["Id"]);
          }
        }
      }
      return this.products;
    },
    getBaskedCustomerId() {
      if (this.cart["CustomerId"]) {
        return this.cart["CustomerId"];
      }
      return null;
    },
    getPage: {
      get() {
        return this.page;
      },
      set(newItem) {
        this.page = newItem;
      },
    },
    hasPrevPage() {
      if (this.getPage == 1) {
        return true;
      }
      return false;
    },
    hasNextPage() {
      if (this.getPage < this.amountOfPages) {
        return false;
      }
      return true;
    },
    getAmountOfPages: {
      get() {
        if (this.amountOfPages == 0) {
          return 1;
        }
        return this.amountOfPages;
      },
      set(newItem) {
        this.amountOfPages = newItem;
      },
    },
    getUserToken() {
      return auth.userToken();
    },
  },
  watch: {
    search: function (params) {
      this.searchItem(params);
    },
    sortBy: function (param) {
      this.resetPaging();
      this.getProducts({
        start: this.startCounter,
        count: this.itemsPerPage,
        search: this.search ? this.search : "",
        query: this.filterUrlString + "&sort=" + param,
      });
    },
  },
  methods: {
    clearCustomerIds() {
      this.customer = null;
      this.cart["CustomerId"] = null;
      for (const [key] of Object.entries(this.$refs)) {
        if (this.$refs[key][0]) {
          this.$refs[key][0].updateCutomerId(null);
        }
      }
    },
    changeCustomer(item = false) {
      if (this.customer) {
        this.currency = this.customer.Currency;
        for (const [key] of Object.entries(this.$refs)) {
          if (this.$refs[key][0]) {
            this.$refs[key][0].changeCurrency(this.currency);
            this.$refs[key][0].updateCutomerId(this.customer.Id);
          }
        }
        if (this.customer.Id) {
          this.updateCustomer(this.customer.Id);
        }
      } else {
        if (item) {
          this.cart.BasketId = null;
          this.cart.CustomerId = null;
          this.cart.Products = [];
        }
        this.updateCart();
      }
    },
    updatefullShoppingcart(event) {
      //update parent component
      this.shoppingcartLoading = true;
      this.updateCart(event);
      //refresh products
      this.getProducts({
        start: this.startCounter,
        count: this.itemsPerPage,
        search: this.search ? this.search : "",
        query: this.filterUrlString,
      });
    },
    updateCustomer(id) {
      if (
        id &&
        this.cart["CustomerId"] == null &&
        this.cart.items.length != 0
      ) {
        let urlFilter = this.cart["BasketId"]
          ? "&basketId=" + this.cart["BasketId"]
          : "";
        request.post(
          "/api/kdesign/basket-update-customer?customerId=" + id + urlFilter,
          null,
          () => {
            this.updateCart(id);
          }
        );
      } else {
        this.updateCart(id);
      }
    },
    loopRefs() {
      for (const [key] of Object.entries(this.$refs)) {
        if (this.$refs[key][0]) {
          this.$refs[key][0].changeCurrency(this.currency);
        }
      }
    },
    checkIfAgent() {
      request.get("/api/kdesign/account", null, (res) => {
        if (!auth.userIsAdministrator()) {
          if (res.OrderInput == false) {
            this.disabeleButtons = true;
          }
        }
        if (res.success) {
          //set isagent
          //set currency
          this.currency = res.Currency;
          this.accountCurrency = res.Currency;
          //set customers
          this.customer = res;
          this.loopRefs();
        } else {
          this.currency = "EUR";
          this.loopRefs();
        }
      });
    },
    getCustomers() {
      request.get("/api/kdesign/customers", null, (res) => {
        if (res.success) {
          this.customers = res;
          this.customers.sort((a, b) => a.Name.localeCompare(b.Name));
          this.getLastCart();
        }
      });
      return;
    },

    searchItem(params) {
      clearTimeout(this.timer);
      this.resetPaging();
      this.timer = setTimeout(() => {
        this.getProducts({
          start: 0,
          count: this.itemsPerPage,
          search: params ? params : "",
          query: this.filterUrlString,
        });
      }, 800);
    },
    goToShoppingCart() {
      this.$store.dispatch("resetProductColor");
      let id = "";
      if (this.cart["CustomerId"]) {
        id = this.cart["CustomerId"];
      } else if (this.customer) {
        id = this.customer.Id;
      } else {
        id = null;
      }
      this.$router.push({
        name: "cart",
        params: {
          id: this.$route.params.id,
          isAgent: this.isSalesAgent,
          customerId: id,
        },
      });
      this.$router.go(1);
    },
    getAmountCart(id) {
      let url = id ? "?customerId=" + id : "";
      request.get("/api/kdesign/baskettotalcount" + url, null, (res) => {
        this.basketAmount = res.item;
        this.basketAmount == 0 ? (this.shoppingcartLoading = false) : "";
      });
    },
    changeFilter(items) {
      this.products = items;
    },
    getLastCart() {
      this.shoppingcartLoading = true;
      request.get("/api/kdesign/last-updated-baksetoverview", null, (res) => {
        this.shoppingcartLoading = false;

        if (res.success) {
          const items = this.productStructure(res);
          if (res.CustomerId && this.customers) {
            const customer = this.customers.find(
              (el) => el.Id == res.CustomerId
            );
            if (customer.OrderInput) {
              this.customer = customer;
            } else {
              return;
            }
            this.currency = this.customer.Currency;
          }
          this.cart = {
            BasketId: res.BasketId,
            CustomerId: res.CustomerId,
            items: items,
          };
          for (const [key] of Object.entries(this.$refs)) {
            if (this.$refs[key][0]) {
              this.$refs[key][0].changeCurrency(this.currency);
              this.$refs[key][0].updateCutomerId(res.CustomerId);
            }
          }
          this.getAmountCart(res.CustomerId);
        }
      });
    },
    updateCart(id) {
      let urlFilter = id ? "?customerId=" + id : "";
      request.get("/api/kdesign/baksetoverview" + urlFilter, null, (res) => {
        if (res.success) {
          const items = this.productStructure(res);
          this.cart = {
            BasketId: res.BasketId,
            CustomerId: res.CustomerId,
            items: items,
          };
          this.getAmountCart(id);
        }
      });
    },
    productStructure(res) {
      let items = [];
      res.Products.forEach((element) => {
        const productCode = element.Code;
        element.Variants.forEach(async (el) => {
          const color = await this.getColorById(el.ColorId);
          const image = element.Images[0];
          const code = el.Code;

          items.push({
            Id: element.Id,
            Code: productCode,
            VariantCode: code,
            Image: image,
            Variants: [],
            Color: color,
          });
          el.Reserved.forEach((item) => {
            items.forEach((variant) => {
              if (
                variant.VariantCode == code &&
                variant.Code == productCode &&
                variant.Id == element.Id
              ) {
                variant.Variants.push({
                  Amount: item.BasketReservedAmount,
                  Price: item.NetPrices,
                  Size: item.Size,
                });
              }
            });
          });
        });
      });
      return items;
    },
    deleteItem(item) {
      let id = this.getBaskedCustomerId;
      let urlFilter = id ? "&customerId=" + id : "";
      request.post(
        "/api/kdesign/basketremove?productId=" +
          item.Id +
          "&variantCode=" +
          item.VariantCode +
          urlFilter,
        null,
        (res) => {
          if (res.success) {
            //update parent component
            this.shoppingcartLoading = true;
            this.updateCart(id);
            //get all products
            this.getProducts({
              start: 0,
              count: this.itemsPerPage,
              search: "",
              query: this.filterUrlString,
            });
            // show snackbar
            this.snackbar = true;
            this.snackbarText = this.$t(
              "Item successfully deleted from shopping cart."
            );
            this.snackbarColor = "succes";
          } else {
            this.snackbar = true;
            this.snackbarText = this.$t(
              "Oops something went wrong, please try again."
            );
            this.snackbarColor = "error";
          }
        }
      );
    },
    updateSort(url) {
      this.startCounter = 0;
      this.resetPaging();
      this.filterUrlString =
        url +
        "&isB2B=" +
        !this.isSalesAgent +
        "&isSalesAgent=" +
        this.isSalesAgent;
      this.getProducts({
        start: 0,
        count: this.endCounter,
        search: this.search ? this.search : "",
        query: this.filterUrlString,
      });
    },
    shoppingcartItemPrice(item) {
      if (item.length == 1) {
        return item[0].Price;
      }
      let price = 0;
      item.forEach((element) => {
        if (element.Code == this.currency) {
          return (price = element.Price);
        }
      });
      return price;
    },
    async getColorById(id) {
      let color;
      if (id) {
        color = await axios
          .get(this.apiUrl + "/api/kdesign/productcolor?colorId=" + id)
          .then((res) => {
            return this.apiUrl + "/images/products/colors/" + res.data.Image;
          });
        return color;
      }
      return "";
    },
    resetPaging() {
      this.page = 1;
      this.start = 0;
      this.count = 10;
    },
    getProducts(items) {
      this.loadingProducts = true;
      request.get(
        "/api/kdesign/products?start=" +
          items.start +
          "&count=" +
          items.count +
          "&search=" +
          items.search +
          items.query +
          "&isB2B=" +
          !this.isSalesAgent +
          "&isSalesAgent=" +
          this.isSalesAgent,
        null,
        (res) => {
          this.products = res;
          this.loadingProducts = false;
        }
      );
      this.productCounter(items);
    },
    nextPage() {
      this.startCounter = this.startCounter + this.itemsPerPage;
      const customer = this.customer ? "&customerId=" + this.customer.Id : "";
      this.getProducts({
        start: this.startCounter,
        count: this.endCounter,
        search: this.search ? this.search : "",
        query: this.filterUrlString + customer,
      });
      this.page++;
    },
    formerPage() {
      this.startCounter = this.startCounter - this.itemsPerPage;
      const customer = this.customer ? "&customerId=" + this.customer.Id : "";

      this.getProducts({
        start: this.startCounter,
        count: this.itemsPerPage,
        search: this.search ? this.search : "",
        query: this.filterUrlString + customer,
      });
      this.page--;
    },
    productCounter(items) {
      request.get(
        "/api/kdesign/productscount?" +
          "search=" +
          items.search +
          items.query +
          "&isB2B=" +
          !this.isSalesAgent +
          "&isSalesAgent=" +
          this.isSalesAgent,
        null,
        (res) => {
          this.amountOfPages = Math.ceil(res.item / this.itemsPerPage);
        }
      );
    },
  },
};
</script>

<style scoped>
.sheet-height {
  height: 65vh !important;
}
</style>