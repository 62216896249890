<template>
  <div>
    <v-row no-gutters v-if="userData">
      <v-col cols="12"><backButton /></v-col>
      <v-col
        ><h2>{{ userData.Name }}</h2>
      </v-col>
    </v-row>
    <accountDetail :isAdmin="isAdministrator" :account="userData" />
  </div>
</template>

<script>
import request from "../../request";
export default {
  data: function () {
    return {
      clients: [],
      userData: null,
      isAdministrator: null,
    };
  },
  created() {
    request.get("/api/kdesign/account", null, (res) => {
      if (res.UserId) {
        request.get("/user/" + res.UserId, null, (resolution) => {
          if (resolution.success) {
            if (
              Object.prototype.hasOwnProperty.call(
                resolution.data,
                "Administrator"
              )
            ) {
              this.isAdministrator = resolution.data.Administrator;
            }
          }
        });
      }
    });
    request.get(
      "/api/kdesign/customer?customerId=" + this.$route.params.clientId,
      null,
      (res) => {
        this.userData = res;
      }
    );
  },
};
</script>

<style>
</style>
