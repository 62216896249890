<template>
  <v-app
    light
    id="app"
    :class="[
      $route.name == 'login' ||
      $route.name == 'forgotPassword' ||
      $route.name == 'resetPassword' ||
      $route.name == 'activateAccount'
        ? 'appColor'
        : '',
    ]"
  >
    <div v-if="loggedIn && $route.name != 'login'">
      <v-navigation-drawer
        fixed
        clipped
        app
        v-model="drawer"
        class="overflow-y-auto"
      >
        <v-list>
          <router-link to="/websitesoverview">
            <v-list-item v-if="userIsAdministrator">
              <v-list-item-action class="mr-4">
                <v-icon>mdi-laptop</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-subtitle>
                  {{ $t("Modules") }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </router-link>

          <router-link to="/usersoverview">
            <v-list-item v-if="userIsAdministrator">
              <v-list-item-action class="mr-4">
                <v-icon>mdi-account-circle</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-subtitle>
                  {{ $t("Users") }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </router-link>
          <router-link to="/settings">
            <v-list-item v-if="userIsAdministrator">
              <v-list-item-action class="mr-4">
                <v-icon>mdi-cog</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-subtitle>
                  {{ $t("Settings") }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </router-link>
          <v-divider v-if="userIsAdministrator" />

          <v-list-item>
            <v-list-item-action class="mr-4 mb-0 mt-5">
              <v-icon>mdi-laptop</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <!-- Show dropdown when there are multiple websites -->
              <template v-if="websites">
                <v-select
                  v-if="websites.length > 1"
                  :items="websites"
                  :value="selectedWebsite"
                  :label="$t('Select a module')"
                  v-model="defaultSelected"
                  single-line
                  menu-props="bottom"
                  item-value="_id"
                  item-text="Name"
                  @change="setSelectedWebsite"
                  id="websiteSelect"
                  hide-details
                ></v-select>
                <!-- Show label when there is only one website -->
                <v-list-item-subtitle
                  class="mt-2 pt-3"
                  v-if="websites.length == 1"
                >
                  {{ websites[0].Name }}
                </v-list-item-subtitle>
              </template>
            </v-list-item-content>
          </v-list-item>
          <template v-if="selectedWebsite && currentLanguage">
            <v-list-item v-if="checkForPages()">
              <v-list-item-icon class="mx-0 my-auto mr-4">
                <v-icon> mdi-magnify </v-icon>
              </v-list-item-icon>
              <v-slide-x-transition>
                <v-text-field
                  :label="$t('Search')"
                  clearable
                  v-model="navSearchValue"
                ></v-text-field>
              </v-slide-x-transition>
            </v-list-item>
          </template>
          <template v-if="selectedWebsite && currentLanguage">
            <template v-for="(page, index) in selectedWebsiteSortedPages">
              <template v-if="page.Enabled && !page.IsSubPage">
                <v-list :key="index" class="py-0">
                  <template v-if="page.SubPages.length > 0">
                    <v-hover v-slot="{ hover }">
                      <v-list-item
                        @click="goToRoute('editPage', page._id.$oid, page)"
                        :class="[
                          hover && !page['Group']
                            ? 'menu-item-hover'
                            : 'menu-item-hover-group',
                        ]"
                      >
                        <v-layout
                          align-center
                          fill-height
                          justify-space-between
                        >
                          <router-link
                            :is="page.Group ? 'span' : 'router-link'"
                            :key="page._id.$oid"
                            :to="{
                              name: 'editPage',
                              params: { id: page._id.$oid },
                            }"
                          >
                            <v-layout>
                              <v-list-item-icon
                                v-if="page.Icon"
                                class="my-0 mr-4"
                                active
                              >
                                <v-icon class="menu-icon">{{
                                  page.Icon
                                }}</v-icon>
                              </v-list-item-icon>
                              <div class="pl-10" v-else>
                                <v-spacer></v-spacer>
                              </div>
                              <v-list-item-title
                                v-if="page.Name[currentLanguage].length == 0"
                                class="text-truncate black--text maxtext-width"
                              >
                                {{ page.Name[Object.keys(page.Name)[0]] }}
                              </v-list-item-title>
                              <v-list-item-title
                                v-else
                                class="text-truncate black--text maxtext-width"
                              >
                                {{ page.Name[currentLanguage] }}
                              </v-list-item-title>
                            </v-layout>
                          </router-link>
                          <template v-if="checkForIconUpDown(page)">
                            <v-btn icon @click="slideOpen(index)">
                              <v-icon>
                                {{
                                  openPage[index]
                                    ? "mdi-chevron-up"
                                    : "mdi-chevron-down"
                                }}
                              </v-icon>
                            </v-btn>
                          </template>
                        </v-layout>
                      </v-list-item>
                    </v-hover>
                    <v-expand-transition>
                      <v-sheet v-if="openPage[index]" height="auto">
                        <div
                          :key="'sub-pages-container' + page._id.$oid"
                          v-if="page.SubPages.length > 0"
                        >
                          <div
                            v-for="(subPage, i) in getSubPages(page.SubPages)"
                            :key="i"
                          >
                            <div v-if="subPage.Enabled">
                              <v-hover v-slot="{ hover }">
                                <router-link
                                  :key="subPage._id.$oid"
                                  :to="{
                                    name: 'editPage',
                                    params: { id: subPage._id.$oid },
                                  }"
                                >
                                  <div
                                    :class="{ 'menu-item-hover': hover }"
                                    class="colorclass"
                                  >
                                    <v-list-item
                                      subheader
                                      class="iconPadding max-hight"
                                    >
                                      <v-list-item-icon
                                        class="my-2 mr-4"
                                        v-if="
                                          getSubpageIcon(subPage._id.$oid) != ''
                                        "
                                      >
                                        <v-icon class="menu-icon">{{
                                          getSubpageIcon(subPage._id.$oid)
                                        }}</v-icon>
                                      </v-list-item-icon>
                                      <div class="pl-10" v-else>
                                        <v-spacer></v-spacer>
                                      </div>
                                      <v-list-item-subtitle class="black--text">
                                        {{
                                          getNameFromPageId(subPage._id.$oid)
                                        }}
                                      </v-list-item-subtitle>
                                    </v-list-item>
                                  </div>
                                </router-link>
                              </v-hover>
                            </div>
                          </div>
                        </div>
                      </v-sheet>
                    </v-expand-transition>
                  </template>
                  <v-hover v-slot="{ hover }" v-else>
                    <v-list-item
                      @click="goToRoute('editPage', page._id.$oid, page)"
                      :class="[
                        hover && !page['Group']
                          ? 'menu-item-hover'
                          : 'menu-item-hover-group',
                      ]"
                    >
                      <template>
                        <router-link
                          :is="page.Group ? 'span' : 'router-link'"
                          :key="page._id.$oid"
                          :to="{
                            name: 'editPage',
                            params: { id: page._id.$oid },
                          }"
                        >
                          <v-layout>
                            <v-list-item-icon
                              class="my-0 mr-4"
                              v-if="page.Icon"
                            >
                              <v-icon class="menu-icon">{{ page.Icon }}</v-icon>
                            </v-list-item-icon>
                            <div class="pl-10" v-else>
                              <v-spacer></v-spacer>
                            </div>
                            <v-list-item-title
                              v-if="page.Name[currentLanguage].length == 0"
                              class="text-truncate black--text maxtext-width"
                            >
                              {{ page.Name[Object.keys(page.Name)[0]] }}
                            </v-list-item-title>
                            <v-list-item-title
                              v-else
                              class="text-truncate black--text maxtext-width"
                            >
                              {{ page.Name[currentLanguage] }}
                            </v-list-item-title>
                          </v-layout>
                        </router-link>
                      </template>
                    </v-list-item>
                  </v-hover>
                </v-list>
              </template>
            </template>
          </template>
        </v-list>
      </v-navigation-drawer>

      <v-app-bar color="primary" id="toolbar" clipped-left fixed dark app>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

        <v-toolbar-title class="hidden-xs-only mr-5">
          <router-link to="/" v-if="logo">
            <img
              id="logo"
              :src="serverUrl + '/images/' + logo + '?token=' + userToken"
            />
          </router-link>
          <router-link to="/" v-else>
            <img contain id="logo" :src="getLogo" @error="failedImage = true" />
          </router-link>
        </v-toolbar-title>

        <div class="d-flex align-center" style="margin-left: auto">
          <languageSelector :whiteColors="true" :addIcon="true"/>

          <v-btn
            outlined
            fab
            small
            class="mr-10"
            @click.stop="toggleSearchDrawer()"
          >
            <v-icon>mdi-magnify</v-icon>
          </v-btn>

          <v-btn v-on:click="logOut" :loading="loggingOut" outlined>{{
            $t("Logout")
          }}</v-btn>
        </div>
      </v-app-bar>

      <v-dialog v-model="onboardingDialog" width="1100">
        <v-card flat tile>
          <v-card-title class="pb-0">
            <v-spacer></v-spacer>
            <v-btn icon large color="error" @click="onboardingDialog = false">
              <v-icon>mdi-close-circle</v-icon>
            </v-btn>
          </v-card-title>
          <v-window
            v-model="onboarding"
            show-arrows
            class="color-window-onboarding"
          >
            <template v-slot:[`prev`]="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon> mdi-chevron-left </v-icon>
              </v-btn>
            </template>
            <template v-slot:[`next`]="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon> mdi-chevron-right </v-icon>
              </v-btn>
            </template>
            <v-window-item
              v-for="(onboardingItem, index) in onboardingItems"
              :key="`card-${index}`"
            >
              <v-card color="transparent">
                <v-row class="fill-height" align="center" justify="center">
                  <v-card-text class="text-justify">
                    <v-container>
                      <v-row
                        class="mx-12 my-0"
                        v-if="!onboardingItem.Components[currentLanguage]"
                      >
                        <v-col cols="6" class="pr-6">
                          <v-img
                            v-if="getUserToken"
                            contain
                            height="400"
                            :lazy-src="
                              serverUrl +
                              '/images/onboarding/' +
                              onboardingItem.Images[currentLanguage] +
                              '?token=' +
                              getUserToken
                            "
                            :src="
                              serverUrl +
                              '/images/onboarding/' +
                              onboardingItem.Images[currentLanguage] +
                              '?token=' +
                              getUserToken
                            "
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="grey lighten-5"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-col>
                        <v-col cols="6" class="pl-6">
                          <p v-html="onboardingItem.Texts[currentLanguage]"></p>
                        </v-col>
                      </v-row>
                      <component
                        class="mx-12 my-0"
                        v-else
                        :is="onboardingComponent"
                      ></component>
                    </v-container>
                  </v-card-text>
                </v-row>
              </v-card>
            </v-window-item>
          </v-window>
          <v-card-actions>
            <v-layout justify-center>
              <v-item-group v-model="onboarding" class="text-center" mandatory>
                <v-item
                  v-for="(n, index) in onboardingItems"
                  :key="`btn-${index}`"
                  v-slot="{ active, toggle }"
                >
                  <v-btn
                    color="primary"
                    :input-value="active"
                    icon
                    @click="toggle"
                    x-small
                  >
                    <v-icon>mdi-record</v-icon>
                  </v-btn>
                </v-item>
              </v-item-group>
            </v-layout>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-main>
        <v-container fluid>
          <transition name="fade">
            <router-view :key="$route.params.id"></router-view>
          </transition>
        </v-container>
      </v-main>

      <v-footer fixed clipped-left app>
        <v-spacer></v-spacer>
        &copy; {{ year }} {{ websiteName }}
        <v-spacer></v-spacer>
        <v-hover v-slot="{ hover }" v-if="onboardingItems.length">
          <v-btn icon :color="hover ? 'info' : ''" @click="setOnboarding()">
            <v-icon>mdi-information</v-icon>
          </v-btn>
        </v-hover>
        <v-hover v-slot="{ hover }">
          <v-btn
            icon
            :color="hover ? 'error' : ''"
            @click="$store.dispatch('setErrorDialog', true)"
          >
            <v-icon>mdi-alert</v-icon>
          </v-btn>
        </v-hover>
      </v-footer>
      <errorHandeling
        v-if="error"
        :snackbarText="error"
        :buttons="[
          {
            isText: true,
            functionName: 'reportError',
            text: 'Report error',
          },
          {
            isText: true,
            functionName: 'clearError',
            text: 'Close',
          },
        ]"
        snackbarColor="error"
        snackbarTimout="-1"
        snackbarIcon="mdi-alert"
        @clearError="$store.dispatch('resetError')"
      />
      <v-dialog v-model="errorDialog" persistent max-width="800">
        <v-card>
          <v-card-title>
            {{ $t("Report problem") }}
          </v-card-title>
          <v-card-text>
            <v-row no-gutters>
              <v-col>
                <v-textarea
                  v-model="errorText"
                  :label="$t('The problem')"
                  :placeholder="$t('Describe the problem')"
                  :hint="$t('When clicking the button... it fails to...')"
                ></v-textarea>
              </v-col>
              <v-col cols="12" class="mt-4">
                <v-layout row justify-end>
                  <v-btn
                    text
                    @click="$store.dispatch('setErrorDialog', false)"
                    >{{ $t("Cancel") }}</v-btn
                  >
                  <v-btn
                    @click="
                      $store.dispatch('reportErrorToDeveloper', {
                        errortext: errorText,
                        route: $route,
                      })
                    "
                    :loading="isReporting"
                    :disabled="!errorText"
                    class="ml-sm-4"
                    @close="$store.dispatch('setErrorDialog', false)"
                    >{{ $t("Send error to developers") }}</v-btn
                  >
                </v-layout>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <v-container
      v-else-if="!loggedIn && $route.name == 'login'"
      id="login-container"
      fill-height
      fluid
    >
      <v-row align="center" justify="center">
        <v-col>
          <transition name="fade">
            <login></login>
          </transition>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-else-if="!loggedIn" id="login-container" fill-height fluid>
      <v-row align="center" justify="center">
        <v-col>
          <transition name="fade">
            <router-view :key="$route.params.id"></router-view>
          </transition>
        </v-col>
      </v-row>
    </v-container>
    <v-navigation-drawer
      v-if="loggedIn"
      width="675px"
      v-model="searchDrawer"
      fixed
      clipped
      temporary
      right
    >
      <v-list-item>
        <v-list-item-avatar>
          <v-btn icon @click.stop="toggleSearchDrawer()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>
            <v-text-field
              :label="$t('Search')"
              autofocus
              clearable
              @click:clear="onSearchCleared()"
              @keyup="searchContent"
              v-model="searchValue"
            ></v-text-field>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list v-if="lastChangedContent != null">
        <v-subheader v-if="searchedValues && lastChangedContent.length > 0">{{
          $t("Search results")
        }}</v-subheader>
        <v-subheader
          v-else-if="searchedValues && lastChangedContent.length == 0"
          >{{ $t("No results") }}</v-subheader
        >
        <v-subheader v-else>{{ $t("Recent changes") }}</v-subheader>
        <v-list-item
          v-for="content in lastChangedContent"
          :key="content._id.$oid"
          link
          :to="
            content.PageRepeatingContent
              ? {
                  name: 'editPageAddEditItem',
                  params: {
                    id: content.PageId.$oid,
                    itemId: content._id.$oid,
                  },
                }
              : { name: 'editPage', params: { id: content.PageId.$oid } }
          "
        >
          <v-list-item-avatar>
            <v-icon>{{
              content.PageRepeatingContent
                ? "mdi-format-list-bulleted"
                : "mdi-text-long"
            }}</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>
              {{ content.DisplayLabel }}
            </v-list-item-title>
            <v-list-item-subtitle
              v-text="
                content.WebsiteName +
                (content.WebsiteName == '' ? '' : ' - ') +
                content.PageName[currentLanguage]
              "
            >
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-app>
</template>

<script>
import auth from "../auth";
import request from "../request";
import languageSelector from "./shared/LanguageSelector";
import helpers from "../helpers";

import { mapGetters } from "vuex";

export default {
  data() {
    return {
      userIsAdministrator: auth.userIsAdministrator(),
      loggedIn: auth.loggedIn(),
      year: new Date().getFullYear(),
      drawer: null,
      loggingOut: false,
      websiteName: this.$config.websiteName,
      logo: this.$config.logoNeg,
      serverUrl: helpers.getServerUrl(),
      searchDrawer: null,
      lastChangedContent: null,
      searchValue: "",
      searchedValues: false,
      searchTimeout: null,
      defaultSelected: "",
      iconChecker: [],
      openPage: [],
      navSearchValue: "",
      onboarding: 0,
      onboardingDialog: false,
      onboardingItems: [],
      errorText: null,
      failedImage: false,
      userToken: auth.userToken(),
    };
  },

  created() {
    auth.onChange = (loggedIn) => {
      this.loggedIn = loggedIn;
      this.userIsAdministrator = auth.userIsAdministrator();
      if (this.loggedIn) {
        this.getOnboarding();
        this.getWebsites();
      }
    };

    if (this.loggedIn) {
      this.getOnboarding();
      this.getWebsites();
    }

    document.title = this.$config.websiteName;
  },

  computed: {
    ...mapGetters({
      websites: "allWebsites",
      selectedWebsite: "selectedWebsite",
      error: "error",
      isReporting: "isReporting",
      errorDialog: "errorDialog",
    }),
    getUserToken() {
      return auth.userToken();
    },
    getLogo: {
      get() {
        return this.failedImage
          ? this.serverUrl +
              "/images/" +
              "logo-neg.png" +
              "?token=" +
              this.userToken +
              "&v=" +
              this.getDate()
          : this.serverUrl +
              "/images/" +
              "logo-neg.svg" +
              "?token=" +
              this.userToken +
              "&v=" +
              this.getDate();
      },
    },
    currentLanguage: {
      get: function () {
        return this.$i18n.locale();
      },
    },
    selectedWebsiteSortedPages: {
      get: function () {
        this.setDefaultSelected(this.selectedWebsite);
        const sortedWebsites = this.selectedWebsite.Pages.slice().sort(
          helpers.sortByKey(this.currentLanguage)
        );

        let allWebsitePages = [];
        sortedWebsites.forEach((element, index) => {
          const checkName = element.Name[this.currentLanguage]
            .toLowerCase()
            .includes(
              this.navSearchValue ? this.navSearchValue.toLowerCase() : ""
            );
          element.SubPages.forEach((subpage) => {
            if (
              this.$route.params.id == subpage.$oid &&
              !Object.prototype.hasOwnProperty.call(this.openPage, index)
            ) {
              this.$set(this.openPage, index, 1);
            }

            if (checkName && element.IsSubPage) {
              if (
                subpage.$oid == element._id.$oid &&
                !allWebsitePages.filter((e) => e.Name != element.Name).length >
                  0
              ) {
                if (
                  this.navSearchValue
                    ? this.navSearchValue.toLowerCase()
                    : "" == element.Name[this.currentLanguage].toLowerCase()
                ) {
                  let pageCopy = Object.assign({}, element);
                  pageCopy.SubPages = pageCopy.SubPages.filter(
                    (el) => el.$oid == subpage.$oid
                  );
                  allWebsitePages.push(pageCopy);
                } else {
                  allWebsitePages.push(element);
                }
              }
            }
          });
          if (checkName) {
            allWebsitePages.push(element);
          }
        });
        return allWebsitePages.filter((v, i, a) => a.indexOf(v) === i);
      },
    },

    onboardingComponent() {
      this.onboarding;
      return () =>
        this.onboardingItems[this.onboarding].Components[this.currentLanguage]
          ? import(
              "@/components/onboarding/" +
                this.onboardingItems[this.onboarding].Components[
                  this.currentLanguage
                ] +
                ".vue"
            )
          : null;
    },
  },
  methods: {
    setOnboarding() {
      this.onboarding = 0;
      this.getOnboarding(true);
    },
    getOnboarding(isViaDialog = false) {
      request.get("/onboardingsteps", null, (res) => {
        this.onboardingItems = res.data;
        if (isViaDialog && res.data.length && auth.userIsFirstLogin()) {
          this.onboardingDialog = true;
        }
      });
    },
    next() {
      this.onboarding =
        this.onboarding + 1 === this.onboardingItems ? 0 : this.onboarding + 1;
    },
    prev() {
      this.onboarding =
        this.onboarding - 1 < 0
          ? this.onboardingItems - 1
          : this.onboarding - 1;
    },
    getSubPages(subpages) {
      const pages = [];
      subpages.forEach((element) => {
        let page = this.selectedWebsite.Pages.find(
          (p) => p._id.$oid === element.$oid
        );
        pages.push(page);
      });
      return pages;
    },

    checkForIconUpDown(page) {
      const sortedWebsites = this.selectedWebsite.Pages.slice().sort(
        helpers.sortByKey(this.currentLanguage)
      );
      let hasAccess = false;
      page.SubPages.forEach((el) => {
        if (sortedWebsites.find((e) => e._id.$oid == el.$oid)) {
          hasAccess = true;
        }
      });

      return hasAccess;
    },
    checkForPages() {
      const subpages = this.selectedWebsite.Pages.filter(
        (el) => el.IsSubPage == true && el.Enabled == true
      );

      const pagesWithoutSubPages =
        this.selectedWebsite.Pages.filter((el) => el.Enabled == true).length -
        subpages.length;
      return pagesWithoutSubPages > 11;
    },
    slideOpen(index) {
      this.$set(this.openPage, index, !this.openPage[index]);
    },
    logOut() {
      this.loggingOut = true;

      auth.logOut((res) => {
        this.loggingOut = false;

        if (!res.success) {
          this.$store.dispatch("setError", res);
        } else {
          this.$router.push({ path: "login" });
        }
      });
    },
    getWebsites() {
      if (this.websites.length == 0) {
        request.get("/websites", null, (res) => {
          if (res.success) {
            this.$store.dispatch("setAllWebsites", res.data);
            this.setDefaultSelected(this.websites[0]);
          } else {
            this.$store.dispatch("setError", res);
          }
        });
      } else {
        this.setDefaultSelected(this.websites[0]);
        if (this.websites.length == 1) {
          this.setSelectedWebsite(this.websites[0]._id);
        }
      }
    },
    setDefaultSelected(value) {
      this.$store.dispatch("setSelectedWebsite", value);
      this.defaultSelected = value;
    },
    setSelectedWebsite(value) {
      this.resetIconList();
      this.$store.dispatch("setSelectedWebsiteById", value);
      const selectedWebsiteSortedPagesEnabled =
        this.selectedWebsiteSortedPages.filter((page) => page.Enabled == true);
      this.$router.push({
        name: "editPage",
        params: { id: selectedWebsiteSortedPagesEnabled[0]._id.$oid },
      });
      this.$router.go(1);
    },
    getNameFromPageId(id) {
      return this.selectedWebsite.Pages.find((p) => p._id.$oid === id).Name[
        this.currentLanguage
      ];
    },
    toggleSearchDrawer() {
      this.searchDrawer = !this.searchDrawer;
      if (this.searchDrawer) {
        window.scrollTo(0, 0);
        this.getLastChangedContent();
      }
    },
    getLastChangedContent() {
      this.searchValue = "";
      this.lastChangedContent = null;

      request.get("/lastchangedcontent", null, (res) => {
        this.searchedValues = false;

        if (res.success) {
          this.lastChangedContent = res.data;
        } else {
          this.$store.dispatch("setError", res);
        }
      });
    },
    searchContent() {
      var scope = this;

      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(function () {
        request.get(
          "/search/?searchValue=" + scope.searchValue,
          null,
          (res) => {
            scope.searchedValues = true;
            scope.lastChangedContent = null;

            if (res.success) {
              scope.lastChangedContent = res.data;
            } else {
              scope.error = res.message;
            }
          }
        );

        clearTimeout(this.searchTimeout);
      }, 800);
    },

    onSearchCleared() {
      this.getLastChangedContent();
    },
    iconCheck(page, index) {
      if (page.Icon) {
        this.iconChecker[index] = page.Icon;
      }
      return this.iconChecker.length >= 1 ? true : false;
    },
    resetIconList() {
      this.iconChecker = [];
    },
    getSubpageIcon(id) {
      return this.selectedWebsite.Pages.find((p) => p._id.$oid === id).Icon;
    },
    getDate() {
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      const yyyy = today.getFullYear();

      return mm + dd + yyyy;
    },
    goToRoute(name, pageId, page) {
      if (page["Group"]) {
        return;
      }

      if (this.$route.params.id != pageId) {
        this.$router.push({
          name: name,
          params: { id: pageId },
        });
        this.$router.go(1);
      }
    },
  },
  components: {
    languageSelector,
  },
};
</script>

<style>
a {
  text-decoration: none;
}
.appColor {
  background-color: var(--v-background-base) !important;
}
#logo {
  height: 50px;
  margin-top: 5px;
}

.row {
  margin: 0;
}

.router-link-active {
  font-weight: 500;
}

.router-link-active .v-list-item__subtitle {
  font-weight: bold !important;
}

#websiteSelect,
#websiteSelect .input-group__selections,
#websiteSelect i {
  margin-top: -8px;
}

#websiteSelect .input-group__details {
  display: none;
}

#languageSelect {
  margin-right: 30px;
}

.card ul {
  padding-bottom: 0;
}

.list__tile__content {
  color: #000 !important;
}

.fade-enter-active {
  transition-property: opacity;
  transition-duration: 0.3s;
}

.fade-enter-active {
  transition-property: opacity;
  transition-duration: 0.3s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.custom-overview-row {
  border-bottom: 1px solid #ddd;
}

.custom-overview-row.no-border {
  border-bottom: 0px;
}

.custom-overview-row:last-child {
  border-bottom: 0;
}

.border-top {
  border-top: 1px solid #ddd;
}

.custom-overview-row .input-group:not(.no-styling),
.custom-overview-row h4 {
  margin-left: 15px;
  margin-top: 15px;
  padding-right: 30px;
}

.custom-overview-row-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.custom-overview-row-title {
  text-overflow: ellipsis;
  overflow: hidden;
}

.textInput {
  border: 1px solid #e4e4e4;
  width: 100%;
  min-height: 30px;
  padding: 5px;
  outline: none;
}

.textInput:focus {
  outline: none;
}

.pageElementLanguageSelectRichText,
.pageElementLanguageSelect {
  max-width: 65px;
  padding: 0;
}

.add-item-select {
  max-width: 300px;
  float: left;
}

.sub-pages-container {
  margin-top: -5px;
  margin-left: 5px;
  margin-bottom: 10px;
}

.max-width-icon {
  max-width: 24px;
}
.iconPadding {
  margin: 0 2rem 0rem 2.5rem;
}
.max-hight {
  min-height: 32px !important;
}

.menu-item-hover {
  background: #ddd;
  cursor: pointer;
}
.menu-item-hover-group {
  cursor: default;
}
.router-link-active .menu-icon {
  color: var(--v-primaryColor-base) !important;
}
.maxtext-width {
  max-width: 120px;
}
.color-window-onboarding .v-window__prev {
  background: #ffff !important;
  color: #ffff !important;
  margin: 0 0 0 32px;
}
.color-window-onboarding .v-window__next {
  background: #ffff !important;
  color: #ffff !important;
  margin: 0 32px 0 0;
}
@media screen and (min-width: 1600px) {
  .maxtext-width {
    max-width: 140px;
  }
}

@media only screen and (max-width: 568px) {
  .edit-page-action-button {
    width: 100%;
  }

  .edit-page-action-button .btn__content {
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-top: 8px;
  }

  .edit-page-action-button .btn__content i {
    padding-top: 0px;
    margin-top: -3px;
    margin-left: 5px;
  }
}

@media only screen and (max-width: 465px) {
  .dropdown-action-button {
    margin: 0;
    position: relative;
    top: -14px;
  }
}
</style>
