<template>
  <div>
    <v-row no-gutters>
      <v-col v-if="item.UserId != null && !item.UserActive && !isAgents">
        <v-btn
          small
          :loading="activationEmail"
          @click="sendActivationEmail(item)"
        >
          {{ $t("Send activation email") }}
          <v-icon right> mdi-email </v-icon>
        </v-btn>
      </v-col>
      <v-col v-if="item.UserId != null && isAgents">
        <v-btn
          :loading="activationEmailPassword"
          small
          @click="sendNewPasswordEmail(item)"
        >
          {{ $t("Send new password by email") }}
          <v-icon right> mdi-email </v-icon>
        </v-btn>
      </v-col>
      <v-col
        v-if="item.UserId == null && item.Email != '' && item.Email != null"
      >
        <v-btn :loading="activationAccount" small @click="createAccount(item)">
          {{ $t("Create account") }}
          <v-icon right> mdi-email </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: ["item", "request", "isAgents"],
  data() {
    return {
      activationEmail: false,
      activationAccount: false,
      activationEmailPassword: false,
      activationEmailDisable: false,
    };
  },
  methods: {
    hasEmailAndIsActive(item) {
      if (this.activationEmailDisable) {
        return true;
      }
      if (item.Contacts.length == 0) return true;
      return false;
    },

    sendActivationEmail(user) {
      this.activationEmail = true;
      if (user.Id) {
        this.request.post(
          "/api/kdesign/send-activation-email?customerId=" + user.Id,
          null,
          (res) => {
            this.activationEmail = false;
            if (res.success) {
              this.$emit("userFeedback", {
                alert: true,

                alertType: "success",
                alertText: this.$t("Activation email sent successfully"),
              });

              this.activationEmailDisable = true;
              return;
            }
            this.$emit("userFeedback", {
              alert: true,

              alertType: "error",
              alertText: this.$t(
                "Woops, something went wrong! Please try again."
              ),
            });
          }
        );
      }
    },
    createAccount(user) {
      this.activationAccount = true;
      if (user.Id) {
        this.request.post(
          "/api/kdesign/create-user-acccount?customerId=" + user.Id,
          null,
          (res) => {
            this.activationAccount = false;
            if (res.success) {
              this.item.UserId = res.UserId;
              this.item.UserActive = res.UserActive;
              this.item.Email = res.Email;
              /* this.item = res; */
              this.$emit("userFeedback", {
                alert: true,

                alertType: "success",
                alertText: this.$t("Email for new Account sent successfully"),
              });
              return;
            }
            this.$emit("userFeedback", {
              alert: true,

              alertType: "error",
              alertText: this.$t(
                "Woops, something went wrong! Please try again."
              ),
            });
          }
        );
      }
    },
    sendNewPasswordEmail(user) {
      this.activationEmailPassword = true;
      if (user.Id) {
        this.request.post(
          "/api/kdesign/send-new-password-email?customerId=" + user.Id,
          null,
          (res) => {
            this.activationEmailPassword = false;
            if (res.success) {
              this.$emit("userFeedback", {
                alert: true,
                alertType: "success",
                alertText: this.$t("Email for new password sent successfully"),
              });
              return;
            }
            this.$emit("userFeedback", {
              alert: true,

              alertType: "error",
              alertText: this.$t(
                "Woops, something went wrong! Please try again."
              ),
            });
          }
        );
      }
    },
  },
};
</script>

<style scoped>
</style>