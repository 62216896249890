<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-card class="mt-4 mb-2" v-for="product in products" :key="product.name">
        <v-card-title v-text="product.name" class="pb-0"></v-card-title>
        <v-row>
          <v-layout align-center>
            <v-col cols="2" sm="1" md="2">
              <v-responsive>
                <v-img
                  src="https://www.k-designfashion.com/sites/default/files/styles/thumb/public/products/images/product/T875-249.jpg?itok=YBAB4eoj"
                  height="8rem"
                  width="8rem"
                  lazy-src
                  contain
                  class="ma-4"
                >
                </v-img>
              </v-responsive>
            </v-col>
            <v-col cols="10" sm="11" md="10">
              <v-simple-table dense class="pa-4">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Color</th>
                      <th
                        class="text-center"
                        v-for="(item, index) in product.available_sizes"
                        :key="index"
                      >
                        {{ item }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(color, index) in product.colors" :key="index">
                      <td><v-badge :color="color.name"></v-badge></td>
                      <td
                        v-for="(availableSize, index) in color.available_sizes"
                        :key="index"
                      >
                        <v-col
                          cols:1
                          class="d-flex flex-column align-center pa-0"
                        >
                          <v-btn
                            @click="changeAmountUp(product.name, color, index)"
                            icon
                          >
                            <v-icon small>mdi-chevron-up</v-icon>
                          </v-btn>
                          <input
                            type="number"
                            class="inputwidth text--center ma-0 pa-0"
                            v-model="availableSize.amount"
                          />

                          <v-btn
                            @click="
                              changeAmountDown(product.name, color, index)
                            "
                            icon
                          >
                            <v-icon small>mdi-chevron-down</v-icon>
                          </v-btn>
                        </v-col>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-layout>
        </v-row>
        <v-layout justify-end class="px-4 pb-4">
          <v-btn elevation="2" small>
            <v-icon left> mdi-cart </v-icon>
            {{ $t("Add") }}
          </v-btn>
        </v-layout>
      </v-card>
    </v-col>
    <v-col>
      <v-layout justify-center>
        <template>
          <v-row class="mt-2" align="center" justify="center">
            <v-spacer></v-spacer>

            <span class="mr-4 grey--text">
              <small>Page {{ page }}</small>
            </span>
            <v-btn
              :disabled="hasPrevPage"
              fab
              x-small
              class="mr-1"
              @click="formerPage"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn
              :disabled="hasNextPage"
              fab
              x-small
              class="ml-1"
              @click="nextPage"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-row>
        </template>
      </v-layout>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ["products"],
  data() {
    return {
      page: 1,
    };
  },
  methods: {
    changeAmountUp(prodName, color, index) {
      let obj = this.products.find((o) => o.name === prodName);
      obj = obj.colors.find((o) => o.name === color.name);
      obj.available_sizes[index].amount++;
    },
    changeAmountDown(prodName, color, index) {
      let obj = this.products.find((o) => o.name === prodName);
      obj = obj.colors.find((o) => o.name === color.name);
      obj.available_sizes[index].amount--;
    },
  },
};
</script>

<style scoped>
.inputwidth {
  max-width: 2rem;
  text-align: center;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>